import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Paragraph from "@editorjs/paragraph";
import ImageTool from "@editorjs/image";
import AlignmentTuneTool from "editorjs-text-alignment-blocktune";
import FontSize from "editorjs-inline-font-size-tool";
import Undo from "editorjs-undo";
import Images from "@vtchinh/gallery-editorjs";

import Embed from "@editorjs/embed";
import DragDrop from "editorjs-drag-drop";
import ColorPlugin from "editorjs-text-color-plugin";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import Footer from "../../examples/Footer";
import { API_URL } from "../../constants/constants";
import MDInput from "../../components/MDInput";
import { useEffect, useRef, useState } from "react";
import { DateRange } from "react-date-range";

import ImagesIcon from "../../assets/images/icons/images-icon.svg";
import { CollinsFrontUrl } from "../../constants/constants";
import {
  dateFormatYYYYMMDDHHMM,
  dateFormatYYYYMMDDHHMMToBackEndType,
  dateYYYYMMDDFormat,
  getDataRangeInputs,
  getYmd10,
  showDataRange
} from "../../Util";
import { setSpinnerStatus, useMaterialUIController } from "../../context";

function Editor() {
  const [, dispatch] = useMaterialUIController();
  const editor = useRef(null);
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [targetThumbnailUrl, setTargetThumbnailUrl] = useState("");
  const [backgroundUrl, setBackgroundUrl] = useState(null);
  const [registeredDate, setRegisteredDate] = useState(dateFormatYYYYMMDDHHMM(new Date()));
  const [duplicateCheck, setDuplicateCheck] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [boardId, setBoardId] = useState(null);
  const [writer, setWriter] = useState("");
  const [isShowDataRange, setIsShowDataRange] = useState(false);
  const filterDataRef = useRef(null);
  const [specialDateUserDate, setSpecialDateUserDate] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection"
    }
  ]);

  const [usedDate, setUsedDate] = useState(false);
  const [visible, setVisible] = useState(false);
  const getUserInit = async () => {
    return await fetch(API_URL + "/api/admin/info", {
      method: "GET",
      credentials: "include"
    }).then(res => res.json()).then(r => {
      setSpinnerStatus(dispatch, false);
      if (r.data) {
        setUserInfo(r.data);
        return r.data;
      }
    });
  };

  const uploadByThumbnailFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileType", "thumbnail");
    return fetch(API_URL + "/api/collins/boards/upload", {
      method: "POST",
      body: formData,
      credentials: "include"
    }).then((response) => {
      return response.json().then((res) => {
        setTargetThumbnailUrl(res.data.url);
      });
    });
  };

  useEffect(() => {
    if (usedDate) {
      getDataRangeInputs(filterDataRef, setSpecialDateUserDate, setIsShowDataRange);
      document.body.addEventListener("click", (e) => {
        setIsShowDataRange(false);
        e.stopPropagation();
      });
    }
  }, [usedDate]);

  useEffect(() => {
    showDataRange(isShowDataRange);
  }, [isShowDataRange]);


  useEffect(() => {
    setSpinnerStatus(dispatch, true);
    editor.current = new EditorJS({
      /**
       * Id of Element that should contain the Editor
       */
      holder: "editorjs",

      /**
       * Available Tools list.
       * Pass Tool's class or Settings object for each Tool you want to use
       */
      tools: {
        fontSize: FontSize,
        Color: {
          class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
          config: {
            colorCollections: ["#EC7878", "#9C27B0", "#673AB7", "#3F51B5", "#0070FF", "#03A9F4", "#00BCD4", "#4CAF50", "#8BC34A", "#CDDC39", "#FFF"],
            defaultColor: "#FF1300",
            type: "text",
            customPicker: true // add a button to allow selecting any colour
          }
        },

        header: {
          class: Header,
          inlineToolbar: ["link"],
          tunes: ["option"]
        },
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
          tunes: ["option"]
        },
        embed: {
          class: Embed,
          inlineToolbar: true,
          config: {
            services: {
              youtube: true
            }
          }
        },
        list: {
          class: List,
          inlineToolbar: true
        },
        image: {
          class: ImageTool,
          config: {
            // endpoints: {
            //   byFile: "http://localhost:8008/uploadFile", // Your backend file uploader endpoint
            //   byUrl: "http://localhost:8008/fetchUrl" // Your endpoint that provides uploading by Url
            // }
            uploader: {
              uploadByFile(file) {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("fileType", "img");
                return fetch(API_URL + "/api/collins/boards/upload", {
                  method: "POST",
                  body: formData,
                  credentials: "include"
                }).then((response) => {
                  return response.json().then((res) => {
                    return {
                      success: 1,
                      file: {
                        url: res.data
                      }
                    };
                  });
                });
              }
            }
          }
        },
        Images: {
          class: Images,
          toolbox: {
            title: "Images",
            icon: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
<metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
<g><path d="M794,959.4L71.3,769.5l196-722.8L990,236.6L794,959.4z M910.4,285.6L310.1,126.4l-116.4,441l594.1,159.3L910.4,285.6L910.4,285.6z M377.5,414.3l36.8,67.4l104.1-98l55.1,91.9L714.4,353l36.8,281.8L291.8,512.3L377.5,414.3z M402,346.9c-24.5-6.1-36.7-36.8-30.6-61.3c6.1-24.5,30.6-42.9,61.3-30.6c24.5,6.1,42.9,36.8,36.8,61.3C457.1,340.8,432.6,353,402,346.9z M689.9,120.3l-12.3,6.1L549,89.6l183.8-49l30.6,104.1l-73.5-18.4V120.3L689.9,120.3z M89.6,279.5l42.9,153.1L95.8,555.1L10,230.5l189.9-49L175.4,255L89.6,279.5L89.6,279.5z M206,953.3l-36.8-128.6L420.4,892L206,953.3z"/></g>
</svg>`
          },
          config: {

            uploader: {
              uploadByFile(file) {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("fileType", "img");
                return fetch(API_URL + "/api/collins/boards/upload", {
                  method: "POST",
                  body: formData,
                  credentials: "include"
                }).then((response) => {
                  return response.json().then((res) => {
                    return {
                      success: 1,
                      file: {
                        url: res.data
                      }
                    };
                  });
                });
              }
            }
          }
        },

        option: {
          class: AlignmentTuneTool,
          config: {
            default: "left",
            blocks: {
              header: "center"
            }
          }
        }
      },
      onReady: () => {
        new Undo({ editor: editor.current });
        new DragDrop(editor.current);
        getUserInit().then((r) => getBoardDetail(r)
        );

      }
    });


  }, []);

  const getBoardDetail = (userData) => {
    const searchParams = new URLSearchParams(window.location.search);
    const boardIdParams = searchParams.get("id");
    setBoardId(boardIdParams);
    if (boardIdParams) {
      setSpinnerStatus(dispatch, true);
      fetch(API_URL + "/api/collins/boards/contents/" + boardIdParams, {
        method: "GET",
        credentials: "include"
      }).then(res => {
        setSpinnerStatus(dispatch, false);
        if (res.status !== 200) {
          window.location.href = "/brandArchive";
          return window.alert("잘못된 접근입니다.");
        }
        return res.json();
      }).then(r => {
        if (r.data) {
          setTitle(r.data.title);
          setSlug(r.data.slug);
          setWriter(r.data.writer);
          if (r.data.slug.trim().length > 0) {
            setDuplicateCheck(true);
          }
          setRegisteredDate(dateFormatYYYYMMDDHHMM(new Date(r.data.register_date)));
          setTargetThumbnailUrl(r.data.thumbnail_url);
          setBackgroundUrl(r.data.background_url);
          if (r.data.start_date) {
            setUsedDate(true);
            setSpecialDateUserDate([
              {
                startDate: new Date(r.data.start_date),
                endDate: new Date(r.data.end_date),
                key: "selection"
              }
            ]);
          }
          setVisible(Boolean(r.data.use_yn));
          if (r.data.content) {
            editor.current.render({ blocks: JSON.parse(r.data.content) });
          }
        }
      });
    } else {
      setWriter(userData.email);
    }
  };

  const saveBoard = () => {
    if (title.trim().length === 0) {
      alert("제목을 입력해주세요.");
      return;
    } else if (!duplicateCheck) {
      alert("슬러그를 중복 체크 해주세요.");
      return;
    } else {
      editor.current.save().then((outputData) => {
        const data = {
          title: title,
          thumbnail_url: targetThumbnailUrl,
          background_url: backgroundUrl,
          slug: slug,
          content: JSON.stringify(outputData.blocks),
          use_yn: visible,
          register_date: dateFormatYYYYMMDDHHMMToBackEndType(new Date(registeredDate)),
          start_date: usedDate ? new Date(specialDateUserDate[0].startDate).toLocaleDateString() : null,
          end_date: usedDate ? new Date(specialDateUserDate[0].endDate).toLocaleDateString() : null,
          writer: writer
        };
        setSpinnerStatus(dispatch, true);
        fetch(API_URL + "/api/collins/boards/contents", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data),
          credentials: "include"
        }).then((response) => {
            response.json().then(
              (res) => {
                setSpinnerStatus(dispatch, false);
                if (res.code === "200") {
                  alert("저장 되었습니다.");
                  window.location.href = "/brandArchive/editor?id=" + res.data;
                } else {
                  alert("오류가 발생했습니다.");
                }
              });
          }
        );
      });
    }
  };

  const modifyBoard = () => {
    if (title.trim().length === 0) {
      alert("제목을 입력해주세요.");
      return;
    } else if (!duplicateCheck) {
      alert("슬러그를 중복 체크 해주세요.");
      return;
    } else {
      editor.current.save().then((outputData) => {
        const data = {
          title: title,
          thumbnail_url: targetThumbnailUrl,
          background_url: backgroundUrl,
          slug: slug,
          content: JSON.stringify(outputData.blocks),
          use_yn: visible,
          register_date: dateFormatYYYYMMDDHHMMToBackEndType(new Date(registeredDate)),
          start_date: usedDate ? new Date(specialDateUserDate[0].startDate).toLocaleDateString() : null,
          end_date: usedDate ? new Date(specialDateUserDate[0].endDate).toLocaleDateString() : null,
          writer: writer
        };
        setSpinnerStatus(dispatch, true);
        fetch(API_URL + "/api/collins/boards/contents/" + boardId, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data),
          credentials: "include"
        }).then((response) => {
          setSpinnerStatus(dispatch, false);
          if (response.ok) {
            alert("수정 되었습니다.");
            // window.location.href = "/brandArchive";
          } else {
            alert("오류가 발생했습니다.");
          }
        });
      });
    }
  };

  const checkDuplicatedSlug = () => {
    if (slug.trim().length === 0) {
      alert("슬러그를 입력해주세요.");
      return;
    } else {
      setSpinnerStatus(dispatch, true);
      fetch(API_URL + "/api/collins/boards/contents/slug/check?keyword=" + slug, {
        method: "GET",
        credentials: "include"
      }).then((response) => {
        response.json().then((res) => {
          setSpinnerStatus(dispatch, false);
          if (res.data) {
            setDuplicateCheck(true);
            alert("사용 가능한 슬러그입니다.");
          } else {
            alert("이미 사용중인 슬러그입니다.");
          }
        });
      });
    }
  };

  const deleteBoard = () => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      setSpinnerStatus(dispatch, true);
      fetch(API_URL + "/api/collins/boards/contents/" + boardId, {
        method: "DELETE",
        credentials: "include"
      }).then((response) => {
        setSpinnerStatus(dispatch, false);
        if (response.ok) {
          alert("삭제 되었습니다.");
          window.location.href = "/brandArchive";
        } else {
          alert("오류가 발생했습니다.");
        }
      });
    }
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  COLLINS
                </MDTypography>
                <MDTypography variant="h6" color="white">
                  Maker's Note {boardId ? "수정하기" : "작성하기"}
                </MDTypography>
              </MDBox>
              <MDBox mt={2} mx={2} px={2} display="flex">
                <MDBox width="100%" mr={1}>
                  <MDTypography component="div" variant="caption" color="text" fontSize="16px" fontWeight="medium">
                    제목(최대 40자 이하)
                  </MDTypography>
                  <MDInput style={{ width: "100%" }} value={title} onChange={(e) => setTitle(e.target.value)} />
                </MDBox>
                <MDBox>
                  <MDBox display="flex" justifyContent="space-between" alignItem="center">
                    <MDTypography component="div" variant="caption" color="text" fontSize="16px" fontWeight="medium">
                      Slug
                    </MDTypography>
                    <MDButton color={duplicateCheck ? "success" : "error"}
                              style={{ padding: "0", minHeight: 0, height: "18px" }}
                              onClick={() => {
                                checkDuplicatedSlug();
                              }}>중복 체크</MDButton>
                  </MDBox>
                  <MDInput value={slug} onChange={(e) => {
                    setSlug(e.target.value);
                    setDuplicateCheck(false);
                  }} />
                </MDBox>
              </MDBox>
              <MDBox my={1} mx={2} px={2} display="flex" style={{ justifyContent: "space-between" }}>
                <MDBox display="flex">
                  <MDBox>
                    <MDBox my={1} minWidth="200px">
                      <MDTypography component="div" variant="caption" color="text" fontSize="16px" fontWeight="medium">
                        썸네일 이미지(684*600px)
                      </MDTypography>
                      <MDInput type="file"
                               onChange={e => {
                                 const formData = new FormData();
                                 formData.append("file", e.target.files[0]);
                                 formData.append("fileType", "thumbnail");
                                 fetch(`${API_URL}/api/collins/boards/upload`, {
                                   method: "POST",
                                   credentials: "include",
                                   body: formData
                                 }).then(res => res.json().then(r => {
                                   setTargetThumbnailUrl(r.data);
                                 }));
                               }} />
                    </MDBox>
                    <img src={targetThumbnailUrl} style={{ height: "80px", marginLeft: "5px" }} />
                  </MDBox>
                  <MDBox mx={1}>
                    <MDBox my={1} minWidth="200px">
                      <MDTypography component="div" variant="caption" color="text" fontSize="16px" fontWeight="medium">
                        배경 이미지(2560*1600px) ※미설정 시 썸네일 이미지 사용
                      </MDTypography>
                      <MDInput type="file"
                               onChange={e => {
                                 const formData = new FormData();
                                 formData.append("file", e.target.files[0]);
                                 formData.append("fileType", "background");
                                 fetch(`${API_URL}/api/collins/boards/upload`, {
                                   method: "POST",
                                   credentials: "include",
                                   body: formData
                                 }).then(res => res.json().then(r => {
                                   setBackgroundUrl(r.data);
                                 }));
                               }} />
                    </MDBox>
                    <img src={backgroundUrl} style={{ height: "80px", marginLeft: "5px" }} />
                  </MDBox>
                </MDBox>
                <MDBox my={1}>
                  <MDTypography component="div" variant="caption" color="text" fontSize="16px" fontWeight="medium">
                    작성자
                  </MDTypography>
                  <MDInput value={writer} onChange={(e) => {
                    setWriter(e.target.value);
                  }} />
                </MDBox>
              </MDBox>
              <MDBox pt={3} mx={4} my={2} borderTop={"1px solid rgba(0, 0, 0, 0.125)"}
                     borderBottom={"1px solid rgba(0, 0, 0, 0.125)"}>
                <div id="editorjs"></div>
              </MDBox>
              <MDBox my={1} mx={2} px={2} display={"flex"}>
                <MDBox display={"flex"} mb={1} height="fit-content">
                  <MDTypography component="div" variant="caption" color="text" fontSize="16px" fontWeight="medium">
                    활성화
                  </MDTypography>
                  <input checked={visible} onChange={() => setVisible(!visible)} type={"checkbox"}
                         style={{ marginLeft: "4px" }} />
                </MDBox>
                <MDBox ml={2}>
                  <MDBox display={"flex"} mb={1}>
                    <MDTypography component="div" variant="caption" color="text" fontSize="16px" fontWeight="medium">
                      노출 날짜 지정
                    </MDTypography>
                    <input type={"checkbox"} onChange={() => setUsedDate(!usedDate)}
                           checked={usedDate}
                           style={{ marginLeft: "4px" }} />
                  </MDBox>
                  {usedDate && <DateRange
                    editableDateInputs={true}

                    onChange={(item) => {
                      filterDataRef.current = item.selection;
                      setSpecialDateUserDate([item.selection]);
                    }}
                    moveRangeOnFirstSelection={false}
                    ranges={
                      specialDateUserDate
                    }
                  />}
                </MDBox>
              </MDBox>
              <MDBox my={1} mx={2} px={2}>
                <MDBox mt={1} mb={1}>
                  <MDTypography component="div" variant="caption" color="text" fontSize="16px" fontWeight="medium">
                    작성일
                  </MDTypography>
                </MDBox>
                <MDInput type={"datetime-local"} value={registeredDate}
                         onChange={(e) => setRegisteredDate(e.target.value)} />
              </MDBox>
              <MDBox display="flex" mx={2}
                     mt={2}
                     py={3}
                     px={2}>
                <MDBox display="flex">
                  {boardId ? <MDBox display="flex">
                    <MDBox display="flex" mr={1}>
                      <MDButton color="info" onClick={() => {
                        window.open(CollinsFrontUrl + "/makersnote/preview/" + boardId, "_blank");
                      }}>미리보기</MDButton>
                    </MDBox>
                    <MDBox mr={1}><MDButton color="info" onClick={() => {
                      modifyBoard();
                    }}>수정하기</MDButton></MDBox>
                    <MDButton color="info" onClick={() => {
                      deleteBoard();
                    }}>삭제하기</MDButton>
                  </MDBox> : <MDButton color="info" onClick={() => {
                    saveBoard();
                  }}>저장하기</MDButton>}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>

  );
}

export default Editor;

