import MDTypography from "components/MDTypography";
import { downloadFromS3 } from "../../../Util";
import { useNavigate } from "react-router-dom";
import DownloadIcon from "../../../assets/images/icons/downloads.png";
import MDBadge from "../../../components/MDBadge";


export default function data(data, type) {
  const navigate = useNavigate();
  return {
    columns: [
      { Header: "ID", accessor: "id", align: "center" },
      { Header: "상태", accessor: "status", align: "center" },
      { Header: "제목", accessor: "title", align: "center" },
      { Header: "등록자", accessor: "registrant", align: "center" },
      { Header: "등록일", accessor: "registerDate", align: "center" },
      { Header: "업로드 파일", accessor: "fileName", align: "center" }
    ],

    rows: data.map((item, i) => ({
      id: (
        <MDTypography component="div" variant="caption" color="text"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/${type}/invoice/${item.id}`);
                      }}
                      fontWeight="medium">
          {item.id}
        </MDTypography>
      ),
      status: (
        <MDBadge
          color={item.status === "confirmed" ? "success" : item.status === "some_unconfirmed" ? "warning" : "error"}
          badgeContent={item.status === "confirmed" ? "확정 완료" : item.status === "some_unconfirmed" ? "일부 미확정" : "전체 미확정"}
        />
      ),
      title: (
        <MDTypography component="div" variant="caption" color="text" fontWeight="bold"
                      style={{
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        maxWidth: "30vw",
                        width: "30vw"
                      }}
                      onClick={() => {
                        navigate(`/${type}/invoice/${item.id}`);
                      }}>
          {item.title}
        </MDTypography>
      ),
      registrant: (
        <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
          {item.registrant}
        </MDTypography>
      ),
      registerDate: (
        <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
          {item.registed_date}
        </MDTypography>
      ),
      fileName: (
        <div style={{ cursor: "pointer", display: "flex", whiteSpace: "nowrap" }}
             onClick={() => downloadFromS3(item.file_url, item.file_name)}>
          <MDTypography color="text"
                        style={{ textOverflow: "ellipsis", overflow: "hidden", width: "100px" }}
                        fontWeight="medium" variant="caption">

            {item.file_name}
          </MDTypography>
          <img src={DownloadIcon} width={"15px"} style={{ marginLeft: "3px" }} />
        </div>
      )
    }))
  }
    ;
}
