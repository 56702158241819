import React, { useEffect, useState } from "react";
import MDReleaseNotificationModifyModal from "../../components/MDReleaseNotificationModifyModal";
import MDModal from "../../components/MDModal";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import { autoHypenPhone } from "../../Util";
import MDButton from "../../components/MDButton";
import { DateRange } from "react-date-range";
import MenuItem from "@mui/material/MenuItem";
import DataTable from "../../examples/Tables/DataTable";
import MDPagination from "../../components/MDPagination";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import Footer from "../../examples/Footer";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";

function Home() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg"
                     coloredShadow="info">
                <MDTypography variant="h6" color="white">
                  깃컴퍼니 통합 어드민
                </MDTypography>
              </MDBox>
              <MDBox mx={3} mt={3} px={2}>
                <MDTypography fontSize={"13px"} color="black">
                  관리 주체: 깃컴퍼니 팀 온라인 / @benny @trevor @vivi
                </MDTypography>
              </MDBox>
              <MDBox mx={3} px={2} display="flex" flexWrap="wrap">
                <MDBox pr={1} mr={1} my={5}>
                  <MDButton color="success" onClick={() => {
                    window.open("https://gittco.slack.com/archives/C34UY5SLQ", "_blank");
                  }}>online 문의하기 (Slack)</MDButton>
                </MDBox>
              </MDBox>
              <MDBox mx={2} px={2} variant="gradient" borderRadius="lg" display="flex"
                     style={{
                       alignItems: "center",
                       justifyContent: "space-between"
                     }}
              >
                <MDBox display="flex" style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center"
                }}>
                  {/*<MDBox pr={1} mx={1}>*/}
                  {/*  <MDButton color={"secondary"} onClick={() => setFilterDateRange([*/}
                  {/*    {*/}
                  {/*      startDate: null,*/}
                  {/*      endDate: null,*/}
                  {/*      key: "selection"*/}
                  {/*    }*/}
                  {/*  ])}>날짜 삭제</MDButton>*/}
                  {/*</MDBox>*/}
                </MDBox>
              </MDBox>
            </Card>

          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );

}

export default Home;
