import React, { useEffect, useState } from "react";
import MDReleaseNotificationModifyModal from "../../components/MDReleaseNotificationModifyModal";
import MDModal from "../../components/MDModal";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import { autoHypenPhone, downloadFromS3, fetchWithTimeout } from "../../Util";
import MDButton from "../../components/MDButton";
import { DateRange } from "react-date-range";
import MenuItem from "@mui/material/MenuItem";
import DataTable from "../../examples/Tables/DataTable";
import MDPagination from "../../components/MDPagination";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import Footer from "../../examples/Footer";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import { API_URL } from "../../constants/constants";
import invoiceTableData from "../tables/data/invoiceTableData";
import { useNavigate } from "react-router-dom";
import { setSpinnerStatus, useMaterialUIController } from "../../context";
import DownloadIcon from "../../assets/images/icons/downloads.png";
import MDProgressModal from "../../components/MDProgressModal";

function InvoiceDetailPage(props) {
  const [controller, dispatch] = useMaterialUIController();
  const { type } = props;
  const [targetId, setTargetId] = useState(null);
  const [data, setData] = useState();
  const [statusTotalCount, setStatusTotalCount] = useState(0);
  const [statusSuccessCount, setStatusSuccessCount] = useState(0);
  const [successOrderList, setSuccessOrderList] = useState([]);
  const [failOrderList, setFailOrderList] = useState([]);

  const { rows, columns } = invoiceTableData(data?.invoicesOrderList ? data?.invoicesOrderList : [], type);
  const pathName = window.location.pathname;
  const pathArr = pathName.split("/");
  const ruleId = pathArr[pathArr.length - 1];

  const sequentialAsyncMap = async () => {
    let orderIdArr = [];
    const successOrderListTemp = [];
    const failOrderListTemp = [];
    for (const [index, item] of data.invoicesOrderList.entries()) {
      if (!item.status) {
        orderIdArr.push(item.id);
        if (orderIdArr.length === 3) {
          await registerInvoice(orderIdArr, successOrderListTemp, failOrderListTemp);
          orderIdArr = [];
        } else if (index + 1 === data.invoicesOrderList.length) {
          await registerInvoice(orderIdArr, successOrderListTemp, failOrderListTemp);
          orderIdArr = [];
        }
      } else {
        if (index + 1 === data.invoicesOrderList.length) {
          if (orderIdArr.length > 0) {
            await registerInvoice(orderIdArr, successOrderListTemp, failOrderListTemp);
            orderIdArr = [];
          }
        }
        successOrderListTemp.push(item.id);
        await setSuccessOrderList([...successOrderListTemp]);
      }
    }
  };

  const registerInvoice = async (orderIdArr, successOrderListTemp, failOrderListTemp) => {
    try {
      await fetchWithTimeout(`${API_URL}/api/${type}/invoices/ship?invoicesId=${targetId}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify(orderIdArr)
      }, 300000).then(async res => await res.json().then(async r => {
        if (r.data) {
          successOrderListTemp.push(...orderIdArr);
        } else {
          failOrderListTemp.push(...orderIdArr);
        }
      })).catch(() => {
        failOrderListTemp.push(...orderIdArr);
      }).finally(async () => {
        await setSuccessOrderList([...successOrderListTemp]);
        await setFailOrderList([...failOrderListTemp]);
      });
    } catch (err) {
      failOrderListTemp.push(...orderIdArr);
      await setFailOrderList([...failOrderListTemp]);
    }

    //   await fetch(`${API_URL}/api/${type}/invoices/ship?invoicesId=${targetId}`, {
    //     method: "POST",
    //     headers: { "Content-Type": "application/json" },
    //     credentials: "include",
    //     body: JSON.stringify(orderIdArr)
    //   }).then(async res => await res.json().then(async r => {
    //     if (r.data) {
    //       successOrderListTemp.push(...orderIdArr);
    //     } else {
    //       failOrderList.push(...orderIdArr);
    //     }
    //   })).catch(async () => {
    //     failOrderList.push(...orderIdArr);
    //   });
    //   await setSuccessOrderList([...successOrderListTemp]);
    //   await setFailOrderList([...failOrderListTemp]);
    // } catch (err) {
    //   failOrderList.push(...orderIdArr);
    //   await setFailOrderList([...failOrderListTemp]);
    // }
  };

  const registerInvoiceAsyncMap = async () => {
    document.getElementById("progressModal").show();
    await sequentialAsyncMap();
    await viewInvoiceResult();
  };


  const viewInvoiceResult = () => {
    setSpinnerStatus(dispatch, true);
    fetch(`${API_URL}/api/${type}/invoices/list/${targetId}`, {
      method: "GET",
      credentials: "include"
    }).then(res => res.json().then(r => {
      if (r.data.status === "some_unconfirmed") {
        const failInvoiceList = [];
        const successInvoiceList = [];
        r.data.invoicesOrderList.map(item => {
          if (item.status) {
            successInvoiceList.push(item);
          } else {
            failInvoiceList.push(item);
          }
        });
        const result = failInvoiceList.concat(successInvoiceList);
        r.data.invoicesOrderList = result;
      }
      setData(r.data);
      setSpinnerStatus(dispatch, false);
    })).catch(() => {
      setSpinnerStatus(dispatch, false);
    });
  };

  const calculateStatusCount = () => {
    if (data?.invoicesOrderList?.length > 0) {
      setStatusTotalCount(data.invoicesOrderList.length);
      setStatusSuccessCount(data.invoicesOrderList.filter(item => item.status).length);
    }
  };

  useEffect(() => {
    setData([]);
    setTargetId(null);
    setTargetId(ruleId);
  }, [pathName]);

  useEffect(() => {
    calculateStatusCount();
  }, [data]);

  useEffect(() => {
    if (targetId) {
      viewInvoiceResult();
    }
  }, [targetId]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDProgressModal id={"progressModal"} successArr={successOrderList} failArr={failOrderList}
                       totalCount={data?.invoicesOrderList?.length} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg"
                     coloredShadow="info">
                <MDTypography variant="h6" color="white">
                  {type.toUpperCase()}
                </MDTypography>
                <MDTypography variant="h6" color="white">
                  송장 업로드
                </MDTypography>
              </MDBox>
              <MDBox mx={3} mt={3} px={2}>
                <MDBox style={{ justifyContent: "space-between", width: "100%", display: "flex" }}>
                  <MDBox
                    style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    {targetId &&
                      <MDTypography style={{ fontSize: "20px", paddingRight: "15px" }}
                                    color={"dark"}
                      >#{targetId} {data ? data.title : ""}</MDTypography>}
                    <MDBox style={{ textWrap: "nowrap" }}>
                      <MDTypography style={{ fontSize: "15px" }}
                                    color={"dark"}
                      >{data ? data.registedDate : ""}</MDTypography>
                      <MDTypography style={{ fontSize: "15px", marginTop: "3px" }}
                                    color={"dark"}
                      >{data ? data.registrant : ""}</MDTypography>
                      <div style={{ cursor: "pointer", display: "flex", whiteSpace: "nowrap", marginTop: "10px" }}
                           onClick={() => downloadFromS3(data?.fileUrl, data?.fileName)}>
                        <MDTypography color="text"
                                      fontWeight="medium" variant="caption">

                          {data?.fileName}
                        </MDTypography>
                        <img src={DownloadIcon} width={"15px"} style={{ marginLeft: "3px" }} />
                      </div>
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDTypography fontSize={"15px"} color="dark">
                  업로드된 송장 : {statusTotalCount}<br />
                  확정된 송장 : {statusSuccessCount}<br />
                  미확정된 송장 : {statusTotalCount - statusSuccessCount}
                </MDTypography>
              </MDBox>
              <MDBox mx={3} px={2} display="flex" flexWrap="wrap">
                {statusTotalCount !== statusSuccessCount && <MDBox pr={1} mr={1} mt={2}>
                  <MDTypography style={{ fontSize: "13px" }} color="dark">미확정된
                    송장 {statusTotalCount - statusSuccessCount}개를 확정하시겠습니까?</MDTypography>
                  <MDButton color={targetId ? "success" : "light"}
                            style={{ cursor: targetId ? "pointer" : "not-allowed" }}
                            onClick={async () => {
                              if (targetId) {
                                await registerInvoiceAsyncMap();
                              }
                            }}>송장 일괄 확정하기</MDButton>
                </MDBox>}
              </MDBox>
              <MDBox>
                <DataTable table={{ columns, rows }} isSorted={false}
                           showTotalEntries={false} noEndBorder />
              </MDBox>
            </Card>

          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );

}

export default InvoiceDetailPage;
