// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
import { useEffect, useMemo, useState, useRef, useCallback } from "react";
import Divider from "@mui/material/Divider";
import { Icon } from "@mui/material";
import MDInput from "../../components/MDInput";
import {
  API_URL,
  WP_COLLINS_CONSUMER_KEY, WP_COLLINS_CONSUMER_SECRET,
  WP_COLLINS_REST_API_URL
} from "../../constants/constants";
import MDButton from "../../components/MDButton";
import MDPagination from "../../components/MDPagination";
import baconOrderListTableData from "../tables/data/baconOrderListTableData";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import { useNavigate } from "react-router-dom";
import { setSpinnerStatus, useMaterialUIController } from "../../context";
import Select from "react-select";
import { searchParam } from "../../Util";

function OrderList(props) {
  const { type } = props;
  const [controller, dispatch] = useMaterialUIController();
  const [searchText, setSearchText] = useState("");
  const [targetData, setTargetData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(5);
  const navigate = useNavigate();
  const [searchCustomerValue, setSearchCustomerValue] = useState("");
  const [customer, setCustomer] = useState(null);
  const debounceRef = useRef(0);
  const [userList, setUserList] = useState([]);
  const userIdParam = searchParam("userId");
  const searchKey = [
    { label: "이름", value: "name" },
    { label: "전화번호", value: "phone" },
    { label: "주문번호", value: "id" }
  ];
  const [orderTab, setOrderTab] = useState("order");
  const [searchKeyword, setSearchKeyword] = useState(searchKey[0]);


  const [apiRequests, setApiRequests] = useState([]);
  const getBaconOrderList = useCallback(async () => {
    setSpinnerStatus(dispatch, true);

    try {
      apiRequests.push(0);
      setApiRequests(apiRequests);
      if (orderTab === "order") {

        const response = await fetch(`${API_URL}/api/${type}/orders/list?page=${currentPage}&size=20${searchText !== "" ? `&${searchKeyword.value}=${searchText}` : ""}${customer?.customer_id ? `&userId=${customer.customer_id}` : ""}`, {
          method: "GET",
          credentials: "include"
        });
        await response.json().then(r => {
          setTargetData([...r.data]);
          setTotalPage(r.totalPages);
        });
      } else {
        const response = await fetch(`${API_URL}/api/${type}/template/list?page=${currentPage}&size=20${searchText !== "" ? `&${searchKeyword.value}=${searchText}` : ""}${customer?.customer_id ? `&userId=${customer.customer_id}` : ""}`, {
          method: "GET",
          credentials: "include"
        });
        await response.json().then(r => {
          setTargetData([...r.data]);
          setTotalPage(r.totalPages);
        });
      }
    } finally {
      apiRequests.pop();
      setApiRequests(apiRequests);
      if (apiRequests.length === 0) {
        setSpinnerStatus(dispatch, false);
      }
    }
  }, [type, currentPage, searchText, customer, searchKeyword, orderTab]);


  const {
    columns,
    rows
  } = useMemo(() => baconOrderListTableData(targetData, type, orderTab), [targetData, type, orderTab]);


  useEffect(() => {
    getBaconOrderList();
  }, [currentPage]);

  useEffect(() => {
    getBaconOrderList();
    if (customer?.value) {
      navigate(`/${type}/order/list?userId=${customer.value}`);
    } else {
      navigate(`/${type}/order/list`);
    }
  }, [customer]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      getBaconOrderList();
    }
  }, [type, orderTab]);

  const debounceSearch = (value, delay) => {
    clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(() => {
      setSearchCustomerValue(value);
    }, delay);
  };


  const searchCustomer = async () => {
    setSpinnerStatus(dispatch, true);

    await fetch(`${API_URL}/api/${type}/customers?search=${searchCustomerValue}`, { credentials: "include" }).then((res) => res.json().then((r) => {
      const formatData = r.data.map(item => {
        item.value = item.customer_id;
        item.label = `${item.first_name} (#${item.customer_id} - ${item.email})`;
        return item;
      });
      setUserList(formatData);
    }));
    setSpinnerStatus(dispatch, false);
  };

  useEffect(() => {
    if (searchCustomerValue !== "") {
      searchCustomer();
    }
  }, [searchCustomerValue]);

  useEffect(() => {
    if (userIdParam) {
      searchUserById();
    }
    const searchInputElement = document.getElementById("searchInput");
    searchInputElement.addEventListener("keyup", function(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        document.getElementById("searchButton").click();

      }
    });
    return () => searchInputElement.removeEventListener("keyup", function(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        document.getElementById("searchButton").click();
      }
    });
  }, []);

  const searchUserById = async () => {

    await fetch(`${API_URL}/api/${type}/customers/${userIdParam}`, { credentials: "include" }).then((res) => res.json().then((rs) => {
      const formatData = {
        value: rs.data.customer_id,
        label: `${rs.data.first_name} (#${rs.data.customer_id} - ${rs.data.email})`,
        customer_id: rs.data.customer_id
      };
      setCustomer(formatData);
      setUserList([formatData]);
    }));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {orderTab === "order" ? "주문 리스트" : "템플릿 리스트"}
                </MDTypography>
              </MDBox>
              <MDBox
                mx={2}
                px={2}
                variant="gradient"
                borderRadius="lg"
                display="flex"
                flexWrap="wrap"
                justifyContent="space-between"
              >
                <MDBox display="flex" flexWrap="wrap" alignItems={"center"}>
                  <MDBox mr={1} width={"130px"}>
                    <Select
                      id="productName"
                      className="basic-single search-dropdown"
                      classNamePrefix="select"
                      isClearable={false}
                      isSearchable={false}
                      name="searchKey"
                      options={searchKey}
                      value={searchKeyword}
                      onChange={(e) => {
                        setSearchKeyword({ ...e });
                      }}
                    />
                  </MDBox>
                  <MDBox pr={1} my={1} width="190px">
                    <MDInput id={"searchInput"} label="검색" onChange={(e) => setSearchText(e.target.value)} />
                  </MDBox>
                  <MDBox pr={1} mr={5} my={1}>
                    <MDButton color="success" variant={"gradient"} id={"searchButton"} onClick={() => {
                      if (currentPage !== 1) {
                        setCurrentPage(1);
                      } else {
                        getBaconOrderList();
                      }
                    }}>검색하기</MDButton>
                  </MDBox>

                  <MDBox minWidth="500px" witdh={"100%"} my={1}>
                    <Select
                      id="productName"
                      className="basic-single customer-dropdown"
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={true}
                      label={"고객"}
                      name="color"
                      options={userList}
                      value={customer}
                      onInputChange={e => debounceSearch(e, 500)}
                      onChange={(e) => {
                        setCustomer({ ...e });
                      }}
                    />
                  </MDBox>
                </MDBox>
                <MDBox pr={1} mr={1} my={1}>
                  <MDButton color="info" id={"orderButton"}

                            onClick={() => navigate(`/${type}/order/new${orderTab === "template" ? "?status=template" : ""}`)}>{orderTab === "order" ? "주문 생성하기" : "템플릿 생성하기"}</MDButton>
                </MDBox>
              </MDBox>
              <Divider />
              <MDBox pt={3}>
                <MDBox ml={4} mb={1} mr={4} display={"flex"} style={{ borderBottom: `1px solid rgba(0, 0, 0, 0.12)` }}>
                  <MDBox bgColor={orderTab === "order" ? "info" : ""}
                         color={orderTab === "order" ? "white" : "rgba(0, 0, 0, 0.12)"} borderRadius={"5px 5px 0 0"}
                         width={"80px"}
                         textAlign={"center"} style={{ cursor: "pointer" }}
                         border={"1px solid rgba(0, 0, 0, 0.12)"}
                         onClick={() => setOrderTab("order")}>전체
                  </MDBox>
                  <MDBox bgColor={orderTab === "template" ? "info" : ""}
                         color={orderTab === "template" ? "white" : "rgba(0, 0, 0, 0.12)"} borderRadius={"5px 5px 0 0"}
                         width={"80px"} textAlign={"center"}
                         border={"1px solid rgba(0, 0, 0, 0.12)"}
                         cursor={"pointer"}
                         style={{ cursor: "pointer" }}
                         onClick={() => setOrderTab("template")}>템플릿
                  </MDBox>
                </MDBox>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
              <MDBox py={3} display="flex" justifyContent="center">
                <MDPagination>
                  <CustomPagination total={totalPage} itemsPerPage={4} currentPage={currentPage}
                                    setCurrentPage={(page) => setCurrentPage(page)} />
                </MDPagination>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default OrderList;
