import Dashboard from "layouts/dashboard";
import BaconDistTables from "layouts/tables/BaconDistTables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import CollinsDistTable from "./layouts/tables/CollinsDistTable";
import Order from "./layouts/Order/Order";
import BaconSetProduct from "./layouts/BaconSetProduct/BaconSetProduct";
import CollinsSetProduct from "./layouts/CollinsSetProduct/CollinsSetProduct";
import DistHistory from "./layouts/DistHistory/DistHistory";
import BaconCoupon from "./layouts/BaconCoupon/BaconCoupon";
import CollinsCoupon from "./layouts/CollinsCoupon/CollinsCoupon";
import DistDetail from "./layouts/DistDetail/DistDetail";
import DistNew from "./layouts/DistNew/DistNew";
import SetProductNew from "./layouts/SetProductNew/SetProductNew";
import RestoreAdmin from "./layouts/RestoreAdmin/RestoreAdmin";
import BaconDmList from "./layouts/BaconDmList/BaconDmList";
import SetProductDetail from "./layouts/SetProductDetail/SetProductDetail";
import CollinsSetProductNew from "./layouts/CollinsSetProductNew/CollinsSetProductNew";
import CollinsSetProductDetail from "./layouts/CollinsSetProductDetail/CollinsSetProductDetail";
import CollinsPlace from "./layouts/CollinsPlace/CollinsPlace";
import CollinsMusic from "./layouts/CollinsMusic/CollinsMusic";
import Editor from "./layouts/Editor/Editor";
import BoardList from "./layouts/BoardList/BoardList";
import RestockNotificationList from "./layouts/RestockNotificationList/RestockNotificationList";
import MoodWaterReleaseNotification from "./layouts/MoodWaterReleaseNotification/MoodWaterReleaseNotification";
import BaconRestockNotificationList from "./layouts/BaconRestockNotificationList/BaconRestockNotificationList";
import InvoiceUploadPage from "./layouts/InvoiceUploadPage/InvoiceUploadPage";
import InvoiceUploadListPage from "./layouts/InvoiceUploadListPage/InvoiceUploadListPage";
import Home from "./layouts/Home/Home";
import InvoiceDetailPage from "./layouts/InvoiceDetailPage/InvoiceDetailPage";
import BaconOrderList from "./layouts/OrderList/OrderList";
import OrderDetail from "./layouts/OrderDetail/OrderDetail";
import BaconOrderDetailNew from "./layouts/OrderDetailNew/OrderDetailNew";
import TodayDeliveryNotification from "./layouts/TodayDeliveryNotification/TodayDeliveryNotification";

const routes = [
  // {
  //     type: "collapse",
  //     name: "BACON 출고",
  //     key: "bacon_released",
  //     icon: <Icon fontSize="small">dashboard</Icon>,
  //     route: "/dist",
  //     component: <BaconDistTables />,
  // },
  {
    type: "",
    name: "출시 알림 현황 조회",
    group: "",
    key: "home",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/",
    role: "ROLE_ADMIN",
    component: <Home />
  },
  {
    type: "collapse",
    name: "출시 알림 현황 조회",
    group: "collins",
    key: "collins/releaseNotificationList",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/collins/releaseNotificationList",
    role: "ROLE_ADMIN",
    component: <MoodWaterReleaseNotification />
  },
  {
    type: "collapse",
    name: "재입고 알림 현황 조회",
    group: "collins",
    key: "collins/restockNotificationList",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/collins/restockNotificationList",
    role: "ROLE_ADMIN",
    component: <RestockNotificationList />
  },
  {
    type: "",
    name: "송장 업로드New",
    group: "collins",
    key: "collins/invoice",
    icon: <Icon fontSize="small">dashboard</Icon>,
    role: "ROLE_USER",
    route: "/collins/invoice/new",
    component: <InvoiceUploadPage type={"collins"} />
  },
  {
    type: "",
    name: "송장 업로드 상세",
    group: "collins",
    key: "collins/invoice",
    icon: <Icon fontSize="small">dashboard</Icon>,
    role: "ROLE_USER",
    route: "/collins/invoice/:id",
    component: <InvoiceDetailPage type={"collins"} />
  },
  {
    type: "collapse",
    name: "송장 업로드",
    group: "collins",
    key: "collins/invoice",
    icon: <Icon fontSize="small">dashboard</Icon>,
    role: "ROLE_USER",
    route: "/collins/invoice/list",
    component: <InvoiceUploadListPage type={"collins"} />
  },
  {
    type: "collapse",
    name: "재입고 알림 현황 조회",
    group: "bacon",
    key: "bacon/restockNotificationList",
    icon: <Icon fontSize="small">dashboard</Icon>,
    role: "ROLE_ADMIN",
    route: "/bacon/restockNotificationList",
    component: <BaconRestockNotificationList />
  },

  {
    type: "",
    name: "송장 업로드New",
    group: "bacon",
    key: "bacon/invoice",
    icon: <Icon fontSize="small">dashboard</Icon>,
    role: "ROLE_USER",
    route: "/bacon/invoice/new",
    component: <InvoiceUploadPage type={"bacon"} />
  },
  {
    type: "",
    name: "송장 업로드 상세",
    group: "bacon",
    key: "bacon/invoice",
    icon: <Icon fontSize="small">dashboard</Icon>,
    role: "ROLE_USER",
    route: "/bacon/invoice/:id",
    component: <InvoiceDetailPage type={"bacon"} />
  },
  {
    type: "collapse",
    name: "송장 업로드",
    group: "bacon",
    key: "bacon/invoice",
    icon: <Icon fontSize="small">dashboard</Icon>,
    role: "ROLE_USER",
    route: "/bacon/invoice/list",
    component: <InvoiceUploadListPage type={"bacon"} />
  },
  {
    type: "collapse",
    name: "주문",
    key: "bacon/order",
    group: "bacon",
    role: "ROLE_ADMIN",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/bacon/order/list",
    component: <BaconOrderList type={"bacon"} />
  },
  {
    type: "",
    name: "주문 상세",
    key: "bacon/order",
    group: "bacon",
    role: "ROLE_ADMIN",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/bacon/order/:id",
    component: <OrderDetail type={"bacon"} />
  },
  {
    type: "",
    name: "주문 생성",
    key: "bacon/order",
    group: "bacon",
    role: "ROLE_ADMIN",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/bacon/order/New",
    component: <OrderDetail type={"bacon"} />
  },
  {
    type: "collapse",
    name: "주문",
    key: "collins/order",
    group: "collins",
    role: "ROLE_ADMIN",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/collins/order/list",
    component: <BaconOrderList type={"collins"} />
  },
  {
    type: "",
    name: "주문 상세",
    key: "collins/order",
    group: "collins",
    role: "ROLE_ADMIN",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/collins/order/:id",
    component: <OrderDetail type={"collins"} />
  },
  {
    type: "",
    name: "주문 생성",
    key: "collins/order",
    group: "collins",
    role: "ROLE_ADMIN",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/collins/order/New",
    component: <OrderDetail type={"collins"} />
  },
  {
    type: "collapse",
    name: "오늘 출발 안내 UI 관리 ",
    key: "collins/shippingToday",
    group: "collins",
    role: "ROLE_ADMIN",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/collins/shippingToday",
    component: <TodayDeliveryNotification type={"collins"} />
  },
  {
    group: "collins",
    type: "divider"
  },
  {
    type: "collapse",
    name: "Places",
    group: "collins",
    key: "collins/places",
    route: "/collins/places",
    role: "ROLE_ADMIN",
    icon: <Icon fontSize="small">dashboard</Icon>,
    component: <CollinsPlace />
  },
  {
    type: "collapse",
    name: "Music",
    group: "collins",
    key: "collins/music",
    route: "/collins/music",
    role: "ROLE_ADMIN",
    icon: <Icon fontSize="small">dashboard</Icon>,
    component: <CollinsMusic />
  },
  {
    type: "",
    name: "에디터",
    group: "collins",
    key: "collins/brandArchive",
    route: "/collins/brandArchive/editor",
    role: "ROLE_ADMIN",
    icon: <Icon fontSize="small">dashboard</Icon>,
    component: <Editor />
  },
  {
    type: "collapse",
    name: "Maker's Note",
    group: "collins",
    key: "collins/brandArchive",
    route: "/collins/brandArchive",
    role: "ROLE_ADMIN",
    icon: <Icon fontSize="small">dashboard</Icon>,
    component: <BoardList />
  }
];
export default routes;
