// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
import { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import { Icon } from "@mui/material";
import MDInput from "../../components/MDInput";
import { API_URL } from "../../constants/constants";
import MDButton from "../../components/MDButton";
import MDPagination from "../../components/MDPagination";
import collinsPlaceTableData from "../tables/data/collinsPlaceTableData";
import { autoHypenPhone } from "../../Util";
import MenuItem from "@mui/material/MenuItem";
import MDPostCodeModal from "../../components/MDPostCodeModal/MDPostCodeModal";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import React from "react";
import { setSpinnerStatus, useMaterialUIController } from "../../context";

function CollinsPlace() {
  const [, dispatch] = useMaterialUIController();
  const [placeName, setPlaceName] = useState("");
  const [placeAlias, setPlaceAlias] = useState("");
  const [placePhone, setPlacePhone] = useState("");
  const [instaUrl, setInstaUrl] = useState("");
  const [externalUrl, setExternalUrl] = useState("");
  const [tags, setTags] = useState("");
  const [searchText, setSearchText] = useState("");
  const [targetData, setTargetData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(5);
  const [regionList, setRegionList] = useState([]);
  const [addressDetail, setAddressDetail] = useState("");
  const [postCodeDocument, setPostCodeDocument] = useState(null);
  const [targetRegion, setTargetRegion] = useState(null);

  let firstNum = currentPage - (currentPage % 5);
  let lastNum = currentPage - (currentPage % 5) + 4 > totalPage ? totalPage : currentPage - (currentPage % 5) + 4;

  useEffect(() => {
    const place = postCodeDocument;
    if (place?.road_address) {
      setPlaceName(place.road_address.building_name);
      setPlaceAlias(place.road_address.building_name);
    }
  }, [postCodeDocument]);

  const getCollinsPlaceList = async () => {
    setSpinnerStatus(dispatch, true);
    await fetch(`${API_URL}/api/collins/places?page=${currentPage}&size=10${searchText.trim().length > 0 ? `&search_text=${searchText}` : ""}${targetRegion ? `&code=${targetRegion}` : ""}`, {
      method: "GET",
      credentials: "include"
    }).then(res => res.json().then(r => {
      if (r.data) {
        setTargetData(r.data);
        setCurrentPage(r.page);
        setTotalPage(r.totalElements);
        setSpinnerStatus(dispatch, false);
      }
    }));
  };

  const getCollinsPlaceListByCode = async () => {
    setSpinnerStatus(dispatch, true);
    await fetch(`${API_URL}/api/collins/places/regions`, {
      method: "GET",
      credentials: "include"
    }).then(res => res.json().then(r => {
      if (r.data) {
        setRegionList(r.data);
      }
      setSpinnerStatus(dispatch, false);
    }));
  };

  const addPlace = () => {
    setSpinnerStatus(dispatch, true);
    fetch(`${API_URL}/api/collins/places`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        name: placeName,
        alias: placeAlias,
        phone: placePhone,
        instagram_url: instaUrl,
        link_url: externalUrl,
        description: tags,
        address: postCodeDocument?.road_address?.address_name ? postCodeDocument?.road_address?.address_name : postCodeDocument?.address?.address_name,
        address2: addressDetail,
        region_1depth_name: postCodeDocument?.road_address?.region_1depth_name ? postCodeDocument?.road_address?.region_1depth_name : postCodeDocument?.address?.region_1depth_name,
        region_2depth_name: postCodeDocument?.road_address?.region_2depth_name ? postCodeDocument?.road_address?.region_2depth_name : postCodeDocument?.address?.region_2depth_name,
        region_3depth_name: postCodeDocument?.road_address?.region_3depth_name ? postCodeDocument?.road_address?.region_3depth_name : postCodeDocument?.address?.region_3depth_name,
        zip_code: postCodeDocument?.road_address?.zone_no,
        longitude: postCodeDocument?.x,
        latitude: postCodeDocument?.y
      })
    }).then(res => res.json().then(async r => {
      if (r.data) {
        await getCollinsPlaceList();
        reset();
        setSpinnerStatus(dispatch, false);
        window.alert("추가 되었습니다.");
      }
    }));
  };
  const reset = () => {
    setPlaceName("");
    setPlaceAlias("");
    setPlacePhone("");
    setInstaUrl("");
    setExternalUrl("");
    setTags("");
    setPostCodeDocument(null);
  };

  const { columns, rows } = collinsPlaceTableData(targetData, "collins", getCollinsPlaceList);


  useEffect(() => {
    getCollinsPlaceList();
  }, [currentPage, targetRegion]);

  useEffect(() => {
    getCollinsPlaceListByCode();
  }, []);

  useEffect(() => {
    document.addEventListener("keyup", function(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        document.getElementById("searchButton").click();

      }
    });
    return () => document.removeEventListener("keyup", function(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        document.getElementById("searchButton").click();
      }
    });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDPostCodeModal id={"daum-postcode-modal"} setPostCodeDocument={setPostCodeDocument} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  COLLINS
                </MDTypography>
                <MDTypography variant="h6" color="white">
                  Places
                </MDTypography>
              </MDBox>
              <MDBox
                mx={2}
                mt={2}
                py={3}
                px={2}
                variant="gradient"
                borderRadius="lg"
                display="flex"
                flexWrap="wrap"
              >
                <MDBox display="block">
                  <MDBox flexWrap="wrap" display="flex">
                    <MDBox my={1} minWidth="200px">
                      <MDInput value={placeName} label="이름" onChange={(e) => setPlaceName(e.target.value)} />
                    </MDBox>
                    <MDBox my={1} minWidth="200px">
                      <MDInput value={placeAlias} label="표기명" onChange={(e) => setPlaceAlias(e.target.value)} />
                    </MDBox>
                    <MDBox my={1} mr={2} minWidth="300px">
                      <MDInput label="주소"
                               style={{ width: "100%" }}
                               value={postCodeDocument?.address?.address_name ? postCodeDocument.address.address_name : ""}
                               disabled />
                    </MDBox>
                    <MDBox my={1} mr={2}>
                      <MDButton onClick={() => {
                        document.getElementById("daum-postcode-modal").showModal();
                      }} color={"info"}>주소 검색</MDButton>
                    </MDBox>
                    <MDBox my={1} minWidth="200px">
                      <MDInput value={addressDetail} onChange={e => setAddressDetail(e.target.value)}
                               label="상세 주소" />
                    </MDBox>
                  </MDBox>
                  <MDBox flexWrap="wrap" display="flex">
                    <MDBox my={1} minWidth="200px">
                      <MDInput value={placePhone} onChange={e => setPlacePhone(e.target.value.replaceAll("-", ""))}
                               label="전화번호" />
                    </MDBox>
                    <MDBox my={1} minWidth="200px">
                      <MDInput value={tags} onChange={(e) => setTags(e.target.value)} label="태그(,로 구분)" />
                    </MDBox>
                    <MDBox my={1} minWidth="200px">
                      <MDInput value={instaUrl} onChange={e => setInstaUrl(e.target.value)} label="인스타그램 주소" />
                    </MDBox>
                    <MDBox my={1} minWidth="200px">
                      <MDInput value={externalUrl} onChange={e => setExternalUrl(e.target.value)} label="외부 링크주소" />
                    </MDBox>
                  </MDBox>
                  <MDBox my={1} minWidth="200px">
                    <MDButton color="success" onClick={() => addPlace()}>추가하기</MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox
                mx={2}
                px={2}
                variant="gradient"
                borderRadius="lg"
                display="flex"
                flexWrap="wrap"
              >
                <MDBox display="flex" flexWrap="wrap">
                  <MDBox pr={1} mr={1} my={1} minWidth="200px">
                    <MDInput
                      id={`regionsList`}
                      size="medium"
                      select
                      label="지역 필터"
                      onChange={(e) => {
                        setTargetRegion(e.target.value);
                      }}
                      value={targetRegion}
                      InputProps={{
                        classes: { root: "select-input-styles" }
                      }}
                      fullWidth
                    >
                      {regionList.map((item,key) => <MenuItem
                        key={key}
                        value={item.code}>{item.name}</MenuItem>)}
                    </MDInput>
                  </MDBox>
                  <MDBox pr={1} mr={1} my={1} minWidth="200px">
                    <MDInput label="검색" onChange={(e) => setSearchText(e.target.value)} />
                  </MDBox>
                  <MDBox pr={1} mr={1} my={1}>
                    <MDButton color="success" id={"searchButton"} onClick={() => getCollinsPlaceList()}>검색하기</MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
              <Divider />
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
              <MDBox py={3} display="flex" justifyContent="center">
                <MDPagination>
                  <CustomPagination total={totalPage} itemsPerPage={4} currentPage={currentPage}
                                    setCurrentPage={(page) => setCurrentPage(page)} />
                </MDPagination>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CollinsPlace;
