// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
import { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import { Icon } from "@mui/material";
import MDInput from "../../components/MDInput";
import { API_URL } from "../../constants/constants";
import MDButton from "../../components/MDButton";
import MDPagination from "../../components/MDPagination";
import collinsMusicTableData from "../tables/data/collinsMusicTableData";
import { autoHypenPhone } from "../../Util";
import MenuItem from "@mui/material/MenuItem";
import MDPostCodeModal from "../../components/MDPostCodeModal/MDPostCodeModal";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import React from "react";
import { setSpinnerStatus, useMaterialUIController } from "../../context";

function CollinsMusic() {
  const [, dispatch] = useMaterialUIController();
  const [musicTitle, setMusicTitle] = useState("");
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [videoId, setVideoId] = useState("");

  const [targetData, setTargetData] = useState([]);

  const getCollinsMusicList = async () => {
    setSpinnerStatus(dispatch, true);
    await fetch(`${API_URL}/api/collins/musics`, {
      method: "GET",
      credentials: "include"
    }).then(res => res.json().then(r => {
      if (r.data) {
        setTargetData([]);
        setTargetData([...r.data]);
        setSpinnerStatus(dispatch, false);
      }
    }));
  };

  const reset = () => {
    setVideoId("");
    setMusicTitle("");
    setThumbnailFile("");
  };

  const { columns, rows } = collinsMusicTableData(targetData, "collins", getCollinsMusicList, setTargetData);


  useEffect(() => {
    getCollinsMusicList();
  }, []);

  const addMusic = () => {
    setSpinnerStatus(dispatch, true);
    fetch(`${API_URL}/api/collins/musics`, {
        method: "POST",
        headers: { "content-type": "application/json; charset=utf-8" },
        credentials: "include",
        body: JSON.stringify({ title: musicTitle, thumbnail_url: thumbnailFile, video_id: videoId })
      }
    ).then(res => res.json().then(async r => {
      setSpinnerStatus(dispatch, false);
      if (r.data) {
          await getCollinsMusicList();
          reset();
          window.alert("추가 되었습니다.");
        }
      }
    ));
  };

  useEffect(() => {
    document.addEventListener("keyup", function(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        document.getElementById("searchButton").click();

      }
    });
    return () => document.removeEventListener("keyup", function(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        document.getElementById("searchButton").click();
      }
    });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  COLLINS
                </MDTypography>
                <MDTypography variant="h6" color="white">
                  Music
                </MDTypography>
              </MDBox>
              <MDBox
                mx={2}
                mt={2}
                py={3}
                px={2}
                variant="gradient"
                borderRadius="lg"
                display="flex"
                flexWrap="wrap"
              >
                <MDBox display="block">
                  <MDBox flexWrap="wrap" display="flex">
                    <MDBox my={1} minWidth="200px">
                      <MDTypography component="div" variant="caption" color="text" fontSize="16px" fontWeight="medium">
                        제목
                      </MDTypography>
                      <MDInput value={musicTitle} onChange={(e) => setMusicTitle(e.target.value)} />
                    </MDBox>
                    <MDBox my={1} minWidth="200px">
                      <MDTypography component="div" variant="caption" color="text" fontSize="16px" fontWeight="medium">
                        썸네일 이미지
                      </MDTypography>
                      <MDInput type="file"
                               onChange={e => {
                                 const formData = new FormData();
                                 formData.append("file", e.target.files[0]);
                                 formData.append("fileType", "thumbnail");
                                 fetch(`${API_URL}/api/collins/musics/upload`, {
                                   method: "POST",
                                   credentials: "include",
                                   body: formData
                                 }).then(res => res.json().then(r => {
                                   setThumbnailFile(r.data);
                                 }));
                               }} />
                    </MDBox>
                    <MDBox my={1} mx={1} minWidth="200px">
                      <MDTypography component="div" variant="caption" color="text" fontSize="16px" fontWeight="medium">
                        유튜브 링크
                      </MDTypography>
                      <MDBox display={"flex"} alignItems={"center"}>
                        <MDTypography component="div" variant="caption" color="text" fontSize="16px"
                                      fontWeight="medium">
                          https://www.youtube.com/watch?v=
                        </MDTypography>
                        <MDInput value={videoId} label="videoId"
                                 onChange={e => setVideoId(e.target.value)} />
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDBox my={1} minWidth="200px">
                    <MDButton color="success" onClick={() => {
                      addMusic();
                    }}>추가하기</MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
              {/*<MDBox*/}
              {/*  mx={2}*/}
              {/*  px={2}*/}
              {/*  variant="gradient"*/}
              {/*  borderRadius="lg"*/}
              {/*  display="flex"*/}
              {/*  flexWrap="wrap"*/}
              {/*>*/}
              {/*  <MDBox display="flex" flexWrap="wrap">*/}
              {/*    <MDBox pr={1} mr={1} my={1} minWidth="200px">*/}
              {/*      <MDInput*/}
              {/*        id={`regionsList`}*/}
              {/*        size="medium"*/}
              {/*        select*/}
              {/*        label="지역 필터"*/}
              {/*        onChange={(e) => {*/}
              {/*          setTargetRegion(e.target.value);*/}
              {/*        }}*/}
              {/*        value={targetRegion}*/}
              {/*        InputProps={{*/}
              {/*          classes: { root: "select-input-styles" }*/}
              {/*        }}*/}
              {/*        fullWidth*/}
              {/*      >*/}
              {/*        {regionList.map(item => <MenuItem*/}
              {/*          value={item.code}>{item.name}</MenuItem>)}*/}
              {/*      </MDInput>*/}
              {/*    </MDBox>*/}
              {/*    <MDBox pr={1} mr={1} my={1} minWidth="200px">*/}
              {/*      <MDInput label="검색" onChange={(e) => setSearchText(e.target.value)} />*/}
              {/*    </MDBox>*/}
              {/*    <MDBox pr={1} mr={1} my={1}>*/}
              {/*      <MDButton color="success" id={'searchButton'} onClick={() => getCollinsPlaceList()}>검색하기</MDButton>*/}
              {/*    </MDBox>*/}
              {/*  </MDBox>*/}
              {/*</MDBox>*/}
              <Divider />
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
              <MDBox py={3} display="flex" justifyContent="center">
                <MDPagination>
                  {/*<MDPagination item onClick={() => {*/}
                  {/*  setCurrentPage(currentPage - 1);*/}
                  {/*}}>*/}
                  {/*  <Icon>keyboard_arrow_left</Icon>*/}
                  {/*</MDPagination>*/}

                  {/*{firstNum !== 0 && <MDPagination item*/}
                  {/*                                 active={firstNum === currentPage ? true : false}*/}
                  {/*                                 onClick={() => setCurrentPage(firstNum)}>*/}
                  {/*  {firstNum}*/}
                  {/*</MDPagination>}*/}
                  {/*{Array(4).fill().map((_, i) => {*/}
                  {/*  if (i <= 2) {*/}
                  {/*    return (*/}
                  {/*      <MDPagination item*/}
                  {/*                    onClick={() => setCurrentPage(firstNum + 1 + i)}*/}
                  {/*                    active={firstNum + 1 + i === currentPage ? true : false}>{firstNum + 1 + i}</MDPagination>);*/}
                  {/*  } else if (i >= 3) {*/}
                  {/*    return (*/}
                  {/*      <MDPagination item*/}
                  {/*                    onClick={() => setCurrentPage(lastNum)}*/}
                  {/*                    active={lastNum === currentPage ? true : false}>{lastNum}</MDPagination>*/}
                  {/*    );*/}
                  {/*  }*/}
                  {/*})}*/}
                  {/*<MDPagination item onClick={() => {*/}
                  {/*  setCurrentPage(currentPage + 1);*/}
                  {/*}}>*/}
                  {/*  <Icon>keyboard_arrow_right</Icon>*/}
                  {/*</MDPagination>*/}
                  {/*<CustomPagination total={totalPage} itemsPerPage={4} currentPage={currentPage}*/}
                  {/*                  setCurrentPage={(page) => setCurrentPage(page)} />*/}
                </MDPagination>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CollinsMusic;
