// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
import restockNotificationListData from "layouts/tables/data/restockNotificationListData";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useMemo, useRef, useState } from "react";
import { Icon } from "@mui/material";
import MDInput from "../../components/MDInput";
import { API_URL } from "../../constants/constants";
import MDPagination from "../../components/MDPagination";
import MDButton from "../../components/MDButton";
import { DateRange } from "react-date-range";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import { dateFormat, downloadCSV, getDataRangeInputs, showDataRange, toStringByFormatting } from "../../Util";
import MDReleaseNotificationModifyModal from "../../components/MDReleaseNotificationModifyModal";
import { setSpinnerStatus, useMaterialUIController } from "../../context";


function BaconRestockNotificationList() {
  const [, dispatch] = useMaterialUIController();
  const [restockListData, setRestockListDataData] = useState([]);
  const { columns, rows } = restockNotificationListData(restockListData);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(5);
  const [size, setSize] = useState("20");
  const dataRangeRef = useRef(null);
  const [totalCount, setTotalCount] = useState(0);
  const [isShowDataRange, setIsShowDataRange] = useState(false);
  const [filterDateRange, setFilterDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection"
    }
  ]);
  const getRestockNotificationList = async () => {
    setSpinnerStatus(dispatch, true);
    await fetch(`${API_URL}/api/bacon/wait/list?page=${currentPage}${size === "all" ? "" : `&size=${size}`}${filterDateRange[0].startDate ? `&fromDate=${toStringByFormatting(new Date(filterDateRange[0].startDate))}` : ""}${filterDateRange[0].endDate ? `&toDate=${toStringByFormatting(new Date(filterDateRange[0].endDate))}` : ""}`, { credentials: "include" }).then((res) => res.json().then((r) => {
      setRestockListDataData(r.data);
      const totalPage = Math.ceil(r.totalElements / r.size);
      setTotalPage(totalPage);
      setTotalCount(r.totalElements);
      setSpinnerStatus(dispatch, false);
    }));
  };
  const filterDataRef = useRef(null);

  useEffect(() => {
    getRestockNotificationList();
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    getRestockNotificationList();
  }, [size]);

  useEffect(() => {
    getDataRangeInputs(filterDataRef, setFilterDateRange, setIsShowDataRange);
    document.body.addEventListener("click", (e) => {
      setIsShowDataRange(false);
      e.stopPropagation();
    });
  }, []);

  useEffect(() => {
    showDataRange(isShowDataRange);
  }, [isShowDataRange]);


  const restockListDownload = async () => {
    await fetch(`${API_URL}/api/bacon/wait/list?${filterDateRange[0].startDate ? `&fromDate=${toStringByFormatting(new Date(filterDateRange[0].startDate))}` : ""}${filterDateRange[0].endDate ? `&toDate=${toStringByFormatting(new Date(filterDateRange[0].endDate))}` : ""}`, { credentials: "include" }).then((res) => res.json().then((r) => {
      const fileName = `재입고알림신청현황_베이컨_${filterDateRange[0].startDate ? toStringByFormatting(new Date(filterDateRange[0].startDate)).replaceAll("-", "") : ""}-${filterDateRange[0].endDate ? toStringByFormatting(new Date(filterDateRange[0].endDate)).replaceAll("-", "") : ""}`;
      if (r.data) {
        const columnName = ["ID", "PRODUCT ID", "제품명", "이름", "휴대전화 번호", "등록일"];
        const csv = [];
        const rows = r.data;
        csv.push(columnName);
        rows.map((dataRow) => {
          const row = [];
          row.push(dataRow.id);
          row.push(dataRow.wp_product_id);
          row.push(dataRow.wp_product_title);
          row.push(dataRow.dest_name);
          row.push(dataRow.dest_phone);
          row.push(dataRow.registed_date);
          csv.push(row.join(","));
        });
        downloadCSV(csv.join("\n"), `${fileName}.csv`);
      }
    }));
  };

  useEffect(() => {
    getRestockNotificationList();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg"
                     coloredShadow="info">
                <MDTypography variant="h6" color="white">
                  BACONBOX
                </MDTypography>
                <MDTypography variant="h6" color="white">
                  재입고 알림 현황 조회
                </MDTypography>
              </MDBox>
              <MDBox mx={1} mt={1} py={1} px={1} variant="gradient" borderRadius="lg" display="flex"
                     style={{
                       flexDirection: "row",
                       flexWrap: "wrap",
                       alignItems: "center"
                     }}
              >
                <MDBox pr={1} mx={1}>
                  <DateRange
                    ref={dataRangeRef}
                    editableDateInputs={true}
                    onChange={(item) => {
                      filterDataRef.current = item.selection;
                      setFilterDateRange([item.selection]);
                    }}

                    moveRangeOnFirstSelection={false}
                    ranges={
                      filterDateRange
                    }
                    // locale={'korean'}
                  />
                </MDBox>
                {/*<MDBox pr={1} mx={1}>*/}
                {/*  <MDButton color={"secondary"} onClick={() => setFilterDateRange([*/}
                {/*    {*/}
                {/*      startDate: null,*/}
                {/*      endDate: null,*/}
                {/*      key: "selection"*/}
                {/*    }*/}
                {/*  ])}>날짜 삭제</MDButton>*/}
                {/*</MDBox>*/}
                <MDBox pr={1} mx={1}>
                  <MDButton color={"primary"} onClick={() => {
                    if (currentPage !== 1) {
                      setCurrentPage(1);
                    } else {
                      getRestockNotificationList();
                    }
                  }}>결과 조회</MDButton>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                <MDBox pr={3} pl={3} style={{ justifyContent: "right", width: "100%", display: "flex" }}>
                  <MDButton color={"info"} onClick={() => restockListDownload()}>CSV 다운로드</MDButton>
                </MDBox>
                <MDBox pr={3} pl={3} my={2} style={{ justifyContent: "space-between", width: "100%", display: "flex" }}>
                  <MDBox width="200px">
                    <MDInput
                      size="medium"
                      select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={size}
                      label="페이지 당 표시 갯수"
                      onChange={(e) => setSize(e.target.value)}
                      InputProps={{
                        classes: { root: "select-input-styles" }
                      }}
                      fullWidth
                    >
                      <MenuItem value="10">10</MenuItem>
                      <MenuItem value="20">20</MenuItem>
                      <MenuItem value="30">30</MenuItem>
                      <MenuItem value="50">50</MenuItem>
                      <MenuItem value="100">100</MenuItem>
                    </MDInput>
                  </MDBox>
                  <MDBox style={{ display: "flex", alignItems: "center" }}>
                    <MDTypography style={{ fontSize: "13px" }} color="black">{totalCount}개의 재입고 알림 신청</MDTypography>
                  </MDBox>
                </MDBox>
                <DataTable table={{ columns, rows }} isSorted={false}
                           showTotalEntries={false} noEndBorder />
              </MDBox>

              <MDBox py={3} display="flex" justifyContent="center">
                <MDPagination>
                  <CustomPagination total={totalPage} itemsPerPage={4} currentPage={currentPage}
                                    setCurrentPage={(page) => setCurrentPage(page)} />
                </MDPagination>
              </MDBox>
            </Card>

          </Grid>
          {/*<Grid item xs={12}>*/}
          {/*    <Card>*/}
          {/*        <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">*/}
          {/*            <MDTypography variant="h6" color="white">*/}
          {/*                Projects Table*/}
          {/*            </MDTypography>*/}
          {/*        </MDBox>*/}
          {/*        <MDBox pt={3}>*/}
          {/*            <DataTable table={{ columns: pColumns, rows: pRows }} isSorted={false} entriesPerPage={false} showTotalEntries={false} noEndBorder />*/}
          {/*        </MDBox>*/}
          {/*    </Card>*/}
          {/*</Grid>*/}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BaconRestockNotificationList;
