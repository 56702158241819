import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import { useEffect, useState } from "react";
import { API_URL } from "../../../constants/constants";
import { useNavigate } from "react-router-dom";


export default function data(data, pageType, callBack) {
  const [updatePlaceId, setUpdatePlaceId] = useState("");
  const navigate = useNavigate();
  const updatePlace = () => {
    const placeName = document.getElementById(`placeName${updatePlaceId}`).value;
    const placeAlias = document.getElementById(`placeAlias${updatePlaceId}`).value;
    const placeInsta = document.getElementById(`placeInsta${updatePlaceId}`).value;
    const placeLink = document.getElementById(`placeLink${updatePlaceId}`).value;
    const placeDescription = document.getElementById(`placeDescription${updatePlaceId}`).value;
    const placePhone = document.getElementById(`placePhone${updatePlaceId}`).value;

    fetch(`${API_URL}/api/collins/places/${updatePlaceId}`, {
      method: "put", headers: { "content-type": "application/json" }, body: JSON.stringify({
        "id": updatePlaceId,
        "name": placeName,
        "alias": placeAlias,
        "instagram_url": placeInsta,
        "link_url": placeLink,
        "description": placeDescription,
        "phone": placePhone.replaceAll("-", "")
      }), credentials: "include"
    }).then(res => res.json().then(r => {
      if (r) {
        setUpdatePlaceId("");
        window.alert("수정 완료 되었습니다.");
        callBack();
      }
    }));
  };


  return {
    columns: [
      { Header: "ID", accessor: "boardId", align: "center" },
      { Header: "제목", accessor: "title", align: "center" },
      { Header: "작성자", accessor: "writer", align: "center" },
      { Header: "작성일", accessor: "createAt", align: "center" },
      { Header: "수정일", accessor: "updateAt", align: "center" }
    ],

    rows: data.map((item) => ({
      boardId: (
        <MDTypography component="div" onClick={() => navigate(`/collins/brandArchive/editor?id=${item.id}`)}
                      variant="caption" style={{ cursor: "pointer" }}
                      color="text" fontWeight="medium">
          {item.id}
        </MDTypography>
      ),
      title: (
        <MDTypography component="div" onClick={() => navigate(`/collins/brandArchive/editor?id=${item.id}`)}
                      style={{ cursor: "pointer" }}
                      variant="caption" color="text"
                      fontWeight="bold">
          {item.title}
        </MDTypography>
      ),
      writer: (
        <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
          {item.writer}
        </MDTypography>
      ),
      createAt: (
        <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
          {item.register_date}
        </MDTypography>
      ),
      updateAt: (
        <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
          {item.update_date}
        </MDTypography>
      )
    }))
  };
};
