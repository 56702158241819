import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useEffect, useState } from "react";
import { API_URL, WP_BACON_URL, WP_COLLINS_URL } from "../../../constants/constants";
import {
  couponNameConverter,
  couponStatusConverter,
  deliveryData,
  distStatusConverter,
  invoiceData
} from "../../../Util";
import MDButton from "../../../components/MDButton";
import MDInput from "../../../components/MDInput";

export default function data(data, type) {
  return {
    columns: [
      { Header: "송장 확정 상태", accessor: "status", align: "center" },
      { Header: "주문정보", accessor: "orderInfo", align: "left" },
      { Header: "주문아이템 정보", accessor: "orderItemInfo", align: "left" },
      { Header: "송장정보", accessor: "invoiceInfo", align: "center" }
    ],

    rows: data.map((item) => ({
      status: (
        <MDTypography component="div" variant="caption" color={item.status ? "success" : "error"} fontWeight="medium">
          {item.status ? "확정" : "미확정"}
        </MDTypography>
      ),
      orderInfo: (
        <div style={{ textAlign: "left", width: "100%" }}>
          <div>송장ID:{item.id}</div>
          <MDTypography component="a"
                        href={(type === "collins" ? WP_COLLINS_URL : WP_BACON_URL) + `/post.php?post=${item.orderId}&action=edit`}
                        target={"_blank"}
                        variant="caption" color="info" fontWeight="medium">
            #{item.orderId}
          </MDTypography>
          {item.errorLog !== null && <MDTypography fontSize={'13px'} fontWeight={"bold"} color={"error"}>{item.errorLog}</MDTypography>}
          <div>{item.shippingFirstName + " " + item.shippingLastName}</div>
          <div>{item.shippingAddress1}</div>
          <div>{item.shippingAddress2}</div>
          <div>{item.shippingPostcode}</div>
        </div>

      ),
      orderItemInfo: (
        <div style={{ textAlign: "left" }}>
          {item.orderItemList?.map((orderItem, key) =>
            <div key={key}>{"#" + orderItem.orderItemId + ", " + orderItem.orderItemName}</div>)}
        </div>
      ),
      invoiceInfo: (
        <MDTypography component="a" href={deliveryData(item.divCode, item.sheetNo)}
                      target="_blank"
                      variant="caption" color="info"
                      fontWeight="medium">{invoiceData[item.divCode.toUpperCase()] + "(" + item.sheetNo + ")"}</MDTypography>
      )
    }))
  };
};
