import React, { useRef, useEffect, useState } from "react";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import { Divider } from "@mui/material";
import MDButton from "../MDButton";
import "./index.css";
import Select from "react-select";

function MDSorryBoxModal(props) {
  const { productList, id, hotKeyIndex, data, setHotKeyIndex, orderId, propsTargetProductList, type } = props;
  const [targetProductList, setTargetProductList] = useState([]);
  const [targetId, setTargetId] = useState();


  useEffect(() => {
    if (hotKeyIndex !== null) {
      const tempData = data[hotKeyIndex];
      if (tempData.itemIds && tempData.itemIds.length > 0) {
        const tempProduct = productList.filter(item => (tempData.itemIds.some(itemId => Number(item.value) === Number(itemId)) || propsTargetProductList.some(itemId => Number(itemId.variation_id ? itemId.variation_id : itemId.product_id) === Number(item.value) && itemId.type === "line_items")));
        if (tempProduct.length > 0) {
          tempProduct.map(item => {
            targetProductList.push({ id: item.value, title: item.label });
          });
          setTargetProductList(() => [...targetProductList]);
        }
      }
    } else {
      setTargetProductList([]);
    }
  }, [hotKeyIndex, data, propsTargetProductList]);

  const addProduct = () => {
    if (targetId) {
      targetProductList.push({ id: targetId.value, title: targetId.label });
      setTargetProductList(() => [...targetProductList]);

      document.getElementById("productCount").value = 1;
    }
  };

  const deleteProduct = (i) => {
    targetProductList.splice(i, 1);
    setTargetProductList([...targetProductList]);
  };


  return (
    <dialog id={id}>
      <MDBox py={3} px={5} minWidth="600px" className={"modal-wrapper"}>
        <MDBox display={"flex"} style={{ justifyContent: "space-between" }}>
          <MDTypography component="div" variant="h5"
                        color="text"
                        fontWeight="medium">쏘리 박스 편성</MDTypography>
        </MDBox>
        <MDBox display="flex" mt={2} justifyContent="space-between">
          <MDTypography component="div" variant="h6" color="text" fontWeight="medium">상품 [쏘리 박스 편성에 포함 할 상품을 추가, 삭제
            해주세요.]</MDTypography>
        </MDBox>
        <MDBox display="flex" mt={1} justifyContent="space-between">
          <MDBox width={"300px"}>
            <Select
              id="productName"
              className="basic-single"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              name="color"
              options={productList}
              onChange={(e) => setTargetId(e)}
            />
          </MDBox>
          <MDBox display={"flex"}>
            <MDBox><MDButton color={"info"} onClick={() => addProduct()}>+</MDButton></MDBox>
          </MDBox>
        </MDBox>
        <MDBox mt={3} style={{
          maxHeight: "500px",
          overflow: "scroll"
        }}>
          {targetProductList.map((target, i) => <MDBox key={i} display="flex" mt={1} justifyContent="space-between"
                                                       style={{ alignItems: "center" }}>
            <MDBox width={"300px"}>
              <MDTypography component="div" variant="h6" color="text"
                            fontWeight="medium">{target.title}</MDTypography>
            </MDBox>
            <MDBox display={"flex"} style={{ alignItems: "center" }}>
              <MDBox ml={1}><MDButton color={"info"}
                                      onClick={() => deleteProduct(i)}>x</MDButton></MDBox>
            </MDBox>
          </MDBox>)}
        </MDBox>
        <MDBox display="flex" mt={4} justifyContent="space-between">
          <MDButton value="cancel" color="secondary"
                    onClick={() => {
                      setHotKeyIndex(null);
                      setTargetProductList([]);
                      document.getElementById(id).close();
                    }}>취소</MDButton>
          <MDButton value="default" color="dark" onClick={() => {
            document.getElementById(id).close();
            setTargetProductList([]);
            setHotKeyIndex(null);
            window.location.href = `/${type}/order/new?orderId=${orderId}&customerId=${data[hotKeyIndex].customer?.id}&baseItemId=${targetProductList.map(item => item.id)}&status=${data[hotKeyIndex].status}`;
          }}>생성하러 가기</MDButton>
        </MDBox>
      </MDBox>
    </dialog>
  );
}

export default MDSorryBoxModal;
