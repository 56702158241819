// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
import baconOrderItemListTableData from "layouts/tables/data/baconOrderItemListTableData";
import distSnackTableData from "layouts/tables/data/distSnackTableData";

import projectsTableData from "layouts/tables/data/projectsTableData";
import MenuItem from "@mui/material/MenuItem";
import { useEffect } from "react";
import Divider from "@mui/material/Divider";
import { Icon } from "@mui/material";
import MDInput from "../../components/MDInput";
import {
  API_URL,
  WP_BACON_CONSUMER_KEY,
  WP_BACON_CONSUMER_SECRET,
  WP_BACON_REST_API_URL, WP_COLLINS_CONSUMER_KEY, WP_COLLINS_CONSUMER_SECRET, WP_COLLINS_REST_API_URL
} from "../../constants/constants";
import MDButton from "../../components/MDButton";
import MDPagination from "../../components/MDPagination";
import { useState } from "react";
import {
  collinsDownload,
  download,
  downloadBB,
  downloadBBForKyoWon, downloadBBSForKyoWon,
  downloadBSForKyoWon, downloadForKyoWon,
  downloadNotBB,
  downloadNotBBExtraExternal
} from "../../Util";
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";


const pathName = window.location.pathname;
const orderArr = pathName.split("/");
const orderId = orderArr[orderArr.length - 1];

function OrderDetailNew(props) {
  const { type } = props;
  const [orderDetailData, setOrderDetailData] = useState({});
  const [filterArr, setFilterArr] = useState([]);
  const [targetData, setTargetData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [orderItemListData, setOrderItemListData] = useState([]);

  const [productList, setProductList] = useState([]);
  const [results, setResults] = useState([]);
  const { columns, rows } = baconOrderItemListTableData(orderItemListData);
  // const { columns1, rows1 } = distSnackTableData(results);
  const [snackFile, setSnackFile] = useState("");
  const [snackDbPath, setSnackDbPath] = useState("");

  const getBaconOrderDetail = async () => {
    await fetch(`${API_URL}/api/${type}/products`, { credentials: "include" }).then((res) => res.json().then((r) => {
      setProductList(r.data);
    }));
    if (type === "bacon") {
      await fetch(`${WP_BACON_REST_API_URL}/orders/${orderId}?consumer_key=${WP_BACON_CONSUMER_KEY}&consumer_secret=${WP_BACON_CONSUMER_SECRET}`, { credentials: "include" }).then((res) => res.json().then((r) => {
        setOrderData(r);
        setOrderItemListData(r.line_items ? [...r.line_items, ...r.shipping_lines] : []);
      }));
    } else if (type === "collins") {
      await fetch(`${WP_COLLINS_REST_API_URL}/orders/${orderId}?consumer_key=${WP_COLLINS_CONSUMER_KEY}&consumer_secret=${WP_COLLINS_CONSUMER_SECRET}`, { credentials: "include" }).then((res) => res.json().then((r) => {
        setOrderData(r);
        setOrderItemListData(r.line_items ? [...r.line_items, ...r.shipping_lines] : []);
      }));
    }


  };


  useEffect(() => {
    getBaconOrderDetail();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  주문 수정하기
                </MDTypography>
              </MDBox>
              <MDBox mx={2} mt={2} py={3} px={2} borderRadius="lg" display="block">
                <MDTypography variant="h5" color="white">
                  주문 #{orderData.id}
                </MDTypography>
                <MDBox pr={1} mr={1} my={1} minWidth="200px">
                  <MDTypography variant="h6" color="black">
                    일반
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" flexWrap="wrap">
                  <MDBox pr={1} mr={1} my={1}>
                    <MDInput style={{ minWidth: "250px" }} label="생성 날짜"
                             value={orderData.date_created ? new Date(orderData.date_created).toLocaleString() : ""}
                             disabled />
                  </MDBox>
                  <MDBox pr={1} mr={1} my={1} minWidth="200px">
                    <MDInput label="상태"
                             value={orderData?.status ? orderData?.status : ""}
                             disabled />
                  </MDBox>
                  <MDBox pr={1} mr={1} my={1} minWidth="200px">
                    <MDInput label="고객"
                             value={orderData?.billing?.first_name ? orderData?.billing?.first_name : ""}
                             disabled />
                  </MDBox>
                </MDBox>
                <MDBox display="flex" flexWrap="wrap">
                  <MDBox style={{ width: "50%" }} display="block">
                    <MDBox my={1} minWidth="100%">
                      <MDTypography variant="h6" color="black">
                        청구
                      </MDTypography>
                    </MDBox>
                    <MDBox display={"grid"} width="100%" style={{ gridTemplateColumns: "repeat(2,1fr)" }}>
                      {orderData.billing?.first_name && <MDBox pr={1} mr={1} my={1}>
                        <MDInput label="이름"
                                 value={orderData.billing?.first_name ? orderData.billing?.first_name : ""}
                                 fullWidth
                        />
                      </MDBox>}
                      {orderData.billing?.last_name && <MDBox pr={1} mr={1} my={1}>
                        <MDInput label="성"
                                 value={orderData.billing?.last_name ? orderData.billing?.last_name : ""}
                                 fullWidth />
                      </MDBox>}
                      {orderData.billing?.company && <MDBox pr={1} mr={1} my={1}>
                        <MDInput label="회사"
                                 value={orderData.billing?.company ? orderData.billing?.company : ""}
                                 fullWidth />
                      </MDBox>}
                      {orderData.billing?.address_1 && <MDBox pr={1} mr={1} my={1}>
                        <MDInput label="주소1"
                                 value={orderData.billing?.address_1 ? orderData.billing?.address_1 : ""}
                                 fullWidth
                        />
                      </MDBox>}
                      {orderData.billing?.address_2 && <MDBox pr={1} mr={1} my={1}>
                        <MDInput label="주소2"
                                 value={orderData.billing?.address_2 ? orderData.billing?.address_2 : ""}
                                 fullWidth />
                      </MDBox>}
                      {orderData.billing?.city && <MDBox pr={1} mr={1} my={1}>
                        <MDInput label="도시"
                                 value={orderData.billing?.city ? orderData.billing?.city : ""}
                                 fullWidth />
                      </MDBox>}
                      {orderData.billing?.postcode && <MDBox pr={1} mr={1} my={1}>
                        <MDInput label="우편번호"
                                 value={orderData.billing?.postcode ? orderData.billing?.postcode : ""}
                                 fullWidth />
                      </MDBox>}
                      {orderData.billing?.country && <MDBox pr={1} mr={1} my={1}>
                        <MDInput label="국가"
                                 value={orderData.billing?.country ? orderData.billing?.country : ""}
                                 fullWidth />
                      </MDBox>}
                      {orderData.billing?.state && <MDBox pr={1} mr={1} my={1}>
                        <MDInput label="주/군"
                                 value={orderData.billing?.state ? orderData.billing?.state : ""}
                                 fullWidth />
                      </MDBox>}
                      {orderData.billing?.email && <MDBox pr={1} mr={1} my={1}>
                        <MDInput label="이메일 주소"
                                 value={orderData.billing?.email ? orderData.billing?.email : ""}
                                 fullWidth />
                      </MDBox>}
                      {orderData.billing?.phone && <MDBox pr={1} mr={1} my={1}>
                        <MDInput label="전화번호"
                                 value={orderData.billing?.phone ? orderData.billing?.phone : ""}
                                 fullWidth />
                      </MDBox>}
                      {orderData?.payment_method_title && <MDBox pr={1} mr={1} my={1}>
                        <MDInput label="결제 방법"
                                 value={orderData?.payment_method_title ? orderData?.payment_method_title : ""}
                                 fullWidth />
                      </MDBox>}
                      {orderData?.transaction_id && <MDBox pr={1} mr={1} my={1}>
                        <MDInput label="거래 ID"
                                 value={orderData?.transaction_id ? orderData?.transaction_id : ""}
                                 fullWidth />
                      </MDBox>}
                    </MDBox>
                  </MDBox>
                  <MDBox style={{ width: "50%" }} display="block">
                    <MDBox my={1} minWidth="100%">
                      <MDTypography variant="h6" color="black">
                        배송
                      </MDTypography>
                    </MDBox>
                    <MDBox display={"grid"} width="100%" style={{ gridTemplateColumns: "repeat(2,1fr)" }}>
                      {orderData.shipping?.first_name && <MDBox pr={1} mr={1} my={1}>
                        <MDInput label="이름"
                                 value={orderData.shipping?.first_name ? orderData.shipping?.first_name : ""}
                                 fullWidth
                        />
                      </MDBox>}
                      {orderData.shipping?.last_name && <MDBox pr={1} mr={1} my={1}>
                        <MDInput label="성"
                                 value={orderData.shipping?.last_name ? orderData.shipping?.last_name : ""}
                                 fullWidth
                        />
                      </MDBox>}
                      {orderData.shipping?.company && <MDBox pr={1} mr={1} my={1}>
                        <MDInput label="회사"
                                 value={orderData.shipping?.company ? orderData.shipping?.company : ""}
                                 fullWidth
                        />
                      </MDBox>}
                      {orderData.shipping?.address_1 && <MDBox pr={1} mr={1} my={1}>
                        <MDInput label="주소1"
                                 value={orderData.shipping?.address_1 ? orderData.shipping?.address_1 : ""}
                                 fullWidth
                        />
                      </MDBox>}
                      {orderData.shipping?.address_2 && <MDBox pr={1} mr={1} my={1}>
                        <MDInput label="주소2"
                                 value={orderData.shipping?.address_2 ? orderData.shipping?.address_2 : ""}
                                 fullWidth
                        />
                      </MDBox>}
                      {orderData.shipping?.city && <MDBox pr={1} mr={1} my={1}>
                        <MDInput label="도시"
                                 value={orderData.shipping?.city ? orderData.shipping?.city : ""}
                                 fullWidth
                        />
                      </MDBox>}
                      {orderData.shipping?.postcode && <MDBox pr={1} mr={1} my={1}>
                        <MDInput label="우편번호"
                                 value={orderData.shipping?.postcode ? orderData.shipping?.postcode : ""}
                                 fullWidth />
                      </MDBox>}
                      {orderData.shipping?.country && <MDBox pr={1} mr={1} my={1}>
                        <MDInput label="국가"
                                 value={orderData.shipping?.country ? orderData.shipping?.country : ""}
                                 fullWidth />
                      </MDBox>}
                      {orderData.shipping?.state && <MDBox pr={1} mr={1} my={1}>
                        <MDInput label="주/군"
                                 value={orderData.shipping?.state ? orderData.shipping?.state : ""}
                                 fullWidth />
                      </MDBox>}
                      {orderData.meta_data?.filter(item => item.key === "_shipping_phone1").length > 0 &&
                        <MDBox pr={1} mr={1} my={1}>
                          <MDInput label="배송지 전화번호 1"
                                   value={orderData.meta_data?.filter(item => item.key === "_shipping_phone1")[0].value}
                                   fullWidth />
                        </MDBox>}
                      {orderData.meta_data?.filter(item => item.key === "_shipping_phone2").length > 0 &&
                        <MDBox pr={1} mr={1} my={1}>
                          <MDInput label="배송지 전화번호 2"
                                   value={orderData.meta_data?.filter(item => item.key === "_shipping_phone2")[0].value}
                                   fullWidth />
                        </MDBox>}
                      {orderData?.customer_note && <MDBox pr={1} mr={1} my={1}>
                        <MDInput label="고객이 남긴 메모"
                                 multiline rows={4}
                                 value={orderData?.customer_note ? orderData?.customer_note : ""}
                                 fullWidth />
                      </MDBox>}
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2} mt={2} py={3} px={2} borderRadius="lg" display="block">
                <MDBox pr={1} mr={1} my={1} minWidth="200px">
                  <MDTypography variant="h6" color="black">
                    상품 리스트
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" flexWrap="wrap">
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={true}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </MDBox>
              <MDBox mx={2} px={2} mb={4} borderRadius="lg" display="block">
                <MDBox display="grid"
                       style={{ gridTemplateColumns: "repeat(2,1fr)", gap: "5px", float: "right", textAlign: "right" }}
                       padding={"0 30px"} fontSize={"14px"}>
                  <MDBox>품목 소계 :</MDBox>
                  <MDBox> {Number(orderData.total) + Number(orderData.discount_total)}원</MDBox>
                  <MDBox>쿠폰 :</MDBox>
                  <MDBox>{"- " + orderData.discount_total}원</MDBox>
                  <MDBox>주문 총계 :</MDBox>
                  <MDBox> {Number(orderData.total)}원</MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default OrderDetailNew;
