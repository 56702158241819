import MDTypography from "components/MDTypography";
import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";
import { useEffect, useState } from "react";
import { WP_BACON_URL, WP_COLLINS_URL } from "../../../constants/constants";


export default function data(data, setData, editable, modifyOrder, type) {
  const [targetEditable, setTargetEditable] = useState(null);
  const [targetItem, setTargetItem] = useState(null);

  const enhanceButtonFunctionality = () => {
    const targetElement = document.getElementById("order_button");

    if (targetElement) {
      const originalHandler = targetElement.onclick; // 기존 핸들러 저장

      targetElement.onclick = (event) => {
        // 추가 동작 실행
        clickBodyCalculate()

        // 기존 동작 실행
        if (originalHandler) originalHandler(event);
      };
    }
  };

  useEffect(() => {
    document.body.addEventListener("click", clickBodyCalculate);
    enhanceButtonFunctionality();
    return () => {
      document.body.removeEventListener("click", clickBodyCalculate);
    };
  }, [targetItem, targetEditable]);

  const clickBodyCalculate = () => {
    if (targetEditable !== null) {
      calculateData(targetItem, targetEditable);
      setTargetEditable(null);
      setTargetItem(null);
    }
  };
  const calculateData = (item, target) => {
    const countValue = document.getElementById(`detailCount${target}`).value;
    const totalValue = document.getElementById(`detailTotal${target}`).value;

    const eachPrice = Number(item.subtotal) / Number(item.quantity);
    if (countValue !== "") {
      item.subtotal = String(eachPrice * Number(countValue));
      item.price = Number(countValue) === 0 ? 0 : Number(totalValue) / Number(countValue);
      item.quantity = countValue;
      item.total = String(item.price * Number(countValue));
    } else {
      item.total = totalValue;
    }

    setData([...data]);
  };

  const deleteData = (target) => {
    const temporaryData = target;
    temporaryData.subtotal = "0";
    temporaryData.price = 0;
    temporaryData.quantity = 0;
    temporaryData.total = "0";
    setData([...data]);
  };
  const deleteCouponData = async (target) => {
    data.map((item, i) => {
      if (item.code === target.code) {
        data.splice(i);
      }
    });
    setData([...data]);
    modifyOrder();
  };

  const deleteShippingData = (target) => {
    const temporaryData = target;
    if (temporaryData.title) {
      temporaryData.title = null;
    }
    if (temporaryData.method_title) {
      temporaryData.method_title = null;
    }
    if (temporaryData.name) {
      temporaryData.name = null;
    }
    temporaryData.total = "0";
    setData([...data]);
  };

  const changeCount = (count, target) => {
    document.getElementById(`detailTotal${target}`).value = Number(data[target].price) * Number(count);

  };
  const pattern3 = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; //한글
  return {
    columns: [
      { Header: "항목", accessor: "itemInfo", align: "left" },
      { Header: "비용", accessor: "price", align: "center" },
      { Header: "수량", accessor: "count", align: "center" },
      { Header: "총계", accessor: "totalPrice", align: "center" },
      { Header: "", accessor: "actionBox", align: "center" }
    ],

    rows: data.filter(targetItem => targetItem.type !== "line_items" || targetItem.quantity > 0)
      .filter(targetItem => targetItem.type !== "shipping_lines" || targetItem.method_title !== null)
      .filter(targetItem => targetItem.type !== "fee_lines" || targetItem.name !== null)
      .map((item, i) => ({
        itemInfo: (
          <MDTypography component="a"
                        variant="caption" color="text"
                        fontWeight="medium">
            {item.product_id ? <a
                href={`${type === "bacon" ? WP_BACON_URL : WP_COLLINS_URL}/post.php?post=${item.product_id}&action=edit`}>{item.name ? item.name : item.method_title}</a> :
              <>{item.name ? item.name : item.method_title}</>}
            {item.meta_data?.map(metaItem =>
              pattern3.test(metaItem.display_key) && <>
                <br />{metaItem.display_key} : {String(metaItem.display_value).length > 25 ? String(metaItem.display_value).substr(0, 25) + "..." : String(metaItem.display_value).substr(0, 25)}
              </>)}
            {item.sku && <><br />SKU : {item.sku}</>}
            {(item.variation_id || item.product_id) ? <><br />ID
              : {item.variation_id ? item.variation_id : item.product_id}</> : null}
            {item.attributes ? item.attributes.map(attribute => <>
              <br />{attribute.name} : {attribute.option}</>) : item.attribute ? <>
              <br />{item.attribute}</> : null}
            {item.code ? <>쿠폰 : {item.code}</> : null}
            {/*{item.meta_data?.map(metaData => metaData.display_key && <>*/}
            {/*  <br />{metaData.display_key}:{String(metaData.display_value)}</>)}*/}
          </MDTypography>
        ),
        price: (
          <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
            {item.subtotal && <>{Number(item.subtotal) / Number(item.quantity)}원</>}
          </MDTypography>
        ),
        count: (
          <MDInput defaultValue={item.quantity}
                   id={`detailCount${i}`}
                   onClick={(e) => {
                     e.preventDefault();
                     e.stopPropagation();
                   }}
                   key={`totalKey${i}-${item.quantity}`}
                   disabled={i !== targetEditable || !item.quantity}
                   onChange={(e) => changeCount(e.target.value, i)}
                   type={i !== targetEditable ? "text" : "number"}
          />
        ),
        totalPrice: (
          <div key={`totalKey${i}`}>
            {
              item.code ?
                <MDInput defaultValue={"-" + (item.discount)}
                         key={`totalKey${item.discount}`}
                         id={`detailTotal${i}`}
                         type={"text"}
                         onClick={(e) => {
                           e.preventDefault();
                           e.stopPropagation();
                         }}
                         disabled={true} /> :
                <>
                  <MDInput defaultValue={Number(item.total)}
                           key={`totalKey${item.total}`}
                           id={`detailTotal${i}`}
                           onClick={(e) => {
                             e.preventDefault();
                             e.stopPropagation();
                           }}
                           type={i !== targetEditable ? "text" : "number"}
                           disabled={i !== targetEditable} />
                  {item.subtotal && Number(item.subtotal) - Number(item.total) !== 0 ?
                    <MDBox fontSize={"11px"} style={{ padding: 0, height: "10px", alignItems: "center" }}
                           color={"#888"}>{Number(item.subtotal) - Number(item.total)}원 할인</MDBox> : null}
                </>}
          </div>
        ),
        actionBox: (
          editable ? <MDBox display={"flex"} style={{ textAlign: "left" }}>
            {item.type !== "coupon_lines_base" && <MDBox style={{ width: "40px", cursor: "pointer", display: "flex" }}>
              {targetEditable !== i ? <img style={{ width: "40px" }}
                                           onClick={(e) => {

                                             setTargetEditable(i);
                                             setTargetItem(item);
                                             e.preventDefault();
                                             e.stopPropagation();
                                           }}
                                           src={require("../../../assets/images/icons/edit_icon.png")} /> :
                <img
                  onClick={() => {
                    setTargetEditable(null);
                    calculateData(item, i);
                  }}
                  style={{ width: "20px" }} src={require("../../../assets/images/icons/done.png")} />}
            </MDBox>}
            <MDBox style={{ width: "20px", cursor: "pointer", display: "flex", alignItems: "center" }}
                   onClick={() => {
                     if (item.type === "coupon_lines_base") {
                       deleteCouponData(item);
                     } else if (item.type === "line_items") {
                       deleteData(item);
                     } else if (item.type === "shipping_lines" || item.type === "fee_lines") {
                       deleteShippingData(item);
                     }
                   }}>
              <img style={{ width: "100%", display: "flex", height: "20px" }}
                   src={require("../../../assets/images/icons/delete_icon.png")} />
            </MDBox>
          </MDBox> : <MDBox></MDBox>
        )
      }))
  };
}
