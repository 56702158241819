import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useEffect, useState } from "react";
import { API_URL } from "../../../constants/constants";
import { distStatusConverter } from "../../../Util";

export default function data(data, type) {
    return {
        columns: [
            { Header: "ID", accessor: "id", align: "center" },
            { Header: "product Id", accessor: "productId", align: "center" },
            { Header: "제품명", accessor: "productName", align: "center" },
            { Header: "이름", accessor: "userName", align: "center" },
            { Header: "휴대전화 번호", accessor: "userPhone", align: "center" },
            { Header: "등록일", accessor: "registerDate", align: "center" },
        ],

        rows: data.map((item) => ({
            id: (
                <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                    {item.id}
                </MDTypography>
            ),
            productId: (
                <MDTypography variant="caption" color="text"
                              fontWeight="medium">
                    {item.wp_product_id}
                </MDTypography>
            ),
            productName: (
                <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                    {item.wp_product_title}
                </MDTypography>
            ),
            userName: (
              <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                  {item.dest_name}
              </MDTypography>
            ),
            userPhone: (
              <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                  {item.dest_phone}
              </MDTypography>
            ),
            registerDate: (
                <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                    {item.registed_date}
                </MDTypography>
            ),

        })),
    };
}
