import React from "react";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import { Divider } from "@mui/material";
import MDButton from "../MDButton";
import "./index.css";
import GoogleIcon from "../../assets/images/icons/Google-Icon.png";

function MDLoginModal(props) {
  const { id, title, action } = props;
  return (
    <dialog id={id}>
      <MDBox py={3} px={5} minWidth="250px">
        <MDTypography component="div" variant="h4" color="text" style={{ textAlign: "center" }}
                      fontWeight="medium">GITT ADMIN</MDTypography>
        <MDTypography component="div" variant="h5" color="text" style={{ textAlign: "center" }}
                      fontWeight="medium">로그인</MDTypography>
        <MDBox display="flex" mt={4} justifyContent="space-around">
          <MDButton value="default" color="dark" onClick={() => action && action()}>
            <MDBox component="img" alt="google_login" width="1rem" src={GoogleIcon} mr={1}/>구글 로그인</MDButton>
        </MDBox>
      </MDBox>
    </dialog>
  );
}

export default MDLoginModal;