import { useEffect, useState } from "react";
import MDPagination from "../MDPagination";

function CustomPagination({ total, itemsPerPage, setCurrentPage, currentPage }) {

  // 전체 페이지 수 계산

  // 현재 페이지의 아이템 범위 계산
  const startIndex = currentPage < itemsPerPage || total < itemsPerPage ? 1 : total - currentPage < 2 ? currentPage - 2 - (currentPage - total + 2) : currentPage - 2;
  const endIndex = total >= startIndex + itemsPerPage ? startIndex + itemsPerPage : total;

  // 현재 페이지의 데이터 추출


  // 페이지 번호 클릭시 처리 함수
  const handleClick = (page) => {
    setCurrentPage(page);
  };
  // 페이지 번호 버튼 생성
  const pageButtons = [];
  for (let i = startIndex; i <= endIndex; i++) {
    if (i !== 0) {
      pageButtons.push(
        <MDPagination item
                      active={i === currentPage}
                      onClick={() => handleClick(i)}>
          {i}
        </MDPagination>
      );
    }
  }


  return (
    <div>
      {pageButtons}
    </div>
  );
}

export default CustomPagination;
