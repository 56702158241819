import React, { useState, useRef, useEffect } from "react";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDProgressModal from "../../components/MDProgressModal";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography";
import {
  dateFormatYYYYMMDD,
  dateFormatYYYYMMDDHHMM,
  downloadFromS3,
  getDataRangeInputs,
  getHoliday,
  showDataRange
} from "../../Util";
import DownloadIcon from "../../assets/images/icons/downloads.png";
import MDButton from "../../components/MDButton";
import DataTable from "../../examples/Tables/DataTable";
import Footer from "../../examples/Footer";
import { TimePicker, DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import "./index.css";
import { Fab, TableCell, TableHead } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Select from "react-select";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { API_URL, WP_BACON_URL, WP_COLLINS_URL } from "../../constants/constants";
import dayjs from "dayjs";

function TodayDeliveryNotification(props) {
  const { type } = props;
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [selectedDay, setSelectedDay] = useState(new Set([]));
  const [holidaysInMonth, setHolidaysInMonth] = useState([]);
  const [targetProductList, setTargetProductList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [currentMonth, setCurrentMonth] = useState({
    year: new Date().getFullYear(),
    month: String(new Date().getMonth() + 1).padStart(2, "0")
  });
  const [selectedDates, setSelectedDates] = useState([]);


  // 날짜를 공휴일 이름이 포함된 셀로 표시하기 위한 tileContent 함수
  const tileContent = ({ date, view }) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}${month}${day}`; // YYYYMMDD 형식
    // 공휴일 데이터에서 해당 날짜 찾기
    const targetDay = holidaysInMonth.find(item => item.locdate["#text"] === formattedDate);

    if (targetDay) {
      return <div className="holiday-name react-calendar__month-view__days__day--weekend"
                  style={{ fontSize: "0.7em" }}>{targetDay.dateName["#text"]}</div>;
    }
  };
  const [isShowDataRange, setIsShowDataRange] = useState(false);
  const getExceptDays = async (products) => {
    await fetch(`${API_URL}/api/collins/shipping`).then((res) => res.json().then(r => {
      if (r.data.shippingTime?.startTime) {
        setSelectedStartTime(dayjs("2024-01-01T" + r.data.shippingTime.startTime));
      } else {
        setSelectedStartTime(dayjs("2024-01-01T" + "00:00"));
      }
      if (r.data.shippingTime?.endTime) {
        setSelectedEndTime(dayjs("2024-01-01T" + r.data.shippingTime.endTime));
      } else {
        setSelectedEndTime(dayjs("2024-01-01T" + "00:00"));
      }
      if (r.data.shippingWeek) {
        setSelectedDay(new Set(r.data.shippingWeek));
      }
      if (r.data.shippingProduct) {
        products.filter(item => r.data.shippingProduct.find(p => p.productId === item.value)).map(targetItem => addProduct(targetItem));
      }
      if (r.data.shippingDate) {
        setSelectedDates(r.data.shippingDate);
      }
    }));
  };

  const handleActiveStartDateChange = ({ activeStartDate }) => {
    const year = activeStartDate.getFullYear();
    const month = String(activeStartDate.getMonth() + 1).padStart(2, "0"); // 1자리 월에 0 추가
    setCurrentMonth({ year, month });
  };

  const dayOfTheWeek = [
    { value: "monday", name: "월", code: 1 },
    { value: "tuesday", name: "화", code: 2 },
    { value: "wednesday", name: "수", code: 3 },
    { value: "thursday", name: "목", code: 4 },
    { value: "friday", name: "금", code: 5 },
    { value: "saturday", name: "토", code: 6 },
    { value: "sunday", name: "일", code: 0 }
  ];

  const getProductList = async () => {
    return await fetch(`${API_URL}/api/${type}/products`, { credentials: "include" }).then((res) => res.json().then((r) => {
      const formatData = r.data.map(item => {
        return {
          value: item.id,
          label: item.title,
          sku: item.sku,
          attribute: item.attribute,
          attributes: item.attributes,
          type: item.type
        };
      });
      const data = formatData.sort((a, b) => {
        if (a.sku === null || a.sku === "") return 1;
        if (b.sku === null || b.sku === "") return -1;
        return b - a;
      });
      setProductList(data);
      return data;
    }));
  };


  const addProduct = (target) => {
    if (target) {
      targetProductList.push({
        id: target.value,
        title: target.label,
        sku: target.sku,
        attribute: target.attribute,
        attributes: target.attributes,
        type: target.type
      });
      setTargetProductList(() => [...targetProductList]);
    }
  };

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: data.sku
          ? "blue"
          : "red"
      };
    }
  };


  useEffect(() => {
    fetchHoliday(new Date().getFullYear(), String(new Date().getMonth() + 1).padStart(2, "0"));
    getProductList().then(products => {
      getExceptDays(products);
    });
    document.body.addEventListener("click", (e) => {
      setIsShowDataRange(false);
      e.stopPropagation();
    });
  }, []);

  const fetchHoliday = (year, month) => {
    getHoliday(year, month).then(r => {
      if (Array.isArray(r.items.item)) {
        setHolidaysInMonth([...r.items.item]);
      } else if (r.items.item) {
        setHolidaysInMonth([r.items.item]);
      } else {
        setHolidaysInMonth([]);
      }
    });
  };

  useEffect(() => {
    if (currentMonth) {
      fetchHoliday(currentMonth.year, currentMonth.month);
    }
  }, [currentMonth]);


  useEffect(() => {
    showDataRange(isShowDataRange);
  }, [isShowDataRange]);

  useEffect(() => {

  }, [selectedDay]);


  const toggleDate = (date) => {

    const dateString = dateFormatYYYYMMDD(date);
    const targetYear = selectedDates.find(item => item.year === String(currentMonth.year));
    if (targetYear) {
      if (targetYear.date.includes(dateString)) {
        // 이미 선택된 날짜라면 제거
        targetYear.date = targetYear.date.filter((d) => d !== dateString);
        setSelectedDates([...selectedDates]);
      } else {
        // 선택된 날짜 추가
        targetYear.date.push(dateString);
        setSelectedDates([...selectedDates]);
      }
    } else {
      const data = { year: String(currentMonth.year), date: [dateString] };
      selectedDates.push(data);
      setSelectedDates([...selectedDates]);
    }
  };


  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      if (selectedDay.has(dayOfTheWeek.find(item => item.code === new Date(date).getDay()).value)) {
        return "except-date";
      }
      const dateString = dateFormatYYYYMMDD(date);
      if (selectedDates.find(item => item.year === String(currentMonth.year))) {
        return selectedDates.find(yearData => yearData.date.includes(dateString)) ? "special-date" : null;
      }

    }
    return null;
  };

  const modifyDeliveryDay = async () => {
    const data = {
      "shippingTime": {
        "startTime": dateFormatYYYYMMDDHHMM(selectedStartTime).split("T")[1],
        "endTime": dateFormatYYYYMMDDHHMM(selectedEndTime).split("T")[1]
      },
      "shippingWeek": Array.from(selectedDay),
      "shippingDate": selectedDates,
      "shippingProduct": targetProductList.map(item => {
        return { productId: item.id };
      })
    };
    await fetch(`${API_URL}/api/collins/shipping`, {
      method: "POST",
      headers: { "content-type": "application/json; charset=utf-8" },
      body: JSON.stringify(data)
    }).then((res) => res.json().then(r => {
      if(r.code ==="200"){
        window.alert('저장 되었습니다.')
      }else{
        window.alert('저장을 실패했습니다.')
      }
    }));

  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2}
                     mt={-3}
                     py={3}
                     px={2}
                     variant="gradient"
                     bgColor="info"
                     borderRadius="lg"
                     display={"flex"}
                     coloredShadow="info"
                     style={{ justifyContent: "space-between" }}
              >
                <MDBox>
                  <MDTypography variant="h6" color="white">
                    {type.toUpperCase()}
                  </MDTypography>
                  <MDTypography variant="h6" color="white">
                    오늘 출발 안내 UI 관리
                  </MDTypography>
                </MDBox>
                <MDBox padding={"0 3px"}>
                  <MDButton
                    id={"today_delivery_button"}
                    variant={"gradient"}
                    onClick={() => modifyDeliveryDay()}
                    color={"success"}>저장하기</MDButton>
                </MDBox>
              </MDBox>
              <MDBox mx={3} mt={3} px={2}>
                <MDBox style={{ justifyContent: "space-between", width: "100%", display: "flex" }}>
                  <MDBox
                    style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <MDBox pr={1} mr={1} my={1} minWidth="200px" maxWidth={"600px"} style={{ width: "100%" }}>
                      <MDBox mb={6}>
                        <MDTypography variant="h5" mb={2}>
                          노출 시간 설정
                        </MDTypography>
                        <MDBox display={"flex"}>
                          <MDBox mr={1}>
                            <TimePicker
                              ampm={false}
                              label="시작 시간 선택"
                              value={selectedStartTime}
                              onChange={setSelectedStartTime}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </MDBox>
                          <MDBox>
                            <TimePicker
                              ampm={false}
                              label="종료 시간 선택"
                              value={selectedEndTime}
                              onChange={setSelectedEndTime}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </MDBox>
                        </MDBox>

                      </MDBox>
                      <MDBox mb={6}>
                        <MDTypography variant="h5" mb={2}>
                          노출 요일 설정
                        </MDTypography>
                        <MDBox display="flex" style={{ flexWrap: "wrap" }}>
                          {dayOfTheWeek.map(item =>
                            <MDBox mr={1}>
                              <Fab color={!selectedDay.has(item.value) ? "info" : "secondary"} aria-label="add"
                                   style={{ fontWeight: "bold" }}
                                   onClick={() => {
                                     if (selectedDay.has(item.value)) {
                                       selectedDay.delete(item.value);
                                       setSelectedDay(new Set([...selectedDay]));
                                     } else {
                                       selectedDay.add(item.value);
                                       setSelectedDay(new Set([...selectedDay]));
                                     }
                                   }}>
                                {item.name}
                              </Fab>
                            </MDBox>
                          )}
                        </MDBox>
                      </MDBox>
                      <MDBox mb={6}>
                        <MDTypography variant="h5" mb={2}>
                          제외 날짜 설정
                        </MDTypography>
                        <MDBox display="flex" mb={1}>
                          <Calendar
                            onActiveStartDateChange={handleActiveStartDateChange}

                            tileContent={tileContent}
                            onClickDay={toggleDate} // 날짜 클릭 핸들러
                            tileClassName={tileClassName} // 선택된 날짜에 스타일 추가
                          />
                        </MDBox>
                      </MDBox>
                      <MDBox>
                        <MDTypography variant="h5" mb={2}>
                          제외 상품 설정
                        </MDTypography>
                        <MDBox mb={12}>
                          <MDBox width={"100%"} mb={1}>
                            <Select
                              id="productModalSelect"
                              className="basic-single"
                              classNamePrefix="select"
                              isClearable={true}
                              isSearchable={true}
                              name="color"
                              styles={colourStyles}
                              options={productList}
                              value={null}
                              getOptionLabel={(option) => `[${option.value}] ${option.label}${option.type === "product_variation" ? option.attribute ? " - " + option.attribute.split(",").map(i => i.split(":")[1]).join("/") : "" : ""}${option.sku ? "  (SKU:" + option.sku + ")" : ""}`}
                              onChange={(e) => {
                                addProduct(e);
                              }}
                            />
                          </MDBox>
                          <MDBox>
                            <TableContainer>
                              <Table>
                                <TableHead style={{ display: "table-header-group" }}>
                                  <TableRow>
                                    <TableCell align="left">id</TableCell>
                                    <TableCell align="left">이름</TableCell>
                                    <TableCell align="center">SKU</TableCell>
                                    <TableCell align="center">삭제</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {targetProductList.map((item, key) => {
                                    return <TableRow key={key}>
                                      <TableCell align="left"
                                                 style={{ cursor: "pointer" }}
                                                 onClick={() => {
                                                   window.open(`${WP_COLLINS_URL}/post.php?post=${item.id}&action=edit`, "_blank");
                                                 }}
                                      >{item.id}</TableCell>
                                      <TableCell
                                        align="left">{item.title}</TableCell>
                                      <TableCell align="left">{item.sku}</TableCell>
                                      <TableCell align="center" style={{ cursor: "pointer" }} onClick={() => {
                                        targetProductList.splice(key, 1);
                                        setTargetProductList([...targetProductList]);
                                      }}>X</TableCell>
                                    </TableRow>;
                                  })
                                  }
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />;
    </DashboardLayout>
  )
    ;
}

export default TodayDeliveryNotification;
