import React from 'react';
import ReactDOM from 'react-dom/client'; // 'react-dom/client'를 사용해야 함
import { BrowserRouter } from "react-router-dom";
import App from "App";
import "./inputCustomStyle.css";
import "./index.css";
// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ko';
import dayjs from "dayjs";
dayjs.locale('ko');
const root = ReactDOM.createRoot(document.getElementById('root')); // createRoot 사용

root.render(
  <BrowserRouter>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MaterialUIControllerProvider>
        <App />
      </MaterialUIControllerProvider>
    </LocalizationProvider>
  </BrowserRouter>,
);
