import React, { useEffect, useState } from "react";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import { Divider } from "@mui/material";
import MDButton from "../MDButton";
import MDInput from "../MDInput";
import { API_URL } from "../../constants/constants";
import "./index.css";
import MDPostCodeModal from "../MDPostCodeModal/MDPostCodeModal";

function MDReleaseNotificationModifyModal(props) {
  const { wpId, resetTargetWpId, refresh } = props;
  const [fisrtName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [useYn, setUseYn] = useState(false);
  const [loading, setLoading] = useState(false);

  const getReleaseNotificationDetail = async () => {
    await fetch(`${API_URL}/api/collins/moodwater/customer/${wpId}`, { credentials: "include" }).then((res) => res.json().then((r) => {
      setFirstName(r.data.first_name);
      setLastName(r.data.last_name);
      setPhone(r.data.billing_phone);
      setUseYn(r.data.use_yn);
      setLoading(true);
    }));
  };

  const reset = () => {
    setLastName(null);
    setFirstName(null);
    setPhone(null);
    setUseYn(false);
    resetTargetWpId();
    setLoading(false);
  };

  const updateInfo = async () => {
    await fetch(`${API_URL}/api/collins/moodwater/customer/${wpId}`, {
      method: "PUT",
      headers: { "content-type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        use_yn: useYn,
        first_name: fisrtName,
        last_name: lastName,
        billing_phone: phone
      })
    }).then((res) => res.json().then((r) => {
      refresh();
      document.getElementById("releaseNotificationModifyModal").close();

    }));
  };

  useEffect(() => {
    if (wpId) {
      getReleaseNotificationDetail();
    }
  }, [wpId]);


  return (
    <dialog id={"releaseNotificationModifyModal"} style={{ width: "400px", minHeight: "400px" }}>
      {loading &&
        <MDBox py={3} px={5} minWidth="250px">
          <MDTypography component="div" variant="h6" color="text" fontWeight="medium">정보
            수정 {`#${wpId}`}</MDTypography>
          <MDBox mt={2}>
            <MDTypography component="div" variant="h6" color="text" fontWeight="medium">기본 정보</MDTypography>
            <MDBox mt={1}>
              <MDInput label={"성"} fullWidth value={lastName} onChange={e => setLastName(e.target.value)} />
            </MDBox>
            <MDBox mt={1}>
              <MDInput label={"이름"} fullWidth value={fisrtName} onChange={e => setFirstName(e.target.value)} />
            </MDBox>
            <MDBox mt={1}>
              <MDInput label={"전화번호"} fullWidth value={phone} onChange={e => setPhone(e.target.value)} />
            </MDBox>
          </MDBox>
          <MDBox mt={2}>
            <MDTypography component="div" variant="h6" color="text" fontWeight="medium">알림 여부</MDTypography>
            <MDBox mt={1} display={"flex"}>
              <MDBox style={{ textAlign: "center" }} mr={1}>
                <MDTypography component="div" fontSize={"14px"} color="text" fontWeight="medium">승인</MDTypography>
                <input type={"radio"} checked={useYn} onClick={(e) => setUseYn(true)} />
              </MDBox>
              <MDBox style={{ textAlign: "center" }}>
                <MDTypography component="div" fontSize={"14px"} color="text" fontWeight="medium">거부</MDTypography>
                <input type={"radio"} checked={!useYn} onClick={(e) => setUseYn(false)} />
              </MDBox>
            </MDBox>
          </MDBox>


          <MDBox display="flex" mt={4} justifyContent="space-around">
            <MDButton value="cancel" color="secondary"
                      variant={"gradient"}
                      onClick={() => {
                        reset();
                        document.getElementById("releaseNotificationModifyModal").close();
                      }}>취소</MDButton>
            <MDButton value="default" color="dark" variant={"gradient"} onClick={() => {
              updateInfo();
            }}>확인</MDButton>
          </MDBox>
        </MDBox>
      }
    </dialog>
  );
};

export default MDReleaseNotificationModifyModal;
