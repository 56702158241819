import React, { useEffect, useRef, useState } from "react";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import { Divider } from "@mui/material";
import MDButton from "../MDButton";
import "./index.css";
import Select from "react-select";
import MDInput from "../MDInput";


function MDProductModal(props) {

  const { action, productList } = props;
  const [targetProductList, setTargetProductList] = useState([]);
  const addProduct = (target) => {
    if (target) {
      targetProductList.push({
        id: target.value,
        title: target.label,
        sku: target.sku,
        attribute: target.attribute,
        attributes: target.attributes,
        type: target.type
      });
      setTargetProductList(() => [...targetProductList]);
    }
  };

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: data.sku
          ? "blue"
          : "red"
      };
    }
  };
  

  const deleteProduct = (i) => {
    targetProductList.splice(i, 1);
    setTargetProductList([...targetProductList]);
  };

  return (
    <dialog id="productModal">
      <MDBox py={3} px={5} minWidth="600px" maxWidth={"800px"} className={"modal-wrapper"}>
        <MDTypography component="div" variant="h6" color="text" fontWeight="medium">상품 추가</MDTypography>
        <MDBox display="flex" mt={4} justifyContent="space-between">
          <MDTypography component="div" variant="h6" color="text" fontWeight="medium">상품</MDTypography>
        </MDBox>
        <MDBox display="flex" mt={1} justifyContent="space-between">
          <MDBox width={"100%"} style={{ zIndex: "2000" }}>
            <Select
              id="productModalSelect"
              className="basic-single"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              name="color"
              styles={colourStyles}
              options={productList}
              value={null}
              getOptionLabel={(option) => `[${option.value}] ${option.label}${option.type === "product_variation" ? option.attribute ? " - " + option.attribute.split(",").map(i => i.split(":")[1]).join("/") : "" : ""}${option.sku ? "  (SKU:" + option.sku + ")" : ""}`}
              onChange={(e) => {
                addProduct(e);
              }}
            />
          </MDBox>
          {/*<MDBox width={"150px"} display={"flex"}>*/}

          {/*  <MDBox ml={1}><MDButton color={"info"} onClick={() => addProduct()}>+</MDButton></MDBox>*/}
          {/*</MDBox>*/}
        </MDBox>
        <MDBox mt={3}>
          {targetProductList.map((target, i) => <MDBox key={i} display="flex" mt={1} justifyContent="space-between"
                                                       style={{ alignItems: "center" }}>
            <MDBox width={"100%"} color={target.sku ? "blue" : "red"}>
              <MDTypography component="div" variant="h6" color={"inherit"}
                            fontWeight="medium">{target.title}</MDTypography>
              <MDTypography component="div" fontSize="13px" color={"inherit"}
                            fontWeight="medium">SKU : {target.sku ? target.sku : "없음"}</MDTypography>
              {target.type === "product_variation" && target.attribute &&
                <MDTypography component="div" fontSize="13px" color={"inherit"}
                              fontWeight="medium">{target.attribute}</MDTypography>}
            </MDBox>
            <MDBox display={"flex"} style={{ alignItems: "center" }}>
              <MDBox ml={1} display={"flex"}>
                <MDBox width={"70px"} mr={1}>
                  <MDInput
                    id={`productCount${i}`}
                    defaultValue={1}
                    label="수량"
                    type="number"
                  />
                </MDBox>
                <MDButton color={"info"}
                          onClick={() => deleteProduct(i)}>x</MDButton></MDBox>
            </MDBox>
          </MDBox>)}
        </MDBox>
        <MDBox display="flex" mt={4} justifyContent="space-between">
          <MDButton value="cancel" color="secondary"
                    onClick={() => {
                      setTargetProductList([]);
                      document.getElementById("productModal").close();
                    }}>취소</MDButton>
          <MDButton value="default" color="dark" onClick={() => {
            const data = targetProductList.map((item, i) => {
              const count = document.getElementById(`productCount${i}`).value;
              item.count = Number(count);
              return item;
            });
            action && action(data);
            setTargetProductList([]);
            document.getElementById("productModal").close();
          }}>추가하기</MDButton>
        </MDBox>
      </MDBox>
    </dialog>
  );
}

export default MDProductModal;
