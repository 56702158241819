import { useEffect, useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import "./GlobalSpinner.css";

const override = {
  display: "flex",
  margin: "0 auto",
  borderColor: "#1A73E8",
  justifyContent: "center"

};

function GlobalSpinner(props) {
  const { status } = props;
  useEffect(() => {
    if (status) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [status]);
  return (
    <div className="global-spinner-wrapper" style={{ display: status ? "block" : "none" }}>
      <BeatLoader
        color={"#1A73E8"}
        loading={status}
        cssOverride={override}
        size={30}
        aria-label="Loading Spinner"
        data-testid="loader"
        speedMultiplier={0.8}
      />
    </div>
  );
}

export default GlobalSpinner;
