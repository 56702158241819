import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import MDButton from "../MDButton";
import MDInput from "../MDInput";
import { useState } from "react";

function MDInputModal(props) {
  const { id, title, action } = props;
  const [inputValue, setInputValue] = useState("");

  return (
    <dialog id={id}>
      <MDBox py={3} px={5} minWidth="250px">
        <MDTypography component="div" variant="h6" color="text" fontWeight="medium">{title}</MDTypography>
        <MDBox mt={1}>
          <MDInput
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            fullWidth />
        </MDBox>
        <MDBox display="flex" mt={4} justifyContent="space-around">
          <MDButton value="cancel" color="secondary"
                    variant={"gradient"}
                    onClick={() => document.getElementById(id).close()}>취소</MDButton>
          <MDButton value="default"  variant={"gradient"} color="dark" onClick={() => {
            action && action(inputValue);
            document.getElementById(id).close()
          }}>확인</MDButton>
        </MDBox>
      </MDBox>
    </dialog>
  );
}

export default MDInputModal;
