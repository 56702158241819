import React from "react";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import { Divider } from "@mui/material";
import MDButton from "../MDButton";
import "./index.css";
import GoogleIcon from "../../assets/images/icons/Google-Icon.png";
import MDProgress from "../MDProgress";

function MDProgressModal(props) {
  const { id, successArr, failArr, totalCount } = props;
  return (
    <dialog id={id} className={"progress-modal"}>
      <MDBox py={3} px={5} minWidth="500px" className={"content"}>
        <MDTypography component="div" variant="h4" color="text" style={{ textAlign: "center" }}
                      fontWeight="medium">송장 확정 진척도</MDTypography>
        <MDTypography mt={3} component="div" fontSize={"16px"} color="text" style={{ textAlign: "left" }}
                      fontWeight="medium">총 송장 개수:{totalCount}</MDTypography>
        <MDTypography component="div" fontSize={"16px"} color="text" style={{ textAlign: "left" }}
                      fontWeight="medium">성공한 송장 개수:{successArr.length}</MDTypography>
        <MDTypography component="div" fontSize={"16px"} color="text" style={{ textAlign: "left" }}
                      fontWeight="medium">실패한 송장 개수:{failArr.length}</MDTypography>
        <MDTypography mt={4} component="div" fontSize={"18px"} color="text" style={{ textAlign: "left" }}
                      fontWeight="medium">진행률</MDTypography>
        <MDProgress value={((successArr.length + failArr.length) / totalCount * 100).toFixed(2)} variant="gradient"
                    label />
        <MDBox display="flex" mt={3} justifyContent="space-around">
          <MDButton value="default"
                    variant={"gradient"}
                    color={(successArr.length + failArr.length) / totalCount * 100 === 100 ? "success" : "secondary"}
                    onClick={() => {
                      if ((successArr.length + failArr.length) / totalCount * 100 === 100) {
                        if (failArr.length > 0) {
                          window.alert('실패한 송장이 있습니다. 실패 원인은 리스트에서 확인 하실 수 있습니다.\n 추가사항은 Online팀에 문의 부탁드립니다.')
                        }
                        document.getElementById("progressModal").close();
                      } else {
                        window.alert("송장 확정 진행 중 입니다.");
                      }
                    }}>확인</MDButton>
        </MDBox>
      </MDBox>
    </dialog>
  );
}

export default MDProgressModal;
