import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import DragIcon from "../../../assets/images/icons/drag-icon-original.svg";

import { useEffect, useRef, useState } from "react";
import { API_URL } from "../../../constants/constants";
import MDButton from "../../../components/MDButton";
import MDInput from "../../../components/MDInput";
import { setSpinnerStatus, useMaterialUIController } from "../../../context";

export default function data(data, pageType, callBack, setData) {
  const [, dispatch] = useMaterialUIController();
  const [updateMusicId, setUpdateMusicId] = useState("");
  const [targetThumbnailUrl, setTargetThumbnailUrl] = useState("");
  const draggingItemIndex = useRef(null);
  const [addEventFlag, setAddEventFlag] = useState(true);
  const draggingOverItemIndex = useRef(null);

  const onDragStart = (e, index, id) => {
    draggingItemIndex.current = index;
    e.target.classList.add("grabbing");
  };

  const onAvailableItemDragEnter = (e, index) => {
    draggingOverItemIndex.current = index;


  }; //6

  const onDragEnd = (e) => {
    e.target.classList.remove("grabbing");
    const copyListItems = [...data]; // 1
    const dragItemContent = copyListItems[draggingItemIndex.current]; //2
    copyListItems.splice(draggingItemIndex.current, 1); //3
    copyListItems.splice(draggingOverItemIndex.current, 0, dragItemContent); // 4
    draggingItemIndex.current = null;
    draggingOverItemIndex.current = null; //5
    setData([...copyListItems]);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (updateMusicId !== "") {
      setTargetThumbnailUrl(data[updateMusicId].thumbnail_url);
    }
  }, [updateMusicId]);

  const updateMusic = () => {
    const musicTitle = document.getElementById(`musicTitle${updateMusicId}`).value;
    const playTime = document.getElementById(`playTime${updateMusicId}`).innerText;
    const thumbnailUrl = targetThumbnailUrl;
    const videoId = document.getElementById(`videoId${updateMusicId}`).value;
    const playOrder = document.getElementById(`playOrder${updateMusicId}`).value;
    const id = data[updateMusicId].id;
    setSpinnerStatus(dispatch, true);
    fetch(`${API_URL}/api/collins/musics/${id}`, {
      method: "put", headers: { "content-type": "application/json" }, body: JSON.stringify({
        "id": id,
        "title": musicTitle,
        "play_time": playTime,
        "thumbnail_url": thumbnailUrl,
        "video_id": videoId,
        "play_order": playOrder
      }), credentials: "include"
    }).then(res => res.json().then(r => {
      setSpinnerStatus(dispatch, false);
      if (r) {
        setUpdateMusicId("");
        window.alert("수정 완료 되었습니다.");
        callBack();
      }
    }));
  };

  // useEffect(() => {
  //   const tableRowDocument = document.getElementsByClassName("MuiTableRow-root");
  //   console.log(tableRowDocument);
  //   if (tableRowDocument.length > 1) {
  //     for (let i = 1; tableRowDocument.length > i; i++) {
  //       tableRowDocument[i].draggable = true;
  //       tableRowDocument[i].style.cursor = "grab";
  //       tableRowDocument[i].removeEventListener("dragstart", (e) => onDragStart(e, i - 1));
  //       tableRowDocument[i].removeEventListener("dragend", onDragEnd);
  //       tableRowDocument[i].removeEventListener("dragover", onDragOver);
  //       tableRowDocument[i].removeEventListener("dragenter", (e) => onAvailableItemDragEnter(e, i - 1));
  //       tableRowDocument[i].addEventListener("dragstart", (e) => onDragStart(e, i - 1));
  //       tableRowDocument[i].addEventListener("dragend", onDragEnd);
  //       tableRowDocument[i].addEventListener("dragover", onDragOver);
  //       tableRowDocument[i].addEventListener("dragenter", (e) => onAvailableItemDragEnter(e, i - 1));
  //     }
  //   }
  // }, [data]);

  const deleteMusic = (musicId) => {
    setSpinnerStatus(dispatch, true);
    fetch(`${API_URL}/api/collins/musics/${musicId}`, {
      method: "delete",
      credentials: "include"
    }).then(res => res.json().then(r => {
      setSpinnerStatus(dispatch, false);
      if (r.message) {
        window.alert("삭제 되었습니다.");
        callBack();
      }
    }));
  };

  return {
    columns: [
      { Header: "ID", accessor: "musicId", align: "center" },
      { Header: "제목", accessor: "title", align: "center" },
      { Header: "재생 시간", accessor: "playTime", align: "center" },
      { Header: "썸네일 url", accessor: "thumbnailUrl", align: "center" },
      { Header: "음악파일 url", accessor: "videoId", align: "center" },
      { Header: "재생 순서", accessor: "playOrder", align: "center" },
      { Header: "수정일", accessor: "updateDateAt", align: "center" },
      { Header: "액션", accessor: "action", align: "center" }
    ],

    rows: data.map((item, i) => ({
      musicId: (
        <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
          {item?.id}
        </MDTypography>
      ),
      title: (
        <MDInput defaultValue={item?.title} key={`musicTitle${i}`} id={`musicTitle${i}`}
                 disabled={updateMusicId !== i} />
      ),
      playTime: (
        <MDTypography id={`playTime${i}`}>
          {item?.play_time}
        </MDTypography>
      ),
      thumbnailUrl: (
        <MDBox display="flex" alignItems="center" position="relative">
          <img src={updateMusicId !== i ? item?.thumbnail_url : targetThumbnailUrl} style={{ width: "100px" }}
               id={`thumbnailUrl${i}`} />
          <MDInput style={{ position: "absolute", zIndex: 10, opacity: 0 }} type="file"
                   id={`thumbnailUrl${i}`}
                   disabled={updateMusicId !== i}
                   onChange={e => {
                     const formData = new FormData();
                     formData.append("file", e.target.files[0]);
                     formData.append("fileType", "thumbnail");
                     fetch(`${API_URL}/api/collins/musics/upload`, {
                       method: "POST",
                       credentials: "include",
                       body: formData
                     }).then(res => res.json().then(r => {
                       setTargetThumbnailUrl(r.data);
                     }));
                   }} />
        </MDBox>
        // <MDInput defaultValue={item?.thumbnail_url} key={`thumbnailUrl${item.id}`} id={`thumbnailUrl${item.id}`}
        //          disabled={updateMusicId !== item.id} />
      ),
      videoId: (
        <MDBox display="flex" alignItems="center">
          <MDTypography component="div" variant="caption" color="text" fontSize="16px" fontWeight="medium">
            https://www.youtube.com/watch?v=
          </MDTypography>
          <MDInput defaultValue={item?.video_id} key={`videoId${i}`} id={`videoId${i}`}
                   disabled={updateMusicId !== i} />
        </MDBox>

      ),
      playOrder: (
        <MDInput defaultValue={item?.play_order} key={`playOrder${i}`} id={`playOrder${i}`}
                 disabled={updateMusicId !== i} />

      ),
      updateDateAt: (
        <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
          {new Date(item?.update_date).toLocaleString()}
        </MDTypography>
      ), action: (
        <MDBox display="flex">
          <MDBox mr={1}>
            <MDButton
              color="info"
              onClick={() => {
                updateMusicId === i ? updateMusic() : setUpdateMusicId(i);
              }}>{updateMusicId === i ? "완료" : "수정"}</MDButton>
          </MDBox>
          <MDButton color="warning"
                    onClick={() => deleteMusic(item.id)}
          >삭제</MDButton>
        </MDBox>
      )
    }))
  };
};
