export const CollinsFrontUrl = process.env.REACT_APP_COLLINS_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const WP_COLLINS_URL = process.env.REACT_APP_COLLINS_WP_URL
export const WP_BACON_URL = process.env.REACT_APP_BACON_WP_URL
export const WP_BACON_REST_API_URL = process.env.REACT_APP_BACON_WP_REST_API_URL
export const WP_COLLINS_REST_API_URL = process.env.REACT_APP_COLLINS_WP_REST_API_URL
export const WP_BACON_CONSUMER_KEY= process.env.REACT_APP_BACON_WP_CONSUMER_KEY
export const WP_BACON_CONSUMER_SECRET= process.env.REACT_APP_BACON_WP_CONSUMER_SECRET
export const WP_COLLINS_CONSUMER_KEY= process.env.REACT_APP_COLLINS_WP_CONSUMER_KEY
export const WP_COLLINS_CONSUMER_SECRET= process.env.REACT_APP_COLLINS_WP_CONSUMER_SECRET
