// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
import baconOrderItemListTableData from "layouts/tables/data/baconOrderItemListCTableData";
import { useEffect, useMemo, useRef } from "react";
import MDInput from "../../components/MDInput";
import {
  API_URL,
  WP_BACON_CONSUMER_KEY,
  WP_BACON_CONSUMER_SECRET, WP_BACON_REST_API_URL, WP_BACON_URL,
  WP_COLLINS_CONSUMER_KEY, WP_COLLINS_CONSUMER_SECRET, WP_COLLINS_REST_API_URL, WP_COLLINS_URL
} from "../../constants/constants";
import MDButton from "../../components/MDButton";
import { useState } from "react";
import {
  autoHypenPhone, CollinsOrderHotKeyBaseInfo, dateFormat, deliveryData,
  invoiceDropdownItems, OrderHotKeyBaseInfo
} from "../../Util";
import MDProductModal from "../../components/MDProductModal";
import Select from "react-select";
import MDInputModal from "../../components/MDInputModal";
import { setSpinnerStatus, useMaterialUIController } from "../../context";
import "./orderDetail.css";
import MDRefundModal from "../../components/MDRefundModal";
import MDSorryBoxModal from "../../components/MDSorryBoxModal";
import MDPostCodeModal from "../../components/MDPostCodeModal/MDPostCodeModal";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";


function OrderDetail(props) {
  const { type } = props;
  const debounceRef = useRef(0);
  const [controller, dispatch] = useMaterialUIController();
  const [editable, setEditable] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const pathName = window.location.pathname;
  const searchParams = new URLSearchParams(window.location.search);
  const duplicateOrderId = searchParams.get("orderId");
  const serviceCustomerId = searchParams.get("customerId");
  const baseItemId = searchParams.get("baseItemId");
  const duplicateOrderStatus = searchParams.get("status");

  const orderArr = pathName.split("/");
  const pathInfo = orderArr[orderArr.length - 1];
  const [totalDeliveryPay, setTotalDeliveryPay] = useState(0);
  const [totalFeePay, setTotalFeePay] = useState(0);
  const [orderItemListArr, setOrderItemListArr] = useState([]);
  const [userList, setUserList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [subTotalPrice, setSubTotalPrice] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [refundable, setRefundable] = useState(false);
  const [hotKeyTargetIndex, setHotKeyTargetIndex] = useState(null);
  const orderNoteStatus = [{ label: "개인용 메모", value: "company" }, { label: "고객에게 노트하기", value: "customer" }];
  const [targetOrderNoteStatus, setTargetOrderNoteStatus] = useState(orderNoteStatus[0]);
  const [targetInvoice, setTargetInvoice] = useState(invoiceDropdownItems[0]);
  const [searchCustomerValue, setSearchCustomerValue] = useState("");
  const [orderHotKeyInfo, setOrderHotKeyInfo] = useState(type === "bacon" ? OrderHotKeyBaseInfo : CollinsOrderHotKeyBaseInfo);
  const [postCodeDocument, setPostCodeDocument] = useState(null);
  const [createDate, setCreateDate] = useState(new Date());
  const [postCodeDocumentType, setPostCodeDocumentType] = useState(null);
  const {
    columns,
    rows
  } = baconOrderItemListTableData(orderItemListArr, setOrderItemListArr, editable, () => modifyOrder(), type);
  const [targetProductList, setTargetProductList] = useState([]);
  const [initMetaData, setInitMetaData] = useState([]);
  const [changeDateFlag, setChangeDateFlag] = useState(false);
  const statusDropdownItems = [
    { label: "대기", value: "pending" },
    { label: "결제 완료", value: "order-received" },
    { label: "출고중", value: "processing" },
    { label: "배송중", value: "shipping" },
    { label: "배송 완료", value: "shipped" },
    { label: "구매 완료", value: "completed" },
    { label: "환불 완료", value: "refunded" },
    { label: "예약판매 발송 대기", value: "preorder-received" },
    { label: "가상계좌 입금대기 중", value: "wc-awaiting-vbank" },
    { label: "가상계좌 입금대기 중", value: "awaiting-vbank" },
    { label: "템플릿", value: "template" }
  ];
  const paymentDropdownItems = [
    {
      label: "결제방식을 선택해주세요",
      value: "null"
    },
    {
      label: "카카오페이",
      value: "iamport_kakao"
    },
    {
      label: "네이버페이",
      value: "iamport_naverpay"
    }, {
      label: "가상계좌 결제",
      value: "iamport_vbank"
    }, {
      label: "PAYCO 결제",
      value: "iamport_payco"
    }, {
      label: "신용카드 결제",
      value: "iamport_card"
    }];
  const [orderStatus, setOrderStatus] = useState(statusDropdownItems[0]);
  const [paymentStatus, setPaymentStatus] = useState(paymentDropdownItems[0]);
  const [orderNote, setOrderNote] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [orderDataLoading, setOrderDataLoading] = useState(false);

  const duplicateOrder = async () => {
    let targetUser = null;
    await fetch(`${type === "bacon" ? WP_BACON_REST_API_URL : WP_COLLINS_REST_API_URL}/orders/${duplicateOrderId}?consumer_key=${type === "bacon" ? WP_BACON_CONSUMER_KEY : WP_COLLINS_CONSUMER_KEY}&consumer_secret=${type === "bacon" ? WP_BACON_CONSUMER_SECRET : WP_COLLINS_CONSUMER_SECRET}`, { credentials: "include" }).then((res) => res.json().then(async (r) => {
      setInitMetaData(r.meta_data);
      if (baseItemId && baseItemId !== "undefined" && baseItemId !== "null") {
        const arr = [];
        const baseItemArr = baseItemId.split(",");
        baseItemArr.map(item => arr.push({ id: Number(item), count: 1, price: 0 }));
        setTargetProductList(() => [...arr]);
      } else if (baseItemId !== "null") {
        const tempArr = [];
        const arr = [];
        if (r.line_items && r.line_items.length > 0) {
          r.line_items.map(item => arr.push({
            id: Number(item.variation_id ? item.variation_id : item.product_id),
            count: item.quantity,
            price: item.price
          }));
        }
        if (r.shipping_lines && r.shipping_lines.length > 0) {
          r.shipping_lines.map(item => {
            delete item.id;
            item.method_id = "flat_rate";
            tempArr.push(Object.assign(item, { type: "shipping_lines" }));
          });
        }
        if (r.fee_lines && r.fee_lines?.length > 0) {
          r.fee_lines.map(item => {
            delete item.id;
            tempArr.push(Object.assign(item, { type: "fee_lines" }));
          });
        }
        if (r.coupon_lines && r.coupon_lines.length > 0) {
          r.coupon_lines.map(item => {
            delete item.id;
            tempArr.push(Object.assign(item, { type: "coupon_lines_base" }));
          });
        }
        setOrderItemListArr([...tempArr]);
        setTargetProductList(() => [...arr]);
      }
      if (serviceCustomerId && serviceCustomerId !== "undefined" || r?.customer_id !== 0) {
        await fetch(`${API_URL}/api/${type}/customers/${serviceCustomerId && serviceCustomerId !== "undefined" ? serviceCustomerId : r.customer_id}`, { credentials: "include" }).then((res) => res.json().then((rs) => {
          const formatData = {
            value: rs.data.customer_id,
            label: `${rs.data.first_name} (#${rs.data.customer_id} - ${rs.data.email})`
          };
          if (rs) {
            targetUser = rs.data;
          }
          setUserList([formatData]);
        }));
      }

      if (duplicateOrderStatus && duplicateOrderStatus !== "undefined") {
        setOrderStatus(statusDropdownItems.filter(item => item.value === duplicateOrderStatus)[0]);
      } else {
        setOrderStatus(statusDropdownItems.filter(item => item.value === r.status)[0]);
      }
      if ((r?.customer_id !== 0 && r?.customer_id !== null) || (targetUser?.customer_id !== null && targetUser?.customer_id !== undefined)) {
        setOrderDataLoading(true);
        setCustomer({
          value: targetUser ? targetUser.customer_id : r.customer_id,
          label: targetUser ? `${targetUser.first_name} (#${targetUser.customer_id} - ${targetUser.email})` : `${r.billing.first_name} (#${r.customer_id} - ${r.billing.email})`,
          billing_first_name: r.billing.first_name ? r.billing.first_name : "",
          billing_last_name: r.billing.last_name ? r.billing.last_name : "",
          billing_address1: r.billing.address_1 ? r.billing.address_1 : "",
          billing_address2: r.billing.address_2 ? r.billing.address_2 : "",
          billing_postcode: r.billing.postcode ? r.billing.postcode : "",
          billing_email: targetUser ? targetUser.email : (r.billing.email ? r.billing.email : ""),
          billing_phone: r.billing.phone ? r.billing.phone : "",
          shipping_first_name: r.shipping.first_name ? r.shipping.first_name : "",
          shipping_last_name: r.shipping.last_name ? r.shipping.last_name : "",
          shipping_address1: r.shipping.address_1 ? r.shipping.address_1 : "",
          shipping_address2: r.shipping.address_2 ? r.shipping.address_2 : "",
          shipping_postcode: r.shipping.postcode ? r.shipping.postcode : ""
        });
      }else{
        setCustomer({
          value: targetUser ? targetUser.customer_id : r.customer_id,
          billing_first_name: r.billing.first_name ? r.billing.first_name : "",
          billing_last_name: r.billing.last_name ? r.billing.last_name : "",
          billing_address1: r.billing.address_1 ? r.billing.address_1 : "",
          billing_address2: r.billing.address_2 ? r.billing.address_2 : "",
          billing_postcode: r.billing.postcode ? r.billing.postcode : "",
          billing_email: targetUser ? targetUser.email : (r.billing.email ? r.billing.email : ""),
          billing_phone: r.billing.phone ? r.billing.phone : "",
          shipping_first_name: r.shipping.first_name ? r.shipping.first_name : "",
          shipping_last_name: r.shipping.last_name ? r.shipping.last_name : "",
          shipping_address1: r.shipping.address_1 ? r.shipping.address_1 : "",
          shipping_address2: r.shipping.address_2 ? r.shipping.address_2 : "",
          shipping_postcode: r.shipping.postcode ? r.shipping.postcode : ""
        })
        setOrderDataLoading(true);
      }
    }));
  };

  const getBaconOrderDetail = async () => {
      setSpinnerStatus(dispatch, true);
      let productListTemp = [];
      const tempArr = [];
      await fetch(`${API_URL}/api/${type}/products`, { credentials: "include" }).then((res) => res.json().then((r) => {
        const formatData = r.data.map(item => {
          return {
            value: item.id,
            label: item.title,
            sku: item.sku,
            attribute: item.attribute,
            attributes: item.attributes,
            type: item.type
          };
        });
        const data = formatData.sort((a, b) => {
          if (a.sku === null || a.sku === "") return 1;
          if (b.sku === null || b.sku === "") return -1;
          return b - a;
        });
        setProductList(data);
        productListTemp = data;
      }));
      if (pathInfo.toLowerCase() !== "new" && pathInfo.toLowerCase() !== "list") {
        await fetch(`${type === "bacon" ? WP_BACON_REST_API_URL : WP_COLLINS_REST_API_URL}/orders/${pathInfo}?consumer_key=${type === "bacon" ? WP_BACON_CONSUMER_KEY : WP_COLLINS_CONSUMER_KEY}&consumer_secret=${type === "bacon" ? WP_BACON_CONSUMER_SECRET : WP_COLLINS_CONSUMER_SECRET}`, { credentials: "include" }).then((res) => res.json().then(async (r) => {
          if (!r.id) {
            window.alert("정보를 불러올수 없습니다. 주문 리스트 화면으로 이동하겠습니다.");
            return window.location.href = `/${type}/order/list`;
          }
          setOrderData(r);
          const status = statusDropdownItems.filter(item => item.value === r.status)[0];
          setOrderStatus(status);
          const payMethod = paymentDropdownItems.filter(item => item.value === r.payment_method);
          if (payMethod.length > 0) {
            setPaymentStatus(payMethod[0]);
          } else {
            setPaymentStatus(paymentDropdownItems[0]);
          }
          setCreateDate(new Date(r.date_created));
          let targetUser = null;
          await fetch(`${API_URL}/api/${type}/customers/${r.customer_id}`, { credentials: "include" }).then((res) => res.json().then((rs) => {
            if (rs.data) {
              const formatData = {
                value: rs.data.customer_id,
                label: `${rs.data.first_name} (#${rs.data.customer_id} - ${rs.data.email})`
              };
              targetUser = rs.data;
              setUserList([formatData]);
            }
          }));
          if (r.customer_id) {
            setCustomer({
              value: r.customer_id,
              label: targetUser ? `${targetUser.first_name} (#${targetUser.customer_id} - ${targetUser.email})` : `${r.billing.first_name} (#${r.customer_id} - ${r.billing.email})`,
              billing_first_name: r.billing.first_name ? r.billing.first_name : "",
              billing_last_name: r.billing.last_name ? r.billing.last_name : "",
              billing_address1: r.billing.address_1 ? r.billing.address_1 : "",
              billing_address2: r.billing.address_2 ? r.billing.address_2 : "",
              billing_postcode: r.billing.postcode ? r.billing.postcode : "",
              billing_email: r.billing.email ? r.billing.email : "",
              billing_phone: r.billing.phone ? r.billing.phone : "",
              shipping_first_name: r.shipping.first_name ? r.shipping.first_name : "",
              shipping_last_name: r.shipping.last_name ? r.shipping.last_name : "",
              shipping_address1: r.shipping.address_1 ? r.shipping.address_1 : "",
              shipping_address2: r.shipping.address_2 ? r.shipping.address_2 : "",
              shipping_postcode: r.shipping.postcode ? r.shipping.postcode : ""
            });
          }
          if (r.line_items && r.line_items.length > 0) {
            r.line_items.map(item => {
              item.attribute = productListTemp.filter(productItem => {
                  if (item.variation_id) {
                    return productItem.value === item.variation_id;
                  } else {
                    return productItem.value === item.product_id;
                  }
                }
              )[0]?.attribute;
              tempArr.push(Object.assign(item, { type: "line_items" }));
            });
          }
          if (r.shipping_lines && r.shipping_lines.length > 0) {
            r.shipping_lines.map(item => tempArr.push(Object.assign(item, { type: "shipping_lines" })));
          }
          if (r.fee_lines && r.fee_lines?.length > 0) {
            r.fee_lines.map(item => tempArr.push(Object.assign(item, { type: "fee_lines" })));
          }
          if (r.coupon_lines && r.coupon_lines.length > 0) {

            r.coupon_lines.map(item => {
              delete item.id;
              tempArr.push(Object.assign(item, { type: "coupon_lines_base" }));
            });
          }

          setOrderItemListArr([...tempArr]);
          if (r.status === "pending" || r.status.includes("awaiting") || r.status.includes("template")) {
            setEditable(true);
          }
          if (r.status === "order-received") {
            setRefundable(true);
          }
          setOrderDataLoading(true);
        }));
        await callOrderNote();
        await callInvoiceData();

      } else {
        if (duplicateOrderId && pathInfo.toLowerCase() === "new") {
          await duplicateOrder();
        } else if (duplicateOrderStatus && duplicateOrderStatus !== "undefined") {
          setOrderStatus(statusDropdownItems.filter(item => item.value === duplicateOrderStatus)[0]);
          setOrderDataLoading(true);
        } else {
          setOrderDataLoading(true);
        }
        setEditable(true);
      }
      setSpinnerStatus(dispatch, false);
    }
  ;

  const getBaconProductDetail = async () => {
    const item = await Promise.all(targetProductList.map(async item =>
      await fetch(`${type === "bacon" ? WP_BACON_REST_API_URL : WP_COLLINS_REST_API_URL}/products/${item.id}?consumer_key=${type === "bacon" ? WP_BACON_CONSUMER_KEY : WP_COLLINS_CONSUMER_KEY}&consumer_secret=${type === "bacon" ? WP_BACON_CONSUMER_SECRET : WP_COLLINS_CONSUMER_SECRET}`, {})
        .then(res => res.json().then(r => {
          r.quantity = item.count;
          r.product_id = item.id;
          r.subtotal = Number(r.price) * Number(item.count);
          if (item.price === 0) {
            r.total = 0;
          } else {
            r.total = Number(r.price) * Number(item.count);
          }
          r.type = "line_items";
          delete r.meta_data;
          // r.meta_data.map(metaItem => {
          //   if (typeof metaItem.value !== "string" && typeof metaItem.value !== "number") {
          //     metaItem.value = ""
          //   }
          //   return metaItem;
          // });
          return r;
        }))));
    if (item.length > 0) {
      setOrderItemListArr([...orderItemListArr, ...item]);
    }

  };

  const callOrderNote = async () => {
    await fetch(`${type === "bacon" ? WP_BACON_REST_API_URL : WP_COLLINS_REST_API_URL}/orders/${pathInfo}/notes
?consumer_key=${type === "bacon" ? WP_BACON_CONSUMER_KEY : WP_COLLINS_CONSUMER_KEY}&consumer_secret=${type === "bacon" ? WP_BACON_CONSUMER_SECRET : WP_COLLINS_CONSUMER_SECRET}`, { credentials: "include" }).then((res) => res.json().then((r) => {
      setOrderNote([...r]);
    }));
  };

  const callInvoiceData = async () => {
    await fetch(`${API_URL}/api/${type}/orders/invoices/${pathInfo}`, { credentials: "include" }).then((res) => res.json().then((r) => {
      if (r.data) {
        const targetData = invoiceDropdownItems.filter(item => item.value === r.data?.divCode);
        if (targetData.length > 0) {
          setTargetInvoice(targetData[0]);
        } else {
          setTargetInvoice(invoiceDropdownItems[0]);
        }
        if (r.data?.sheetNo) {
          document.getElementById("delivery-number-input").value = r.data?.sheetNo;
        } else {
          if (document.getElementById("delivery-number-input")) {
            document.getElementById("delivery-number-input").value = "";
          }
        }
      }
    }));
  };


  const modifyOrder = async () => {
    const billingFirstName = document.getElementById(`billing_first_name`).value;
    const billingLastName = document.getElementById(`billing_last_name`).value;
    const billingAddress1 = document.getElementById(`billing_address1`).value;
    const billingAddress2 = document.getElementById(`billing_address2`).value;
    const billingPostcode = document.getElementById(`billing_postcode`).value;
    const billingEmail = document.getElementById(`billing_email`).value;
    const billingPhone = document.getElementById(`billing_phone`).value;
    const shippingFirstName = document.getElementById(`shipping_first_name`).value;
    const shippingLastName = document.getElementById(`shipping_last_name`).value;
    const shippingAddress1 = document.getElementById(`shipping_address1`).value;
    const shippingAddress2 = document.getElementById(`shipping_address2`).value;
    const shippingPostcode = document.getElementById(`shipping_postcode`).value;
    const shippingPhone = document.getElementById(`_shipping_phone1`).value;
    const customerNote = document.getElementById(`shipping_note`).value;
    if (shippingPhone === "" || shippingAddress1 === "" || shippingAddress2 === "" || shippingPostcode === "" || shippingFirstName === "" || shippingLastName === "") {
      if (!window.confirm(`배송지 정보에 누락된 항목(${shippingPhone === "" ? "배송지 전화번호" : shippingLastName === "" ? "성" : shippingFirstName === "" ? "이름" : shippingAddress1 === "" ? "주소1" : shippingAddress2 === "" ? "주소2" : shippingPostcode === "" ? "우편번호" : ""})이 존재합니다. 이대로 주문을 생성하시겠습니까?`)) {
        return;
      }
    }
    const items = orderItemListArr.map(item => {
      if (item.subtotal || item.subtotal >= 0) {
        item.subtotal = String(item.subtotal);
      }
      if (item.id === item.product_id) {
        delete item.id;
      }
      if (item.total || item.total >= 0) {
        item.total = String(item.total);
      }
      if (item.meta_data) {
        item.meta_data.map(metaData =>
          metaData.display_value = String(metaData.display_value));
      }
      if (!item.parent_name) {
        delete item.parent_name;
      }
      if ((typeof item.price) !== "Number") {
        item.price = Number(item.price);
      }
      return item;
    });
    setSpinnerStatus(dispatch, true);
    const line_items = items.filter(item => item.type === "line_items");
    const shipping_lines = items.filter(item => item.type === "shipping_lines");
    const fee_lines = items.filter(item => item.type === "fee_lines");
    const coupon_line = items.filter(item => item.type === "coupon_lines_base");

    await fetch(`${type === "bacon" ? WP_BACON_REST_API_URL : WP_COLLINS_REST_API_URL}/orders/${pathInfo}?consumer_key=${type === "bacon" ? WP_BACON_CONSUMER_KEY : WP_COLLINS_CONSUMER_KEY}&consumer_secret=${type === "bacon" ? WP_BACON_CONSUMER_SECRET : WP_COLLINS_CONSUMER_SECRET}&force=true`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "payment_method": paymentStatus ? paymentStatus.value : "",
        "payment_method_title": paymentStatus ? paymentStatus.label : "",
        "customer_id": customer ? customer?.value : 0,
        "set_paid": false,
        "billing": {
          "first_name": billingFirstName,
          "last_name": billingLastName,
          "address_1": billingAddress1,
          "address_2": billingAddress2,
          "postcode": billingPostcode,
          "email": billingEmail,
          "phone": billingPhone
        },
        "shipping": {
          "first_name": shippingFirstName,
          "last_name": shippingLastName,
          "address_1": shippingAddress1,
          "address_2": shippingAddress2,
          "postcode": shippingPostcode
        },
        "customer_note": customerNote,
        "meta_data": [
          {
            key: "_shipping_phone",
            value: shippingPhone
          }, {
            key: "_shipping_phone1",
            value: shippingPhone
          }, {
            key: "_shipping_phone2",
            value: shippingPhone
          }],
        "line_items": line_items,
        "shipping_lines": shipping_lines,
        "coupon_lines": coupon_line,
        "fee_lines": fee_lines,
        "status": orderStatus.value
      })
    }).then(res => res.json().then(async r => {
      if (r.message) {
        window.alert(r.message);
      } else {
        if (orderStatus.value === "template") {
          await changeTemplateTitle(r.id);
        }
        if (changeDateFlag) {
          await changeDate(r.id).then(() => getBaconOrderDetail());
        } else {
          getBaconOrderDetail();
        }
      }
    }));
  };

  const submitOrder = async () => {
    const billingFirstName = document.getElementById(`billing_first_name`).value;
    const billingLastName = document.getElementById(`billing_last_name`).value;
    const billingAddress1 = document.getElementById(`billing_address1`).value;
    const billingAddress2 = document.getElementById(`billing_address2`).value;
    const billingPostcode = document.getElementById(`billing_postcode`).value;
    const billingEmail = document.getElementById(`billing_email`).value;
    const billingPhone = document.getElementById(`billing_phone`).value;
    const shippingFirstName = document.getElementById(`shipping_first_name`).value;
    const shippingLastName = document.getElementById(`shipping_last_name`).value;
    const shippingAddress1 = document.getElementById(`shipping_address1`).value;
    const shippingAddress2 = document.getElementById(`shipping_address2`).value;
    const shippingPostcode = document.getElementById(`shipping_postcode`).value;
    const shippingPhone = document.getElementById(`_shipping_phone1`)?.value;
    const customerNote = document.getElementById(`shipping_note`).value;
    const items = [];
    if (shippingPhone === "" || shippingAddress1 === "" || shippingAddress2 === "" || shippingPostcode === "" || shippingFirstName === "" || shippingLastName === "") {
      if (!window.confirm(`배송지 정보에 누락된 항목(${shippingPhone === "" ? "배송지 전화번호" : shippingLastName === "" ? "성" : shippingFirstName === "" ? "이름" : shippingAddress1 === "" ? "주소1" : shippingAddress2 === "" ? "주소2" : shippingPostcode === "" ? "우편번호" : ""})이 존재합니다. 이대로 주문을 생성하시겠습니까?`)) {
        return;
      }
    }
    orderItemListArr.map(item => {
      if (item.subtotal || item.subtotal >= 0) {
        item.subtotal = String(item.subtotal);
        item.total = String(item.total);
        if (item.id) {
          delete item.id;
        }
      }
      if ((typeof item.price) !== "Number") {
        item.price = Number(item.price);
      }
      items.push(item);
    });

    const line_items = items.filter(item => item.type === "line_items");
    const shipping_lines = items.filter(item => item.type === "shipping_lines");
    const fee_lines = items.filter(item => item.type === "fee_lines");
    setSpinnerStatus(dispatch, true);

    await fetch(`${type === "bacon" ? WP_BACON_REST_API_URL : WP_COLLINS_REST_API_URL}/orders?force=true&consumer_key=${type === "bacon" ? WP_BACON_CONSUMER_KEY : WP_COLLINS_CONSUMER_KEY}&consumer_secret=${type === "bacon" ? WP_BACON_CONSUMER_SECRET : WP_COLLINS_CONSUMER_SECRET}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "payment_method": paymentStatus ? paymentStatus.value : "",
        "payment_method_title": paymentStatus ? paymentStatus.label : "",
        "customer_id": customer ? customer?.value : 0,
        "set_paid": false,
        "billing": {
          "first_name": billingFirstName,
          "last_name": billingLastName,
          "address_1": billingAddress1,
          "address_2": billingAddress2,
          "postcode": billingPostcode,
          "email": billingEmail,
          "phone": billingPhone
        },
        "shipping": {
          "first_name": shippingFirstName,
          "last_name": shippingLastName,
          "address_1": shippingAddress1,
          "address_2": shippingAddress2,
          "postcode": shippingPostcode
        },
        "customer_note": customerNote,
        "meta_data": [
          {
            key: "_shipping_phone",
            value: shippingPhone
          }, {
            key: "_shipping_phone1",
            value: shippingPhone
          }, {
            key: "_shipping_phone2",
            value: shippingPhone
          }],
        "line_items": line_items,
        "shipping_lines": shipping_lines,
        "fee_lines": fee_lines,
        "status": orderStatus.value
      })
    }).then(res => {
        res.json().then(async rs => {
          if (rs.id) {
            if (orderStatus.value === "template") {
              await changeTemplateTitle(rs.id);
            }
            if (changeDateFlag) {

              await changeDate(rs.id).then(async (r) => {
                await setSpinnerStatus(dispatch, false);
                window.location.href = `/${type}/order/${rs.id}`;
              });
            } else {
              window.location.href = `/${type}/order/${rs.id}`;
            }
          } else {
            window.alert(rs.message);
          }
        });
      }
    );
    setSpinnerStatus(dispatch, false);

  };
  const changeDate = async (id) => {
    await fetch(`${API_URL}/api/${type}/orders/date`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        "order_id": id,
        "date": dateFormat(createDate)
      })
    });
  };

  const changeTemplateTitle = async (id) => {
    const templateTitleElement = document.getElementById("template_title");
    if (templateTitleElement && templateTitleElement.value !== "") {
      await fetch(`${API_URL}/api/${type}/template/title`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include",
        body: JSON.stringify({
          "order_id": id,
          "template_title": templateTitleElement.value
        })
      });
    }

  };

  const debounceSearch = (value, delay) => {
    clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(() => {
      setSearchCustomerValue(value);
    }, delay);
  };

  const searchCustomer = async () => {
    setSpinnerStatus(dispatch, true);

    await fetch(`${API_URL}/api/${type}/customers?search=${searchCustomerValue}`, { credentials: "include" }).then((res) => res.json().then((r) => {
      const formatData = r.data.map(item => {
        item.value = item.customer_id;
        item.label = `${item.first_name} (#${item.customer_id} - ${item.email})`;
        return item;
      });
      setUserList(formatData);
    }));
    setSpinnerStatus(dispatch, false);
  };

  const changeAllItemsPayZero = () => {
    orderItemListArr.map(item => {
      if (item.type === "line_items") {
        item.total = "0";
      }
    });
    setOrderItemListArr([...orderItemListArr]);
  };

  useEffect(() => {
    if (searchCustomerValue !== "") {
      searchCustomer();
    }
  }, [searchCustomerValue]);

  useEffect(() => {
    if (postCodeDocument) {
      if (postCodeDocumentType === "billing") {
        document.getElementById(`billing_address1`).value = postCodeDocument?.address_name;
        document.getElementById(`billing_postcode`).value = postCodeDocument?.road_address?.zone_no;
        document.getElementById(`billing_address2`).value = "";

        setPostCodeDocument(null);
      } else if (postCodeDocumentType === "shipping") {
        document.getElementById(`shipping_address1`).value = postCodeDocument?.address_name;
        document.getElementById(`shipping_address2`).value = "";
        document.getElementById(`shipping_postcode`).value = postCodeDocument?.road_address?.zone_no;
        setPostCodeDocument(null);
      }
    }
  }, [postCodeDocument]);

  const addShippingLine = () => {
    const shippingData = {
      method_id: "flat_rate", method_title: "배송", total: "0", type: "shipping_lines"
    };
    orderItemListArr.push(shippingData);
    setOrderItemListArr([...orderItemListArr]);
  };

  const addAdditionalFee = (value) => {
    let calValue = 0;
    let totalValue = 0;
    if (value.includes("%")) {
      const cutPercent = value.split("%")[0];
      orderItemListArr.map(item => {
        if (item.type !== "coupon_lines_base") {
          totalValue += Number(item.total);
        }
      });
      calValue = Math.round(Number(totalValue) * (Number(cutPercent) / 100));
    } else {
      calValue = value;
    }
    const additionalFeeData = {
      name: `${value} 요금`, total: String(calValue), type: "fee_lines"
    };
    orderItemListArr.push(additionalFeeData);
    setOrderItemListArr([...orderItemListArr]);
  };

  const useCoupon = async (value) => {
    await fetch(`${type === "bacon" ? WP_BACON_REST_API_URL : WP_COLLINS_REST_API_URL}/coupons?code=${value}&consumer_key=${type === "bacon" ? WP_BACON_CONSUMER_KEY : WP_COLLINS_CONSUMER_KEY}&consumer_secret=${type === "bacon" ? WP_BACON_CONSUMER_SECRET : WP_COLLINS_CONSUMER_SECRET}`)
      .then(res => res.json().then(r => {
        const checkDuplicateCoupon = orderItemListArr.some(coupon => coupon.type === "coupon_lines_base" && coupon.code === r[0].code);
        if (checkDuplicateCoupon) {
          return window.alert("쿠폰 코드가 이미 적용됐습니다!");
        } else {
          orderItemListArr.push({
            code: r[0].code,
            discount: String(Number(r[0].amount)),
            type: "coupon_lines_base"
          });
          setOrderItemListArr([...orderItemListArr]);
        }
      }));
    await modifyOrder();
  };

  const deleteInvoice = async () => {
    setSpinnerStatus(dispatch, true);
    await fetch(`${API_URL}/api/${type}/orders/invoices/${pathInfo}`, {
      credentials: "include",
      method: "delete"
    }).then((res) => res.json().then((r) => {
      if (r.code === "200") {
        window.alert("송장 삭제가 완료되었습니다.");
        getBaconOrderDetail();
      } else {
        window.alert(r.message);
        setSpinnerStatus(dispatch, false);

      }
    }));
  };

  const updateInvoice = async () => {
    if (orderData.status !== "processing") {
      return window.alert("출고중 상태에서만 송장 업데이트가 가능합니다.");
    }
    setSpinnerStatus(dispatch, true);
    const data = {
      "order_id": Number(pathInfo),
      "div_code": targetInvoice.value,
      "sheet_no": document.getElementById("delivery-number-input").value
    };
    await fetch(`${API_URL}/api/${type}/orders/invoices`, {
      credentials: "include",
      method: "POST",
      headers: { "content-type": "application/json; charset=utf-8" },
      body: JSON.stringify(data)
    }).then((res) => res.json().then((r) => {
      if (r.code === "200") {
        window.alert("송장 업데이트가 완료되었습니다.");
        getBaconOrderDetail();

      } else {
        window.alert(r.message);
        setSpinnerStatus(dispatch, false);
      }
    }));
  };

  const calculatePrice = () => {
    let totalSubPrice = 0;
    let totalPrice = 0;
    let totalFee = 0;
    let totalDelivery = 0;
    let discountTotal = 0;
    let totalCountTemp = 0;
    if (orderItemListArr.length > 0) {
      orderItemListArr?.filter(item => item.type === "line_items").map(data => {
        if (data.subtotal) {
          totalSubPrice += Number(data.subtotal);
        } else {
          totalSubPrice += Number(data.total);
        }
        totalCountTemp += data.quantity;
        discountTotal += (Number(data.subtotal) - Number(data.total));
      });
      orderItemListArr?.filter(item => item.type === "fee_lines").map(data => {
        if (data.subtotal) {
          totalFee += Number(data.subtotal);
        } else {
          totalFee += Number(data.total);
        }
      });

      orderItemListArr?.filter(item => item.type === "shipping_lines").map(data => {
        if (data.subtotal) {
          totalDelivery += Number(data.subtotal);
        } else {
          totalDelivery += Number(data.total);
        }
      });

      orderItemListArr?.filter(item => item.type !== "coupon_lines_base").map(data => {
        totalPrice += Number(data.total);
      });
    }
    setTotalDeliveryPay(totalDelivery);
    setTotalFeePay(totalFee);
    setDiscountPrice(discountTotal);
    setSubTotalPrice(totalSubPrice);
    setTotalPrice(totalPrice);
    setTotalCount(totalCountTemp);
  };
  const copyBillingToShipping = () => {
    document.getElementById(`shipping_first_name`).value = document.getElementById(`billing_first_name`).value;
    document.getElementById(`shipping_last_name`).value = document.getElementById(`billing_last_name`).value;
    document.getElementById(`shipping_address1`).value = document.getElementById(`billing_address1`).value;
    document.getElementById(`shipping_address2`).value = document.getElementById(`billing_address2`).value;
    document.getElementById(`shipping_postcode`).value = document.getElementById(`billing_postcode`).value;
    document.getElementById(`_shipping_phone1`).value = document.getElementById("billing_phone").value;
  };

  const deleteMemo = async (id) => {
    const result = window.confirm("이 메모를 정말로 삭제하시겠습니까? 삭제 후에는 복구가 불가능합니다.");
    if (result) {
      setSpinnerStatus(dispatch, true);
      await fetch(`${type === "bacon" ? WP_BACON_REST_API_URL : WP_COLLINS_REST_API_URL}/orders/${pathInfo}/notes/${id}
?force=true&consumer_key=${type === "bacon" ? WP_BACON_CONSUMER_KEY : WP_COLLINS_CONSUMER_KEY}&consumer_secret=${type === "bacon" ? WP_BACON_CONSUMER_SECRET : WP_COLLINS_CONSUMER_SECRET}`, {
        credentials: "include",
        method: "DELETE"
      }).then((res) => res.json().then(async (r) => {
        await callOrderNote();
      }));
      setSpinnerStatus(dispatch, false);
    }
  };

  const addMemo = async () => {
    const noteElement = document.getElementById("order-note-input")?.value;
    const status = targetOrderNoteStatus.value === "customer";
    const adminName = document.getElementById("user-info-email").innerText;
    const data = { "note": noteElement, "customer_note": status, "author": adminName, "added_by_user": true };
    setSpinnerStatus(dispatch, true);
    await fetch(`${type === "bacon" ? WP_BACON_REST_API_URL : WP_COLLINS_REST_API_URL}/orders/${pathInfo}/notes?consumer_key=${type === "bacon" ? WP_BACON_CONSUMER_KEY : WP_COLLINS_CONSUMER_KEY}&consumer_secret=${type === "bacon" ? WP_BACON_CONSUMER_SECRET : WP_COLLINS_CONSUMER_SECRET}`, {
      credentials: "include",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
    }).then((res) => res.json().then(async (r) => {
      if (r.id) {
        await callOrderNote();
        document.getElementById("order-note-input").value = "";
      }
    }));
    setSpinnerStatus(dispatch, false);
  };

  const getDateTime = (time) => {
    const dateTime = new Date().setTime(time * 1000);
    return new Date(dateTime).toLocaleString();
  };

  const selectCustomer = () => {
    if (document.getElementById(`billing_first_name`)) {
      document.getElementById(`billing_first_name`).value = customer.billing_first_name ? customer.billing_first_name : "";
      document.getElementById(`billing_last_name`).value = customer.billing_last_name ? customer.billing_last_name : "";
      document.getElementById(`billing_address1`).value = customer.billing_address1 ? customer.billing_address1 : "";
      document.getElementById(`billing_address2`).value = customer.billing_address2 ? customer.billing_address2 : "";
      document.getElementById(`billing_postcode`).value = customer.billing_postcode ? customer.billing_postcode : "";
      document.getElementById(`billing_email`).value = customer.billing_email ? customer.billing_email : "";
      document.getElementById(`billing_phone`).value = customer.billing_phone ? customer.billing_phone : "";
      document.getElementById(`shipping_first_name`).value = customer.shipping_first_name ? customer.shipping_first_name : "";
      document.getElementById(`shipping_last_name`).value = customer.shipping_last_name ? customer.shipping_last_name : "";
      document.getElementById(`shipping_address1`).value = customer.shipping_address1 ? customer.shipping_address1 : "";
      document.getElementById(`shipping_address2`).value = customer.shipping_address2 ? customer.shipping_address2 : "";
      document.getElementById(`shipping_postcode`).value = customer.shipping_postcode ? customer.shipping_postcode : "";
      document.getElementById(`shipping_note`).value = "";
      if (initMetaData.length > 0) {
        document.getElementById(`_shipping_phone1`).value = initMetaData.filter(item => item.key.includes("_shipping_phone"))[0] ? initMetaData.filter(item => item.key.includes("_shipping_phone"))[0].value : "";
      } else {
        document.getElementById(`_shipping_phone1`).value = customer.shipping_phone ? customer.shipping_phone : orderData?.meta_data?.filter(item => item.key.includes("_shipping_phone"))[0] ? orderData?.meta_data?.filter(item => item.key.includes("_shipping_phone"))[0]?.value : "";
      }
    }
  };

  const checkRefundTotal = (refunds) => {
    let total = 0;
    refunds.map(item => total += Number(item.total));
    return total;
  };
  const copyHtml = async () => {
    try {
      const htmlContent = `${type === "bacon" ? "베이컨박스" : "콜린스"} 주문 #${pathInfo} ${window.location.origin}/${type}/order/${pathInfo}`;
      await navigator.clipboard.writeText(htmlContent);
      alert("복사 완료!");
    } catch (err) {
      console.error("복사 실패:", err);
    }
  };

  useEffect(() => {
    if (customer) {
      selectCustomer();
    }
  }, [customer]);

  useEffect(() => {
    getBaconOrderDetail();
  }, []);

  useEffect(() => {
    calculatePrice();
  }, [orderItemListArr]);

  useEffect(() => {
    getBaconProductDetail();
  }, [targetProductList]);

  return (
    <DashboardLayout>
      <MDSorryBoxModal id={"sorryBoxModal"} productList={productList} hotKeyIndex={hotKeyTargetIndex}
                       propsTargetProductList={orderItemListArr} orderId={orderData.id} type={type}
                       data={orderHotKeyInfo} setHotKeyIndex={setHotKeyTargetIndex} setData={setOrderHotKeyInfo} />
      <MDProductModal productList={productList} action={setTargetProductList} />
      <MDPostCodeModal id={"daum-postcode-modal"} setPostCodeDocument={setPostCodeDocument} />
      <MDRefundModal id={"refundModal"} orderData={orderData} type={type} refresh={getBaconOrderDetail} />
      <MDInputModal id={"additionalFeeModal"} title={"고정 금액이나 백분율로 요금을 입력하세요."} action={addAdditionalFee} />
      <MDInputModal id={"couponModal"} title={"적용할 쿠폰 코드를 입력하세요. 할인은 세전 라인 합계에 적용됩니다."} action={useCoupon} />
      <DashboardNavbar />
      <MDBox pt={6} pb={3} id={"order-detail"}>
        <MDBox display={"flex"}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  display={"flex"}
                  coloredShadow="info"
                  style={{ justifyContent: "space-between" }}
                >
                  <MDTypography variant="h6" color="white">
                    {pathInfo.toLowerCase() === "new" ? "추가하기" : "수정하기"}
                  </MDTypography>
                  <MDBox padding={"0 3px"}><MDButton
                    id={"order_button"}
                    onClick={() => {
                      pathInfo.toLowerCase() === "new" ? submitOrder() : modifyOrder();
                    }}
                    color={"success"}>{pathInfo.toLowerCase() === "new" ? "생성하기" : "업데이트"}</MDButton></MDBox>
                </MDBox>
                {orderDataLoading && <MDBox mx={2} mt={2} py={3} px={2} borderRadius="lg" display="block">
                  {orderData.id &&
                    <MDBox mb={3} display={"flex"} style={{ justifyContent: "space-between", flexWrap: "wrap" }}>
                      <MDBox display={"flex"} alignItems={"center"}>
                        <MDTypography variant="h4">
                          주문 {"#" + orderData.id}
                        </MDTypography>
                        <MDBox width={"20px"} height={"20px"} style={{ cursor: "pointer" }}
                               onClick={() => copyHtml()}>
                          <img style={{ display: "flex", width: "100%" }}
                               src={require("../../assets/images/icons/link_copy.png")} />
                        </MDBox>
                      </MDBox>
                      <MDBox display={"flex"} style={{ flexWrap: "wrap" }}>
                        {orderHotKeyInfo.map((item, i) =>
                          <MDBox padding={"0 3px"} mt={1} style={{ position: "relative" }}
                                 display={"flex"}>
                            <MDButton
                              color={"info"}
                              onClick={() => {
                                if (item.title === "쏘리 박스 편성") {
                                  setHotKeyTargetIndex(0);
                                  document.getElementById("sorryBoxModal").showModal();
                                } else {
                                  if (item.title === "복제" && orderStatus.value === "template") {
                                    window.location.href = `/${type}/order/new?orderId=${orderData.id}&customerId=${item.customer?.id}&baseItemId=${item.itemIds}&status=pending`;
                                  } else {
                                    window.location.href = `/${type}/order/new?orderId=${orderData.id}&customerId=${item.customer?.id}&baseItemId=${item.itemIds}&status=${item.status}`;

                                  }
                                }
                              }
                              }>{item.title}</MDButton>
                          </MDBox>)}
                      </MDBox>
                    </MDBox>
                  }
                  <MDBox pr={1} mr={1} my={1} minWidth="200px">
                    <MDTypography variant="h6">
                      일반
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    {orderStatus.value === "template" && <MDBox pr={1} mr={1} my={1}>
                      <MDTypography fontSize={"14px"} fontWeight={"medium"}>
                        템플릿명
                      </MDTypography>
                      <MDInput
                        id={"template_title"}
                        defaultValue={orderData?.meta_data?.filter(item => item.key.includes("template_title") && item.value !== "")[0]?.value}
                      />
                    </MDBox>}
                    <MDBox display="flex" flexWrap="wrap">
                      <MDBox pr={1} mr={1} my={1}>
                        <MDTypography fontSize={"14px"} fontWeight={"medium"}>
                          생성 날짜
                        </MDTypography>
                        <DateTimePicker
                          value={dayjs(createDate)}
                          ampm={false}
                          format="YYYY/MM/DD HH:MM"
                          onChange={(newValue) => {
                            setCreateDate(newValue);
                            setChangeDateFlag(true);
                          }}
                        />
                        {/*<MDInput*/}
                        {/*  style={{ minWidth: "250px" }}*/}
                        {/*  value={orderData.date_created ? new Date(orderData.date_created).toLocaleString() : new Date().toLocaleString()}*/}
                        {/*  disabled />*/}
                      </MDBox>
                      <MDBox pr={1} mr={1} my={1} minWidth="200px"
                             style={{ backgroundColor: "#fffff", zIndex: "5", position: "relative" }}>
                        <MDTypography fontSize={"14px"} fontWeight={"medium"}>
                          상태
                        </MDTypography>
                        <Select
                          id="productName"
                          className="basic-single status-dropdown"
                          classNamePrefix="select"
                          isClearable={null}
                          isSearchable={true}
                          label={"고객"}
                          name="color"
                          value={orderStatus}
                          options={statusDropdownItems}
                          onChange={(e) => setOrderStatus(e)}
                        />
                      </MDBox>
                    </MDBox>
                    <MDBox pr={1} mr={1} my={1} minWidth="300px" witdh={"100%"} maxWidth={"600px"}
                           style={{ backgroundColor: "#fffff", zIndex: "4", position: "relative" }}>
                      <MDBox display={"flex"} mb={0.5}>
                        <MDTypography fontSize={"14px"} fontWeight={"medium"}>
                          고객
                        </MDTypography>
                        <MDTypography fontSize={"13px"}
                                      color={"info"}
                                      style={{
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                        textDecoration: "underline"
                                      }}
                                      onClick={() => window.open(`${type === "bacon" ? WP_BACON_URL : WP_COLLINS_URL}/user-edit.php?user_id=${customer.value}`, "_blank")}>
                          프로필
                        </MDTypography>
                        <MDTypography fontSize={"13px"}
                                      color={"info"}
                                      style={{
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                        textDecoration: "underline"
                                      }}
                                      onClick={() => window.open(`/${type}/order/list?userId=${customer.value}`)}>
                          다른 주문 보기
                        </MDTypography>
                      </MDBox>
                      <Select
                        id="productName"
                        className="basic-single customer-dropdown"
                        classNamePrefix="select"
                        isClearable={true}
                        isSearchable={true}
                        label={"고객"}
                        name="color"
                        options={userList}
                        value={customer}
                        onInputChange={e => debounceSearch(e, 500)}
                        onChange={(e) => {
                          setCustomer({ ...e });
                        }}
                      />
                    </MDBox>
                  </MDBox>
                  <MDBox display="flex" flexWrap="wrap">
                    <MDBox style={{ width: "50%" }} display="block">
                      <MDBox my={1} minWidth="100%">
                        <MDTypography variant="h6">
                          청구
                        </MDTypography>
                      </MDBox>
                      <MDBox display={"grid"} width="100%" style={{ gridTemplateColumns: "repeat(2,1fr)" }}>
                        <MDBox pr={1} mr={1} my={1}>
                          <MDInput label="성"
                                   id={"billing_last_name"}
                                   defaultValue={orderData.billing?.last_name ? orderData.billing?.last_name : ""}
                                   fullWidth />
                        </MDBox>
                        <MDBox pr={1} mr={1} my={1}>
                          <MDInput label="이름"
                                   id={"billing_first_name"}
                                   defaultValue={orderData.billing?.first_name ? orderData.billing?.first_name : ""}
                                   fullWidth
                          />
                        </MDBox>
                        <MDBox pr={1} mr={1} my={1}>
                          <MDInput label="주소1"
                                   id={"billing_address1"}
                                   defaultValue={orderData.billing?.address_1 ? orderData.billing?.address_1 : ""}
                                   fullWidth
                          />
                        </MDBox>
                        <MDBox pr={1} mr={1} my={1}>
                          <MDInput label="주소2"
                                   id={"billing_address2"}
                                   defaultValue={orderData.billing?.address_2 ? orderData.billing?.address_2 : ""}
                                   fullWidth />
                        </MDBox>
                        <MDBox pr={1} mr={1} my={1}>
                          <MDInput label="우편번호"
                                   id={"billing_postcode"}
                                   defaultValue={orderData.billing?.postcode ? orderData.billing?.postcode : ""}
                                   fullWidth />
                        </MDBox>
                        <MDBox pr={1} mr={1} my={1}>
                          <MDButton onClick={() => {
                            setPostCodeDocumentType("billing");
                            document.getElementById("daum-postcode-modal").showModal();
                          }} color={"info"}>주소 검색</MDButton>
                        </MDBox>
                        <MDBox pr={1} mr={1} my={1}>
                          <MDInput label="이메일 주소"
                                   id={"billing_email"}
                                   defaultValue={orderData.billing?.email ? orderData.billing?.email : ""}
                                   fullWidth />
                        </MDBox>
                        <MDBox pr={1} mr={1} my={1}>
                          <MDInput label="전화번호"
                                   id={"billing_phone"}
                                   onChange={(e) => document.getElementById("billing_phone").value = autoHypenPhone(e.target.value)}
                                   defaultValue={orderData.billing?.phone ? autoHypenPhone(orderData.billing?.phone) : ""}
                                   fullWidth />
                        </MDBox>
                        <MDBox pr={1} mr={1} my={1}>
                          <Select
                            id="payment_method_title"
                            className="basic-single payment-select-wrapper"
                            classNamePrefix="select"
                            isClearable={null}
                            isSearchable={true}
                            label={"결제 방법"}
                            name="결제 방법"
                            value={paymentStatus}
                            options={paymentDropdownItems}
                            onChange={(e) => setPaymentStatus(e)}
                          />
                          {/*<MDInput label="결제 방법"*/}
                          {/*         id={"payment_method_title"}*/}
                          {/*         defaultValue={orderData?.payment_method_title ? orderData?.payment_method_title : ""}*/}
                          {/*         fullWidth />*/}
                        </MDBox>
                        <MDBox pr={1} mr={1} my={1}>
                          <MDInput label="거래 ID"
                                   id={"transaction_id"}
                                   defaultValue={orderData?.transaction_id ? orderData?.transaction_id : ""}
                                   fullWidth />
                        </MDBox>
                      </MDBox>
                    </MDBox>
                    <MDBox style={{ width: "50%" }} display="block">
                      <MDBox my={1} minWidth="100%" display={"flex"} style={{ alignItems: "center" }}>
                        <MDTypography variant="h6">
                          배송
                        </MDTypography>
                        <MDTypography fontSize={"14px"}
                                      color={"info"}
                                      style={{
                                        marginLeft: "20px",
                                        cursor: "pointer",
                                        textDecoration: "underline"
                                      }}
                                      onClick={() => copyBillingToShipping()}
                        >
                          청구지 주소 복사
                        </MDTypography>
                      </MDBox>
                      <MDBox display={"grid"} width="100%" style={{ gridTemplateColumns: "repeat(2,1fr)" }}>
                        <MDBox pr={1} mr={1} my={1}>
                          <MDInput label="성"
                                   id={"shipping_last_name"}
                                   defaultValue={orderData.shipping?.last_name ? orderData.shipping?.last_name : ""}
                                   fullWidth
                          />
                        </MDBox>
                        <MDBox pr={1} mr={1} my={1}>
                          <MDInput label="이름"
                                   id={"shipping_first_name"}
                                   defaultValue={orderData.shipping?.first_name ? orderData.shipping?.first_name : ""}
                                   fullWidth
                          />
                        </MDBox>
                        <MDBox pr={1} mr={1} my={1}>
                          <MDInput label="주소1"
                                   id={"shipping_address1"}
                                   defaultValue={orderData.shipping?.address_1 ? orderData.shipping?.address_1 : ""}
                                   fullWidth
                          />
                        </MDBox>
                        <MDBox pr={1} mr={1} my={1}>
                          <MDInput label="주소2"
                                   id={"shipping_address2"}
                                   defaultValue={orderData.shipping?.address_2 ? orderData.shipping?.address_2 : ""}
                                   fullWidth
                          />
                        </MDBox>
                        <MDBox pr={1} mr={1} my={1}>
                          <MDInput label="우편번호"
                                   id={"shipping_postcode"}
                                   defaultValue={orderData.shipping?.postcode ? orderData.shipping?.postcode : ""}
                                   fullWidth />
                        </MDBox>
                        <MDBox pr={1} mr={1} my={1}>
                          <MDButton onClick={() => {
                            setPostCodeDocumentType("shipping");
                            document.getElementById("daum-postcode-modal").showModal();
                          }} color={"info"}>주소 검색</MDButton>
                        </MDBox>
                        <MDBox pr={1} mr={1} my={1}>
                          <MDInput label="배송지 전화번호"
                                   id={"_shipping_phone1"}
                                   onChange={(e) => document.getElementById("_shipping_phone1").value = autoHypenPhone(e.target.value)}
                                   defaultValue={autoHypenPhone(orderData?.meta_data?.filter(item => item.key.includes("_shipping_phone") && item.value !== "")[0]?.value)}
                                   fullWidth />
                        </MDBox>
                        <MDBox pr={1} mr={1} my={1}>
                          <MDInput label="고객이 남긴 메모"
                                   id={"shipping_note"}
                                   multiline rows={4}
                                   defaultValue={orderData?.customer_note ? orderData?.customer_note : ""}
                                   fullWidth />
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  {orderData.payment_method?.includes("vbank") &&
                    <MDBox
                      style={{ borderTop: "1px solid rgba(73, 80, 87, 0.314)", marginTop: "5px", paddingTop: "5px" }}>
                      <MDTypography variant="h6">
                        가상계좌 정보
                      </MDTypography>
                      <MDTypography fontSize={"13px"}>
                        은행명 : {orderData.meta_data.filter(item => item.key === "_iamport_vbank_name")[0]?.value}<br />
                        예금주
                        : {orderData.meta_data.filter(item => item.key === "_iamport_vbank_holder")[0]?.value}<br />
                        계좌번호 : {orderData.meta_data.filter(item => item.key === "_iamport_vbank_num")[0]?.value}<br />
                        입금기한
                        : {getDateTime(orderData.meta_data.filter(item => item.key === "_iamport_vbank_date")[0]?.value)}<br />
                      </MDTypography>
                    </MDBox>}
                </MDBox>}
              </Card>
            </Grid>
          </Grid>
          {pathInfo.toLowerCase() !== "new" && <MDBox ml={2}
                                                      style={{ height: "fit-content", minWidth: "350px" }}>
            <MDBox mx={2} py={3} px={2} display="block" bgColor={"white"} borderRadius={"0.75rem"}>
              <MDBox pr={1} mr={1} my={1} minWidth="200px">
                <MDTypography variant="h6" color="black">
                  주문 노트
                </MDTypography>
              </MDBox>
              <MDBox style={{ maxHeight: "220px", height: "220px", overflow: "scroll" }}>
                {orderNote.map(item => <div className="ballon-wrapper">
                  <div className="ballon"
                       style={{
                         backgroundColor: item.author === "우커머스" ? "#d7cad2" : item.customer_note ? "#a7cedc" : "#efefef",
                         borderTopColor: item.author === "우커머스" ? "#d7cad2" : item.customer_note ? "#a7cedc" : "#efefef"
                       }}>{item.note}</div>
                  <div className="ballon-detail-info">
                    {item.date_created.replace("T", " ")}
                    <div className="ballon-delete-button" onClick={() => deleteMemo(item.id)}>메모 삭제</div>
                  </div>
                </div>)}
              </MDBox>
              <MDBox mt={2} pt={1} style={{ width: "100%", borderTop: "1px solid black" }}>
                <MDTypography fontSize={"12px"} color="black">
                  메모 추가
                </MDTypography>
                <MDInput style={{ width: "100%" }} id={"order-note-input"} />
                <div className="order-note-status-wrapper">
                  <Select
                    id="order-note-selection"
                    className="basic-single order-note-status"
                    classNamePrefix="select"
                    isClearable={null}
                    isSearchable={true}
                    label={"결제 방법"}
                    name="결제 방법"
                    value={targetOrderNoteStatus}
                    options={orderNoteStatus}
                    onChange={(e) => setTargetOrderNoteStatus(e)}
                  />
                  <MDButton color={"info"} onClick={() => addMemo()}>추가하기</MDButton>
                </div>

              </MDBox>

            </MDBox>
            <MDBox mx={2} py={3} px={2} mt={2} bgColor={"white"} borderRadius={"0.75rem"} display="block">
              <MDBox pr={1} mr={1} my={1} minWidth="200px">
                <MDTypography variant="h6" color="black">
                  송장 관리
                </MDTypography>
              </MDBox>
              <MDBox style={{ width: "100%" }}>
                <MDTypography fontSize={"13px"} color="black">
                  택배사
                </MDTypography>
                <Select
                  id="order-note-selection"
                  className="basic-single delivery-dropdown"
                  classNamePrefix="select"
                  isClearable={null}
                  isSearchable={true}
                  name="택배 종류"
                  value={targetInvoice}
                  options={invoiceDropdownItems}
                  onChange={(e) => setTargetInvoice(e)}
                />
                <MDBox mt={1}>
                  <MDTypography fontSize={"13px"} color="black">
                    송장번호
                  </MDTypography>
                  <MDInput style={{ width: "100%" }} id={"delivery-number-input"} />
                </MDBox>
              </MDBox>
              <MDBox mt={2} pt={1} style={{ width: "100%", borderTop: "1px solid black" }}>
                <div className="order-note-status-wrapper">
                  <MDButton color={"warning"} style={{ width: "calc(50% - 5px)" }} onClick={() => deleteInvoice()}>송장정보
                    삭제</MDButton>
                  <MDButton color={"info"} style={{ width: "calc(50% - 5px)" }} onClick={() => updateInvoice()}>송장정보
                    업데이트</MDButton>
                </div>
                <div className="order-note-status-wrapper">
                  <MDButton color={"info"} style={{ width: "100%" }}
                            onClick={() => window.open(deliveryData(targetInvoice.value, document.getElementById("delivery-number-input").value), "_blank")}>배송조회</MDButton>
                </div>
              </MDBox>
            </MDBox>
          </MDBox>}
        </MDBox>
      </MDBox>
      <MDBox pt={1} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2} py={3} px={2} borderRadius="lg" display="block">
                <MDBox pr={1} mr={1} my={1} minWidth="200px">
                  <MDTypography variant="h6" color="black">
                    상품 리스트
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" flexWrap="wrap">
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={true}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </MDBox>
              <MDBox mx={2} px={2} mb={4} borderRadius="lg" display="block">
                <MDBox display={"flex"} justifyContent="end" width={"100%"}
                       pb={2}
                >
                  <MDBox display="grid"
                         style={{
                           gridTemplateColumns: "repeat(2,1fr)",
                           gap: "5px",
                           float: "right",
                           textAlign: "right"
                         }}
                         padding={"0 30px"} fontSize={"14px"}>
                    <MDBox>총 수량 : </MDBox>
                    <MDBox>{totalCount}개</MDBox>
                    <MDBox>품목 소계 :</MDBox>
                    <MDBox> {subTotalPrice}원</MDBox>
                    <MDBox>수수료 :</MDBox>
                    <MDBox> {totalFeePay}원</MDBox>
                    <MDBox>배송 :</MDBox>
                    <MDBox> {totalDeliveryPay}원</MDBox>
                    <MDBox>쿠폰 :</MDBox>
                    <MDBox>- {discountPrice}원</MDBox>
                    <MDBox>주문 총계 :</MDBox>
                    <MDBox> {orderData.status === "refunded" ? orderData.total : totalPrice}원</MDBox>
                    {orderData.refunds && orderData.refunds.length > 0 &&
                      <>< MDBox> 환불 총액 :</MDBox>
                        <MDBox> {checkRefundTotal(orderData.refunds)}원</MDBox>
                        <MDBox>순 결제 :</MDBox>
                        <MDBox> {Number(orderData.total) + checkRefundTotal(orderData.refunds)}원</MDBox></>}
                  </MDBox>
                </MDBox>
                <MDBox
                  style={{ borderTop: "1px solid #49505750" }}
                >
                  {editable && <MDBox
                    style={{ float: "right" }}
                    padding={"13px 27px 0 27px"}
                    display={"flex"}
                  >
                    <MDBox padding={"0 3px"}><MDButton color={"info"}
                                                       onClick={() => document.getElementById("productModal").show()
                                                       }>상품 추가</MDButton></MDBox>
                    <MDBox padding={"0 3px"}><MDButton color={"info"}
                                                       onClick={() => document.getElementById("additionalFeeModal").show()}
                    >수수료 추가</MDButton></MDBox>
                    <MDBox padding={"0 3px"}>
                      <MDButton color={"info"} onClick={() => addShippingLine()}
                      >배송비 추가</MDButton></MDBox>
                    <MDBox padding={"0 3px"}><MDButton color={"info"}
                                                       onClick={() => {
                                                         if (pathInfo.toLowerCase() === "new") {
                                                           return window.alert("쿠폰은 주문 생성 후 등록 가능합니다.");
                                                         }
                                                         document.getElementById("couponModal").show();
                                                       }}
                    >쿠폰 적용하기</MDButton></MDBox>
                    <MDBox padding={"0 3px"}><MDButton color={"info"}
                                                       onClick={() => changeAllItemsPayZero()}
                    >모든 상품 0원 변경</MDButton></MDBox>
                  </MDBox>}
                  {!editable && <MDBox padding={"10px 0 0 0"}><MDButton color={"info"}
                                                                        onClick={async () => {
                                                                          if (orderData?.payment_method?.includes("vbank")) {
                                                                            const result = window.confirm("가상계좌 환불은 포트원 어드민에서 실행해주세요.\n확인 클릭 시 포트원 어드민 주소가 복사됩니다..");
                                                                            if (result) {
                                                                              try {
                                                                                const htmlContent = "https://admin.portone.io/payments";
                                                                                await navigator.clipboard.writeText(htmlContent);
                                                                                alert("포트원 어드민 주소 복사 완료");
                                                                              } catch (err) {
                                                                                console.error("복사 실패", err);
                                                                              }
                                                                            }
                                                                          } else {
                                                                            document.getElementById("refundModal").show();
                                                                          }
                                                                        }}
                  >{orderData.payment_method !== "null" && orderData.payment_method_title} 환불</MDButton></MDBox>}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default OrderDetail;
