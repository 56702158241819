import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useEffect, useState } from "react";
import { API_URL } from "../../../constants/constants";
import { couponNameConverter, couponStatusConverter, distStatusConverter } from "../../../Util";
import MDButton from "../../../components/MDButton";
import MDInput from "../../../components/MDInput";

export default function data(data, pageType, callBack) {
  const [updatePlaceId, setUpdatePlaceId] = useState("");

  const updatePlace = () => {
    const placeName = document.getElementById(`placeName${updatePlaceId}`).value;
    const placeAlias = document.getElementById(`placeAlias${updatePlaceId}`).value;
    const placeInsta = document.getElementById(`placeInsta${updatePlaceId}`).value;
    const placeLink = document.getElementById(`placeLink${updatePlaceId}`).value;
    const placeDescription = document.getElementById(`placeDescription${updatePlaceId}`).value;
    const placePhone = document.getElementById(`placePhone${updatePlaceId}`).value;
    const addressDetail = document.getElementById(`addressDetail${updatePlaceId}`).value;
    fetch(`${API_URL}/api/collins/places/${updatePlaceId}`, {
      method: "put", headers: { "content-type": "application/json" }, body: JSON.stringify({
        "id": updatePlaceId,
        "name": placeName,
        "alias": placeAlias,
        "instagram_url": placeInsta,
        "link_url": placeLink,
        "description": placeDescription,
        "address2": addressDetail,
        "phone": placePhone.replaceAll("-", "")
      }), credentials: "include"
    }).then(res => res.json().then(r => {
      if (r) {
        setUpdatePlaceId("");
        window.alert("수정 완료 되었습니다.");
        callBack();
      }
    }));
  };

  const deletePlace = (placeId) => {
    fetch(`${API_URL}/api/collins/places/${placeId}`, {
      method: "delete",
      credentials: "include"
    }).then(res => res.json().then(r => {
      if (r.message) {
        window.alert("삭제 되었습니다.");
        callBack();
      }
    }));
  };

  return {
    columns: [
      { Header: "ID", accessor: "placeId", align: "center" },
      { Header: "플레이스명", accessor: "name", align: "center" },
      { Header: "별칭(표기명)", accessor: "alias", align: "center" },
      { Header: "주소", accessor: "address", align: "center" },
      { Header: "상세주소", accessor: "address2", align: "center" },
      { Header: "태그", accessor: "description", align: "center" },
      { Header: "전화번호", accessor: "phoneNum", align: "center" },
      { Header: "인스타그램", accessor: "instagram", align: "center" },
      { Header: "외부링크", accessor: "linkUrl", align: "center" },
      { Header: "수정일", accessor: "updateDate", align: "center" },
      { Header: "Action", accessor: "action", align: "center" }
    ],

    rows: data.map((item) => ({
      placeId: (
        <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
          {item.id}
        </MDTypography>
      ),
      name: (
        <MDInput defaultValue={item?.name} key={`placeName${item.id}`} id={`placeName${item.id}`}
                 disabled={updatePlaceId !== item.id} />
      ),
      alias: (
        <MDInput defaultValue={item?.alias} key={`placeAlias${item.id}`} id={`placeAlias${item.id}`}
                 disabled={updatePlaceId !== item.id} />
      ),
      address: (
        <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
          {item.address}
        </MDTypography>
      ),
      address2: (
        <MDInput defaultValue={item?.address2} key={`addressDetail${item.id}`} id={`addressDetail${item.id}`}
                 disabled={updatePlaceId !== item.id} />
      ),
      description: (
        <MDInput defaultValue={item?.description} key={`placeDescription${item.id}`} id={`placeDescription${item.id}`}
                 disabled={updatePlaceId !== item.id} />

      ),
      phoneNum: (
        <MDInput defaultValue={item?.phone} key={`placePhone${item.id}`} id={`placePhone${item.id}`}
                 disabled={updatePlaceId !== item.id} />

      ),
      instagram: (
        <MDInput defaultValue={item?.instagram_url} key={`placeInsta${item.id}`} id={`placeInsta${item.id}`}
                 disabled={updatePlaceId !== item.id} />

      ),
      linkUrl: (
        <MDInput defaultValue={item?.link_url} key={`placeLink${item.id}`} id={`placeLink${item.id}`}
                 disabled={updatePlaceId !== item.id} />

      ),
      updateDate: (
        <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
          {new Date(item.update_date).toLocaleString()}
        </MDTypography>
      ), action: (
        <MDBox display="flex">
          <MDBox mr={1}>
            <MDButton
              color="info"
              onClick={() => {
                updatePlaceId === item.id ? updatePlace() : setUpdatePlaceId(item.id);
              }}>{updatePlaceId === item.id ? "완료" : "수정"}</MDButton>
          </MDBox>
          <MDButton color="warning"
                    onClick={() => deletePlace(item.id)}
          >삭제</MDButton>
        </MDBox>
      )
    }))
  };
};
