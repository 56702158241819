import React, { useEffect, useState } from "react";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import { Divider } from "@mui/material";
import MDButton from "../MDButton";
import "./index.css";
import GoogleIcon from "../../assets/images/icons/Google-Icon.png";
import MDProgress from "../MDProgress";
import MDInput from "../MDInput";
import { API_URL } from "../../constants/constants";
import { setSpinnerStatus, useMaterialUIController } from "../../context";

function MDRefundModal(props) {
  const [controller, dispatch] = useMaterialUIController();
  const { id, orderData, type, refresh } = props;
  const [refundAmount, setRefundAmount] = useState(0);
  const [refundReason, setRefundReason] = useState("");
  const [nPayTargetItems, setNPayTargetItems] = useState([]);

  const checkRefundTotal = (orderData) => {
    let total = 0;
    if (orderData.refunds && orderData.refunds.length > 0) {
      orderData.refunds.map(item => total += Number(item.total));
      return total;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (orderData.line_items) {
      setNPayTargetItems([...orderData.line_items]);
    }
  }, [orderData]);

  const refundPay = async () => {
    await setSpinnerStatus(dispatch, true);
    if (orderData.payment_method === "iamport_kakao" || orderData.payment_method === "iamport_card" || orderData.payment_method === "iamport_payco") {
      const data = {
        order_id: orderData.id,
        order_key: orderData.order_key,
        transaction_id: orderData.transaction_id,
        refund_amount: refundAmount,
        tax_free: 0,
        reason: refundReason
      };
      await fetch(`${API_URL}/api/${type}/orders/refund/card`, {
        headers: { "content-type": "application/json; charset=utf-8" },
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include"
      });
    } else if (orderData.payment_method === "iamport_naverpay") {
      const targetNaverProductIds = nPayTargetItems.map(item => item.meta_data.filter(metaItem => metaItem.key === "naver_product_order_id")[0].value);
      const data = {
        order_id: orderData.id,
        transaction_id: orderData.transaction_id,
        product_order_id: targetNaverProductIds
      };
      await fetch(`${API_URL}/api/${type}/orders/refund/naver`, {
        headers: { "content-type": "application/json; charset=utf-8" },
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include"
      });
    } else if (orderData.payment_method === "iamport_vbank") {
      const targetHolder = orderData.meta_data.filter(item => item.key === "_iamport_vbank_holder")[0];
      const targetBank = orderData.meta_data.filter(item => item.key === "_iamport_vbank_code")[0];
      const targetAccount = orderData.meta_data.filter(item => item.key === "_iamport_vbank_num")[0];
      const targetTel = orderData.meta_data.filter(item => item.key === "_shipping_phone")[0];
      const data = {
        order_id: orderData.id,
        order_key: orderData.order_key,
        transaction_id: orderData.transaction_id,
        refund_amount: refundAmount,
        tax_free: 0,
        reason: refundReason,
        holder: targetHolder.value,
        bank: targetBank,
        account: targetAccount,
        tel: targetTel
      };
      await fetch(`${API_URL}/api/${type}/orders/refund/account`, {
        headers: { "content-type": "application/json; charset=utf-8" },
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include"
      });
    }
    document.getElementById(id).close();
    await refresh();
  };
  return (
    <dialog id={id} className={"refund-modal"}>
      <MDBox py={3} px={5} minWidth="400px" className={"content"}>
        <MDTypography component="div" variant="h4" color="text" style={{ textAlign: "center" }}
                      fontWeight="medium">환불</MDTypography>
        <MDTypography style={{ fontSize: "12px", textAlign: "center"}}>*환불 금액 0원 입력 시 남은 금액 전체 환불 처리됩니다.</MDTypography>
        <MDTypography mt={3} component="div" fontSize={"16px"} color="text" style={{ textAlign: "left" }}
                      fontWeight="medium">이미 환불 된 금액: {-checkRefundTotal(orderData)}원</MDTypography>
        <MDTypography component="div" fontSize={"16px"} color="text" style={{ textAlign: "left" }}
                      fontWeight="medium">환불 가능한
          금액: {Number(orderData.total) + checkRefundTotal(orderData)}원</MDTypography>
        {orderData.payment_method === "iamport_naverpay" &&
          <MDBox style={{ marginTop: "5px" }}
          >
            <MDTypography component="div" fontSize={"16px"}
                          fontWeight="medium"
                          color="text">환불 가능 아이템</MDTypography>
            {orderData.line_items.map((item, i) => <MDBox display={"flex"}
                                                          style={{
                                                            cursor: "pointer"
                                                          }}
                                                          onClick={() => {
                                                            if (nPayTargetItems.some(nPayItem => item.id === nPayItem.id)) {
                                                              nPayTargetItems.map((nPayItem, n) => {
                                                                if (nPayItem.id === item.id) {
                                                                  nPayTargetItems.splice(n, 1);
                                                                }
                                                              });
                                                              setNPayTargetItems([...nPayTargetItems]);
                                                            } else {
                                                              nPayTargetItems.push(item);
                                                              setNPayTargetItems([...nPayTargetItems]);
                                                            }
                                                          }}>
              <input type={"checkbox"} checked={nPayTargetItems.some(nPayItem => item.id === nPayItem.id)} />
              <MDTypography
                key={i} component="div"
                fontSize={"13px"}
                style={{
                  opacity: nPayTargetItems.some(nPayItem => item.id === nPayItem.id) ? "1" : "0.5"
                }}

                fontWeight="medium"
                color="text">{item.name} {item.total}원</MDTypography></MDBox>)}
            <MDBox mt={1}>
              <MDTypography component="div" fontSize={"16px"}
                            fontWeight="medium"
                            color="text">환불 요청 금액
                : {nPayTargetItems?.reduce((acc, cur, idx) => acc + Number(cur.total), 0)}원</MDTypography>
            </MDBox>

          </MDBox>}
        {orderData.payment_method !== "iamport_naverpay" &&
          <MDBox style={{ display: "flex", alignItems: "center" }}> <MDTypography component="div" fontSize={"16px"}
                                                                                  color="text"
                                                                                  style={{ textAlign: "left" }}
                                                                                  fontWeight="medium">환불
            금액:</MDTypography><input style={{ marginLeft: "5px" }}
                                     value={Number(refundAmount)}
                                     onChange={(e) => {
                                       if (!isNaN(Number(e.target.value))) {
                                         setRefundAmount(Number(e.target.value));
                                       }
                                     }} /></MDBox>}
        {orderData.payment_method !== "iamport_naverpay" &&
          <MDBox style={{ display: "flex", alignItems: "center" }}> <MDTypography component="div" fontSize={"16px"}
                                                                                  color="text"
                                                                                  style={{ textAlign: "left" }}
                                                                                  fontWeight="medium">환불
            사유:</MDTypography><input style={{ marginLeft: "5px" }}
                                     value={refundReason}
                                     onChange={(e) => setRefundReason(e.target.value)} /></MDBox>}
        <MDBox style={{ justifyContent: "center", marginTop: "40px" }}>
          <MDButton value="default"
                    variant={"gradient"}
                    style={{ margin: "0 5px" }}
                    color={"info"}
                    onClick={() => {
                      refundPay();
                    }}>환불하기</MDButton>
          <MDButton value="default"
                    variant={"gradient"}
                    style={{ margin: "0 5px" }}
                    color={"secondary"}
                    onClick={() => {
                      document.getElementById(id).close();
                    }}>취소</MDButton></MDBox>

        {/*</MDBox>*/}
      </MDBox>
    </dialog>
  );
}

export default MDRefundModal;
