import React from "react";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import MDButton from "../MDButton";
import DaumPostcodeEmbed from "react-daum-postcode";

function MDPostCodeModal(props) {
  const { setPostCodeDocument, id } = props;
  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    fetch(`https://dapi.kakao.com/v2/local/search/address.json?query=${fullAddress}`, {
      method: "get",
      headers: { Authorization: "KakaoAK " + process.env.REACT_APP_KAKAO_REST_API_KEY }
    }).then(res => res.json().then(r => {
      if (r.documents.length > 0) {
        document.getElementById(id).close();
        setPostCodeDocument(r.documents[0]);
      }
    }));
  };
  const handleClose = (data) => {
    // 검색결과를 선택하여 화면이 닫혔을 경우 iframeDisplay값 false로 변경
    if (data === "COMPLETE_CLOSE") {
      document.getElementById(id).close();

    }
  };

  return (
    <dialog id={id} style={{ zIndex: 2002 }}>
      {document.getElementById(id)?.style.display !== "none" &&
        <MDBox py={3} px={5} width="auto">
          <MDTypography component="div" variant="h6" color="text" fontWeight="medium">주소 검색</MDTypography>
          <DaumPostcodeEmbed style={{ width: "100%" }} onComplete={handleComplete} onClose={handleClose}
                             autoClose={false} />
          <MDBox display="flex" mt={4} justifyContent="space-around">
            <MDButton value="cancel" color="secondary" variant={"gradient"}
                      onClick={() => document.getElementById(id).close()}>취소</MDButton>
          </MDBox>
        </MDBox>}
    </dialog>
  );
}

export default MDPostCodeModal;
