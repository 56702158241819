// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
import invoiceUploadListData from "layouts/tables/data/invoiceUploadListData";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useMemo, useRef, useState } from "react";
import { Icon } from "@mui/material";
import MDInput from "../../components/MDInput";
import { API_URL } from "../../constants/constants";
import MDPagination from "../../components/MDPagination";
import MDButton from "../../components/MDButton";
import { DateRange } from "react-date-range";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import {
  autoHypenPhone,
  dateFormat,
  downloadCSV,
  getDataRangeInputs,
  showDataRange,
  toStringByFormatting
} from "../../Util";
import MDReleaseNotificationModifyModal from "../../components/MDReleaseNotificationModifyModal";
import MDModal from "../../components/MDModal";
import { useNavigate } from "react-router-dom";
import { setSpinnerStatus, useMaterialUIController } from "context";

import React from "react";


function InvoiceUploadListPage(props) {
  const [controller, dispatch] = useMaterialUIController();

  const { type } = props;
  const [invoiceUploadListItem, setInvoiceUploadListItem] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(5);
  const [size, setSize] = useState("20");
  const { columns, rows } = invoiceUploadListData(invoiceUploadListItem, type);
  const [loading, setLoading] = useState(false);
  const dataRangeRef = useRef(null);
  const navigate = useNavigate();
  const [isShowDataRange, setIsShowDataRange] = useState(false);
  const [filterDateRange, setFilterDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection"
    }
  ]);

  const getInvoiceUploadList = async () => {
    setSpinnerStatus(dispatch, true);
    await fetch(`${API_URL}/api/${type}/invoices/list?page=${currentPage}${size === "all" ? "" : `&size=${size}`}${filterDateRange[0].startDate ? `&fromDate=${toStringByFormatting(new Date(filterDateRange[0].startDate))} 00:00:00` : ""}${filterDateRange[0].endDate ? `&toDate=${toStringByFormatting(new Date(filterDateRange[0].endDate))} 23:59:59` : ""}`, { credentials: "include" })
      .then((res) => res.json().then((r) => {
        setInvoiceUploadListItem(r.data);
        const totalPage = Math.ceil(r.totalElements / r.size);
        setTotalPage(totalPage);
        setSpinnerStatus(dispatch, false);
      }));
  };


  const filterDataRef = useRef(null);


  useEffect(() => {
    if (loading) {
      getInvoiceUploadList();
    }
  }, [currentPage]);

  useEffect(() => {
    if (loading) {
      setCurrentPage(1);
      getInvoiceUploadList();
    }
  }, [size, type]);

  useEffect(() => {
    getDataRangeInputs(filterDataRef, setFilterDateRange, setIsShowDataRange);
    document.body.addEventListener("click", (e) => {
      setIsShowDataRange(false);
      e.stopPropagation();
    });
  }, []);


  useEffect(() => {
    showDataRange(isShowDataRange);
  }, [isShowDataRange]);


  useEffect(() => {
    getInvoiceUploadList().then(() => setLoading(true));
  }, []);


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg"
                     coloredShadow="info">
                <MDTypography variant="h6" color="white">
                  {type.toUpperCase()}
                </MDTypography>
                <MDTypography variant="h6" color="white">
                  송장 등록 리스트
                </MDTypography>
              </MDBox>
              <MDBox mx={2} mt={2} variant="gradient" borderRadius="lg" display="flex"
                     style={{
                       alignItems: "center",
                       justifyContent: "space-between"
                     }}
              >
                <MDBox display="flex" style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center"
                }}>
                  <MDBox pr={1} mx={1}>
                    <DateRange
                      ref={dataRangeRef}
                      editableDateInputs={true}
                      onChange={(item) => {
                        filterDataRef.current = item.selection;
                        setFilterDateRange([item.selection]);
                      }}

                      moveRangeOnFirstSelection={false}
                      ranges={
                        filterDateRange
                      }
                      // locale={'korean'}
                    />
                  </MDBox>
                  {/*<MDBox pr={1} mx={1}>*/}
                  {/*  <MDButton color={"secondary"} onClick={() => setFilterDateRange([*/}
                  {/*    {*/}
                  {/*      startDate: null,*/}
                  {/*      endDate: null,*/}
                  {/*      key: "selection"*/}
                  {/*    }*/}
                  {/*  ])}>날짜 삭제</MDButton>*/}
                  {/*</MDBox>*/}
                  <MDBox pr={1} mx={1} display={"flex"} style={{ height: "40px" }}>
                    <MDButton color={"primary"} onClick={() => {
                      if (currentPage !== 1) {
                        setCurrentPage(1);
                      } else {
                        getInvoiceUploadList();
                      }
                    }}>결과 조회</MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                <MDBox pr={3} pl={3} style={{ justifyContent: "right", width: "100%", display: "flex" }}>
                  <MDButton color={"info"} onClick={() => navigate(`/${type}/invoice/new`)}>송장 업로드</MDButton>
                </MDBox>
                <MDBox pr={3} pl={3} my={2} style={{ justifyContent: "space-between", width: "100%", display: "flex" }}>
                  <MDBox width="200px">
                    <MDInput
                      size="medium"
                      select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={size}
                      label="페이지 당 표시 갯수"
                      onChange={(e) => setSize(e.target.value)}
                      InputProps={{
                        classes: { root: "select-input-styles" }
                      }}
                      fullWidth
                    >
                      <MenuItem value="10">10</MenuItem>
                      <MenuItem value="20">20</MenuItem>
                      <MenuItem value="30">30</MenuItem>
                      <MenuItem value="50">50</MenuItem>
                      <MenuItem value="100">100</MenuItem>
                    </MDInput>
                  </MDBox>
                </MDBox>
                <DataTable table={{ columns, rows }} isSorted={false}
                           showTotalEntries={false} noEndBorder />
              </MDBox>

              <MDBox py={3} display="flex" justifyContent="center">
                <MDPagination>
                  <CustomPagination total={totalPage} itemsPerPage={4} currentPage={currentPage}
                                    setCurrentPage={(page) => setCurrentPage(page)} />
                </MDPagination>
              </MDBox>
            </Card>

          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default InvoiceUploadListPage;
