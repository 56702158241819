import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { WP_COLLINS_URL } from "../../../constants/constants";


export default function data(data, setTargetWpId) {
  const URL = WP_COLLINS_URL;
  return {
    columns: [
      { Header: "ID", accessor: "id", align: "center" },
      { Header: "WP Id", accessor: "wpId", align: "center" },
      { Header: "성", accessor: "userLastName", align: "center" },
      { Header: "이름", accessor: "userFirstName", align: "center" },
      { Header: "휴대전화 번호", accessor: "userPhone", align: "center" },
      { Header: "등록일", accessor: "registerDate", align: "center" },
      { Header: "알림 여부", accessor: "useYn", align: "center" },
      { Header: "수정", accessor: "actionBox", align: "center" }
    ],

    rows: data.map((item, i) => ({
      id: (
        <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
          {item.id}
        </MDTypography>
      ),
      wpId: (
        <MDTypography variant="caption" color="info"
                      target="_blank"
                      component="a" href={URL + `/user-edit.php?user_id=${item.wp_user_id}`}
                      fontWeight="medium">
          {item.wp_user_id}
        </MDTypography>
      ),
      userFirstName: (
        <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
          {item?.first_name}
        </MDTypography>
      ),
      userLastName: (
        <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
          {item?.last_name}
        </MDTypography>
      ),
      userPhone: (
        <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
          {item.billing_phone}
        </MDTypography>
      ),
      registerDate: (
        <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
          {item.registed_date}
        </MDTypography>
      ),
      useYn: (
        <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
          {item.use_yn ? "승인" : "거부"}
        </MDTypography>
      ),
      actionBox: (
        <MDBox style={{ width: "40px", cursor: "pointer", display: "flex", alignItems: "center" }}>
          <MDBox>
            <img style={{ width: "40px" }}
                 onClick={() => {
                   setTargetWpId(item.wp_user_id);
                   document.getElementById("releaseNotificationModifyModal").show();
                 }}
                 src={require("../../../assets/images/icons/edit_icon.png")} />
          </MDBox>
          <MDBox>
            <img style={{ width: "20px", alignItems: "center" }}
                 onClick={() => {
                   setTargetWpId(item.wp_user_id);
                   document.getElementById("releaseNotificationDeleteModal").show();
                 }}
                 src={require("../../../assets/images/icons/delete_icon.png")} />
          </MDBox>
        </MDBox>
      )
    }))
  };
}
