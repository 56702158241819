import { API_URL } from "./constants/constants";
// import xml2js from "xml2js";

export const distStatusConverter = (type, status) => {
  if (type === "color") {
    if (status === "done") {
      return "success";
    }
    if (status === "prepare") {
      return "warning";
    }
  }
  if (type === "status") {
    if (status === "done") {
      return "편성 완료";
    }
    if (status === "prepare") {
      return "대상 확정";
    }
  }
  return false;
};

export const saveLocalStorage = (key, value) => {
  window.localStorage.setItem(key, value);
};

export const getLocalStorage = (key) => {
  return window.localStorage.getItem(key);
};

export const couponNameConverter = (name) => {
  let originCouponName = name;
  let closeSquareBracketsPositions = [];
  let openSquareBracketsPositions = [];

  for (let i = 0; i < originCouponName.length; i++) {

    let targetChar = originCouponName.substring(i, i + 1);

    if (targetChar === "]") {
      closeSquareBracketsPositions.push(i);
    } else if (targetChar === "[") {
      openSquareBracketsPositions.push(i);
    }
  }

  if (closeSquareBracketsPositions.length > 0 && openSquareBracketsPositions.length > 0) {
    let couponName = originCouponName.substring(closeSquareBracketsPositions[closeSquareBracketsPositions.length - 2] + 1, openSquareBracketsPositions[openSquareBracketsPositions.length - 1]);
    couponName.replace("()", "");
    return couponName;
  }

  return originCouponName;
};


export const couponStatusConverter = (type, status) => {
  if (type === "color") {
    if (status === "used") {
      return "success";
    }
    if (status === "usable") {
      return "info";
    }
  }
  if (type === "status") {
    if (status === "used") {
      return "사용함";
    }
    if (status === "usable") {
      return "사용가능";
    }
  }
};


export const productStatusConverter = (type, status) => {
  if (type === "color") {
    if (status === "active") {
      return "success";
    }
    if (status === "dt") {
      return "success";
    }
    if (status === "wt") {
      return "warning";
    }
  }
  if (type === "status") {
    if (status === "active") {
      return "Active";
    }
    if (status === "dt") {
      return "Delete";
    }
    if (status === "wt") {
      return "Wait";
    }
  }
  return false;
};

export const orderStatusConverter = (type, status) => {
  if (type === "color") {
    if (status === "wc-completed") {
      return "success";
    }
    if (status === "completed") {
      return "success";
    }
    if (status === "order-received") {
      return "success";
    }
    if (status === "wc-failed") {
      return "warning";
    }
    if (status === "wc-on-hold") {
      return "warning";
    }
    if (status === "wc-order-received") {
      return "success";
    }
    if (status === "wc-processing") {
      return "success";
    }
    if (status === "wc-refunded") {
      return "secondary";
    }
    if (status === "wc-awaiting-vbank") {
      return "secondary";
    }
    if (status === "wc-shipped") {
      return "success";
    }
    if (status === "wc-shipping") {
      return "success";
    }
    if (status === "wc-pending") {
      return "secondary";
    }
    if (status === "pending") {
      return "secondary";
    }
    if (status === "wc-delayed") {
      return "success";
    }
    if (status === "wc-cancelled") {
      return "secondary";
    }
    if (status === "wc-place-order") {
      return "success";
    }
    if (status === "wc-preorder-received") {
      return "warning";
    }
    if (status === "wc-template") {
      return "secondary";
    }

  }
  if (type === "status") {
    if (status === "wc-completed") {
      return "구매완료";
    }
    if (status === "completed") {
      return "구매완료";
    }
    if (status === "shipping") {
      return "배송중";
    }
    if (status === "processing") {
      return "출고중";
    }
    if (status === "order-received") {
      return "결제완료";
    }
    if (status === "wc-failed") {
      return "결제실패";
    }
    if (status === "wc-on-hold") {
      return "결제확인중";
    }
    if (status === "wc-order-received") {
      return "결제완료";
    }
    if (status === "wc-processing") {
      return "출고중";
    }
    if (status === "wc-awaiting-vbank") {
      return "가상계좌 입금대기 중";
    }
    if (status === "wc-refunded") {
      return "환불완료";
    }
    if (status === "wc-shipped") {
      return "배송완료";
    }
    if (status === "wc-shipping") {
      return "배송중";
    }
    if (status === "wc-pending") {
      return "대기";
    }
    if (status === "pending") {
      return "대기";
    }
    if (status === "대기") {
      return "대기";
    }
    if (status === "wc-delayed") {
      return "출고보류";
    }
    if (status === "wc-cancelled") {
      return "취소완료";
    }
    if (status === "wc-place-order") {
      return "발주확인";
    }
    if (status === "wc-preorder-received") {
      return "예약판매 발송 대기";
    }
    if (status === "wc-template") {
      return "템플릿";
    }
  }
  return false;
};


export function download(distId) {
  window.open(API_URL + `/api/bacon/dist/download/${distId}`, "_blank");
}

export function downloadNotBB(distId) {
  window.open(API_URL + `/api/bacon/dist/downloadnotBB/${distId}`, "_blank");
}

export function downloadNotBBExtraExternal(distId) {
  window.open(API_URL + `/api/bacon/dist/downloadNotBBExtraExternal/${distId}`, "_blank");
}

export function downloadBB(distId) {
  window.open(API_URL + `/api/bacon/dist/downloadBBAll/${distId}`, "_blank");
}

export function downloadBSForKyoWon(distId) {
  window.open(API_URL + `/api/bacon/dist/downloadBSForKyoWon/${distId}`, "_blank");
}

export function downloadBBForKyoWon(distId) {
  window.open(API_URL + `/api/bacon/dist/downloadBBForKyoWon/${distId}`, "_blank");
}

export function downloadBBSForKyoWon(distId) {
  window.open(API_URL + `/api/bacon/dist/downloadBBSForKyoWon/${distId}`, "_blank");
}

export function downloadHistories(type, orderId, dogId, userEmail, name) {
  window.open(`${API_URL}/api/${type}/dist/histories/download?order_id=${orderId}&dog_id=${dogId}&user_email=${userEmail}&name=${name}`);
}


export function collinsDownload(distId) {
  window.open(API_URL + `/api/collins/dist/download/${distId}`, "_blank");
}


export function downloadForKyoWon(distId) {
  window.open(API_URL + `/api/collins/dist/downloadForKyoWon/${distId}`, "_blank");

}

export const autoHypenPhone = (str) => {
  if (!str) {
    return "";
  }
  str = str.replace(/[^0-9]/g, "");
  let tmp = "";
  if (str.length < 4) {
    return str;
  } else if (str.length < 7) {
    tmp += str.substr(0, 3);
    tmp += "-";
    tmp += str.substr(3);
    return tmp;
  } else if (str.length < 11) {
    tmp += str.substr(0, 3);
    tmp += "-";
    tmp += str.substr(3, 3);
    tmp += "-";
    tmp += str.substr(6);
    return tmp;
  } else if (str.length === 12) {
    tmp += str.substr(0, 4);
    tmp += "-";
    tmp += str.substr(4, 4);
    tmp += "-";
    tmp += str.substr(8);
    return tmp;
  } else {
    tmp += str.substr(0, 3);
    tmp += "-";
    tmp += str.substr(3, 4);
    tmp += "-";
    tmp += str.substr(7, 4);
    return tmp;
  }
};


export function downloadCSV(data, filename, mime) {
  const blob = new Blob(["\uFEFF" + data], { type: mime || "application/octet-stream" });
  if (typeof window.navigator.msSaveBlob !== "undefined") {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }

    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }
}


export function openWindowWithPost(url, data) {
  var form = document.createElement("form");
  form.enctype = "application/json";
  form.target = "_blank";
  form.method = "POST";
  form.action = url;
  form.style.display = "none";

  for (var key in data) {
    var input = document.createElement("input");
    input.type = "hidden";
    input.name = key;
    input.value = data[key];
    form.appendChild(input);
  }

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}


export const dateFormat = (date) => {
  if (date) {
    const targetDate = new Date(date);
    return targetDate.getFullYear() + "-" + ((targetDate.getMonth() + 1) > 9 ? (targetDate.getMonth() + 1).toString() : "0" + (targetDate.getMonth() + 1)) + "-" + (targetDate.getDate() > 9 ? targetDate.getDate().toString() : "0" + targetDate.getDate().toString()) + " " + (targetDate.getHours() > 9 ? targetDate.getHours().toString() : "0" + targetDate.getHours().toString()) + ":" + (targetDate.getMinutes() > 9 ? targetDate.getMinutes().toString() : "0" + targetDate.getMinutes().toString()) + ":" + (targetDate.getSeconds() > 9 ? targetDate.getSeconds().toString() : "0" + targetDate.getSeconds().toString());
  } else {
    return "";
  }
};

export const dateFormatYYYYMMDD = (date) => {
  if (date) {
    const targetDate = new Date(date);
    return targetDate.getFullYear() + "-" + ((targetDate.getMonth() + 1) > 9 ? (targetDate.getMonth() + 1).toString() : "0" + (targetDate.getMonth() + 1)) + "-" + (targetDate.getDate() > 9 ? targetDate.getDate().toString() : "0" + targetDate.getDate().toString());
  } else {
    return "";
  }
};

export const dateFormatYYYYMMDDHHMM = (date) => {
  if (date) {
    const targetDate = new Date(date);
    return targetDate.getFullYear() + "-" + ((targetDate.getMonth() + 1) > 9 ? (targetDate.getMonth() + 1).toString() : "0" + (targetDate.getMonth() + 1)) + "-" + (targetDate.getDate() > 9 ? targetDate.getDate().toString() : "0" + targetDate.getDate().toString()) + "T" + (targetDate.getHours() > 9 ? targetDate.getHours().toString() : "0" + targetDate.getHours().toString()) + ":" + (targetDate.getMinutes() > 9 ? targetDate.getMinutes().toString() : "0" + targetDate.getMinutes().toString());
  } else {
    return "";
  }

};


export const dateFormatYYYYMMDDHHMMToBackEndType = (date) => {
  if (date) {
    const targetDate = new Date(date);
    return targetDate.getFullYear() + ". " + (targetDate.getMonth() + 1) + ". " + targetDate.getDate().toString() + ". " + targetDate.getHours().toString() + ":" + targetDate.getMinutes().toString() + ":00";
  } else {
    return "";
  }

};


export function getYmd10(d) {
  return d.getFullYear() + "-" + ((d.getMonth() + 1) > 9 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1)) + "-" + (d.getDate() > 9 ? d.getDate().toString() : "0" + d.getDate().toString());
}


function leftPad(value) {
  if (value >= 10) {
    return value;
  }

  return `0${value}`;
}

export function toStringByFormatting(source, delimiter = "-") {
  const year = source.getFullYear();
  const month = leftPad(source.getMonth() + 1);
  const day = leftPad(source.getDate());

  return [year, month, day].join(delimiter);
}


export const showDataRange = (isShow) => {

  const dataPickerElement = document.querySelectorAll(".rdrMonthAndYearWrapper")[0];
  if (!dataPickerElement) {
    return;
  }
  const buttonElements = dataPickerElement.querySelectorAll("button");
  buttonElements[0].style.display = "none";
  buttonElements[1].style.display = "none";
  const monthPickerElement = document.querySelectorAll(".rdrMonthsVertical")[0];
  dataPickerElement.addEventListener("click", e => e.stopPropagation());
  monthPickerElement.addEventListener("click", e => e.stopPropagation());
  if (isShow) {
    dataPickerElement.style.display = "flex";
    monthPickerElement.style.display = "block";
  } else {
    dataPickerElement.style.display = "none";
    monthPickerElement.style.display = "none";
  }
};

export const getDataRangeInputs = (filterDataRef, setFilterDateRange, setIsShowDataRange) => {
  const inputElements = document.querySelectorAll(".rdrDateInput");
  if (inputElements.length === 0) {
    return;
  }
  inputElements[0].childNodes[0].placeholder = "시작일";
  inputElements[1].childNodes[0].placeholder = "종료일";
  inputElements[0].addEventListener("click", (e) => {
    setIsShowDataRange(true);
    e.stopPropagation();
  });
  inputElements[0].childNodes[0].addEventListener("change", (e) => {
    if (e.target.value.trim.length === 0) {
      filterDataRef.current.startDate = null;
      setFilterDateRange([filterDataRef.current]);
    }
  });
  inputElements[1].childNodes[0].addEventListener("change", (e) => {
    if (e.target.value.trim.length === 0) {
      filterDataRef.current.endDate = null;
      setFilterDateRange([filterDataRef.current]);
    }
  });
  inputElements[1].addEventListener("click", (e) => {
    setIsShowDataRange(true);
    e.stopPropagation();

  });
};


export const invoiceData = {
  "EMS": "우체국EMS",
  "CVSNET": "CVSNET",
  "ILYANG": "ILYANG",
  "KGB": "KGB",
  "CH1": "OCS코리아",
  "DHL": "DHL",
  "UPS": "UPS",
  "TNT": "TNT익스프레스",
  "INNOGIS": "GTX 로지스",
  "EPOST": "우체국",
  "HYUNDAI": "롯데택배",
  "HANJIN": "한진택배",
  "HPL": "한의사랑택배",
  "DONGBU": "동부택배",
  "KDEXP": "경동택배",
  "PANTOS": "범한판토스",
  "KOREXG": "대한한공배송",
  "CJGLS": "대한통운",
  "FEDEX": "Fedex",
  "LOTTE": "롯데택배"
};

export const invoiceDropdownItems = [
  { value: null, label: "택배사를 선택하세요." },
  {
    value: "CJGLS", label: "대한통운"
  },
  {
    value: "EPOST", label: "우체국"
  },
  {
    value: "HANJIN", label: "한진택배"
  },
  {
    value: "LOGEN", label: "로젠택배"
  },
  {
    value: "HYUNDAI", label: "롯데택배"
  },
  {
    value: "KDEXP", label: "경동택배"
  }
];

export const deliveryData = (code, number) => {
  let uppercaseCode = code.toUpperCase();
  if (uppercaseCode === "CJGLS") {
    return "https://www.cjlogistics.com/ko/tool/parcel/tracking";
  } else if (uppercaseCode === "EPOST") {
    return `https://service.epost.go.kr/trace.RetrieveDomRigiTraceList.comm?sid1=${number}`;
  } else if (uppercaseCode === "HANJIN") {
    return `https://www.hanjin.com/kor/CMS/DeliveryMgr/WaybillSch.do?mCode=MN038`;
  } else if (uppercaseCode === "LOGEN") {
    return "https://www.ilogen.com/web";
  } else if (uppercaseCode === "LOTTE") {
    return `https://www.lotteglogis.com/home/reservation/tracking/linkView?InvNo=${number}`;
  } else if (uppercaseCode === "HYUNDAI") {
    return `https://www.lotteglogis.com/home/reservation/tracking/linkView?InvNo=${number}`;
  } else {
    return "https://search.naver.com/search.naver?where=nexearch&sm=top_hty&fbm=0&ie=utf8&query=%EB%B0%B0%EC%86%A1+%EC%A1%B0%ED%9A%8C";
  }
};

export function downloadFileFromS3(url, name) {
  // Replace 'YOUR_S3_FILE_URL_HERE' with the actual URL of the file on S3
  const s3FileUrl = url;

  // Replace 'FILENAME' with the desired filename
  const fileName = name;

  // Creating an anchor element to trigger the download
  const a = document.createElement("a");
  a.href = s3FileUrl;
  a.download = fileName; // Set the download attribute to specify the filename
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function downloadFromS3(url, name) {
  const s3FileUrl = url; // S3 파일의 URL
  const fileName = name; // 다운로드될 파일의 이름

  // XMLHttpRequest를 사용하여 S3로부터 파일 다운로드 요청을 보냅니다.
  const xhr = new XMLHttpRequest();
  xhr.open("GET", s3FileUrl, true);
  xhr.responseType = "blob"; // 응답 형식을 Blob으로 설정합니다.

  xhr.onload = function() {
    // 파일 다운로드를 위한 a 태그를 동적으로 생성합니다.
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(xhr.response);
    a.download = fileName; // 다운로드될 파일의 이름을 설정합니다.
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  xhr.send();
}

export const fetchWithTimeout = (url, options = {}, timeout = 60000) => {
  const controller = new AbortController();
  const { signal } = controller;

  const fetchPromise = fetch(url, { ...options, signal });

  const timeoutPromise = new Promise((_, reject) => {
    const timer = setTimeout(() => {
      controller.abort();
      reject(new Error("Request timed out"));
    }, timeout);
  });

  return Promise.race([fetchPromise, timeoutPromise]);
};


export const OrderHotKeyBaseInfo = [
  {
    title: "쏘리 박스 편성",
    customer: { id: "118492", label: "고객서비스 (#118492 - cx@baconbox.co)" },
    itemIds: ["461841"],
    status: "order-received"
  },
  {
    title: "교환 출고 편성",
    customer: { id: "118492", label: "고객서비스 (#118492 - cx@baconbox.co)" },
    itemIds: ["1557110"],
    status: "order-received"
  },
  {
    title: "복제"
  }
];

export const CollinsOrderHotKeyBaseInfo = [
  {
    title: "쏘리 박스 편성",
    customer: { id: "14319", label: "고객서비스 (#14319 - cx@baconbox.co)" },
    itemIds: ["110"],
    status: "order-received"
  },
  {
    title: "교환 출고 편성",
    customer: { id: "14319", label: "고객서비스 (#14319 - cx@baconbox.co)" },
    itemIds: null,
    status: "order-received"
  },
  {
    title: "복제"
  }

];

export const searchParam = (param) => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(param);
};

function xmlToJson(xml) {
  let obj = {};

  if (xml.nodeType === 1) { // element node
    // 처리할 속성들을 객체에 추가
    if (xml.attributes.length > 0) {
      for (let i = 0; i < xml.attributes.length; i++) {
        obj[xml.attributes[i].nodeName] = xml.attributes[i].nodeValue;
      }
    }
  } else if (xml.nodeType === 3) { // text node
    obj = xml.nodeValue;
  }

  // 하위 노드를 순차적으로 처리
  if (xml.hasChildNodes()) {
    for (let i = 0; i < xml.childNodes.length; i++) {
      const item = xml.childNodes[i];
      const nodeName = item.nodeName;
      if (obj[nodeName] === undefined) {
        obj[nodeName] = xmlToJson(item);
      } else {
        if (Array.isArray(obj[nodeName])) {
          obj[nodeName].push(xmlToJson(item));
        } else {
          obj[nodeName] = [obj[nodeName], xmlToJson(item)];
        }
      }
    }
  }

  return obj;
}

export const getHoliday = (year, month) => {
  const url = "https://apis.data.go.kr/B090041/openapi/service/SpcdeInfoService/getRestDeInfo"; /*URL*/
  let queryParams = "?" + encodeURIComponent("serviceKey") + "=" + "r2ZLC6glEGEGWV%2BX6MiEOcVqkQiVWg3WjIRbVRL7TttVZ8yeecpXxz%2BEb5QrgEbmtq7JsO4Q76RfJ4woSOEFEg%3D%3D"; /*Service Key*/
  queryParams += "&" + encodeURIComponent("solYear") + "=" + encodeURIComponent(year); /**/
  queryParams += "&" + encodeURIComponent("solMonth") + "=" + month; /**/
  return fetch(url + queryParams).then(r => r.text().then(response => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(response, "application/xml");
    const jsonResult = xmlToJson(xmlDoc);
    return jsonResult.response.body;
  }));
};


export const getDatesByDay = (year, month, dayOfWeek) => {
  // 결과를 저장할 배열
  const dates = [];

  // 지정된 월의 첫 번째 날짜
  const firstDate = new Date(year, month - 1, 1);

  // 지정된 월의 마지막 날짜
  const lastDate = new Date(year, month, 0);

  // 날짜를 순회하며 지정된 요일인지 확인
  for (let date = firstDate; date <= lastDate; date.setDate(date.getDate() + 1)) {
    if (date.getDay() === dayOfWeek) {
      // 요일이 일치하면 배열에 추가
      dates.push(new Date(date));
    }
  }

  return dates;
};
