import React from "react";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import { Divider } from "@mui/material";
import MDButton from "../MDButton";
import "./index.css";

function MDModal(props) {
  const { id, title, action } = props;
  return (
    <dialog id={id}>
      <MDBox py={3} px={5} minWidth="250px">
        <MDTypography component="div" variant="h6" color="text" fontWeight="medium">{title}</MDTypography>
        <MDBox display="flex" mt={4} justifyContent="space-around">
          <MDButton value="cancel" color="secondary"
                    variant={"gradient"}
                    onClick={() => document.getElementById(id).close()}>취소</MDButton>
          <MDButton value="default" variant={"gradient"} color="dark" onClick={() => action && action()}>확인</MDButton>
        </MDBox>
      </MDBox>
    </dialog>
  );
}

export default MDModal;
