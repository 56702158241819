import React, { useEffect, useState } from "react";
import MDReleaseNotificationModifyModal from "../../components/MDReleaseNotificationModifyModal";
import MDModal from "../../components/MDModal";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import { autoHypenPhone } from "../../Util";
import MDButton from "../../components/MDButton";
import Footer from "../../examples/Footer";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import { API_URL } from "../../constants/constants";
import { useNavigate } from "react-router-dom";
import { setSpinnerStatus, useMaterialUIController } from "../../context";

function InvoiceUploadPage(props) {
  const [controller, dispatch] = useMaterialUIController();
  const { type } = props;
  const [targetFile, setTargetFile] = useState();
  const [title, setTitle] = useState("");
  const navigate = useNavigate();

  const invoiceUpload = () => {
    if (title.trim() === "") {
      return window.alert("제목을 입력하세요.");
    }
    if (!targetFile) {
      return window.alert("파일을 첨부해주세요.");
    }
    const formData = new FormData();
    formData.append("file", targetFile);
    formData.append("title", title);
    setSpinnerStatus(dispatch, true);
    fetch(`${API_URL}/api/${type}/invoices/upload`, {
      method: "POST",
      credentials: "include",
      body: formData
    }).then(res => res.json().then(r => {
      if(r.code === "200"){
        navigate(`/${type}/invoice/${r.data.invoicesId}`);
        setSpinnerStatus(dispatch, false);
      }else{
        setSpinnerStatus(dispatch, false);
        return window.alert(r.msg)
      }
    }));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg"
                     coloredShadow="info">
                <MDTypography variant="h6" color="white">
                  {type.toUpperCase()}
                </MDTypography>
                <MDTypography variant="h6" color="white">
                  송장 업로드
                </MDTypography>
              </MDBox>
              <MDBox mx={3} mt={3} px={2}>
                <MDTypography fontSize={"13px"} color="dark">
                  [1] 제목을 입력해주세요.<br />
                  [2] 송장정보가 기록된 CSV 파일을 선택한 후 "송장 업로드" 버튼을 클릭합니다. 샘플 CSV 파일을 다운로드 받으시려면 <a
                  href={`https://s3.ap-northeast-2.amazonaws.com/fly.gitt.co/test/${type}_invoice_test.csv`}>여기</a>를
                  클릭하세요.
                </MDTypography>
              </MDBox>
              <MDBox mx={3} px={2} mt={1} display="flex" flexWrap="wrap">
                <MDBox pr={1} mr={1} my={1} minWidth="150px">
                  <MDInput type="text"
                           label={"제목*"}
                           value={title}
                           onChange={e => {
                             setTitle(e.target.value);
                           }}
                  />
                </MDBox>
                <MDBox pr={1} mr={1} my={1} minWidth="150px">
                  <MDInput type="file"
                           onChange={e => {
                             setTargetFile(e.target.files[0]);
                           }}
                  />
                </MDBox>
                <MDBox pr={1} mr={1} my={1}>
                  <MDButton color="success" onClick={() => {
                    invoiceUpload();
                  }}>송장 업로드</MDButton>
                </MDBox>
              </MDBox>
              <MDBox mx={2} px={2} variant="gradient" borderRadius="lg" display="flex"
                     style={{
                       alignItems: "center",
                       justifyContent: "space-between"
                     }}
              >
                <MDBox display="flex" style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center"
                }}>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );

}

export default InvoiceUploadPage;
