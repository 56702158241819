import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

// Images
import { orderStatusConverter } from "../../../Util";

export default function data(data, type, orderTab) {
  return {
    columns: [
      { Header: "파트너 여부", accessor: "isPartner", align: "center" },
      { Header: orderTab === "order" ? "주문" : "템플릿", accessor: "id", align: "center" },
      { Header: "수정일", accessor: "updateDate", align: "center" },
      { Header: "상태", accessor: "status", align: "center" },
      { Header: "총계", accessor: "totalPrice", align: "center" }
    ],

    rows: data.map((item) => ({
      isPartner: (
        <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
          {item.isPartner?"파트너":"일반"}
        </MDTypography>
      ),
      id: (
        <MDTypography component="a" href={`/${type}/order/${item.id}`}
                      variant="caption" color="text"
                      fontWeight="medium">
          #{item.id} {orderTab==="order"?item.billing_last_name+item.billing_first_name:item.template_title}
        </MDTypography>
      ),
      updateDate: (
        <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
          {new Date(item.created_date).toLocaleDateString()}
        </MDTypography>
      ),
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={orderStatusConverter("status", item.status)}
            color={orderStatusConverter("color", item.status)}
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      totalPrice: (
        <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
          {item.total}원
        </MDTypography>
      )
    }))
  };
}
