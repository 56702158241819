/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 React context
import { useMaterialUIController, setMiniSidenav, setTransparentSidenav, setWhiteSidenav } from "context";
import { getLocalStorage, saveLocalStorage } from "../../Util";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor, userInfo, isLogin } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");
  const [groupTab, setGroupTab] = useState(null);
  const [userRole, setUserRole] = useState();
  const navigate = useNavigate();
  let textColor = "white";
  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  const checkRouteByRole = (role) => {
    const targetRoute = routes.filter(item => item.route === location.pathname);
    if (targetRoute.length > 0) {
      if (role === "ROLE_USER" && targetRoute[0].role !== "ROLE_USER") {
        navigate("/");
      }
    }
  };

  useEffect(() => {
    if (location.pathname.includes("collins")) {
      setGroupTab("collins");
      saveLocalStorage("sideNavTab", "collins");
    } else if (location.pathname.includes("bacon")) {
      setGroupTab("bacon");
      saveLocalStorage("sideNavTab", "bacon");
    } else {
      if (getLocalStorage("sideNavTab")) {
        setGroupTab(getLocalStorage("sideNavTab"));
      }
    }
  }, []);
  useEffect(() => {
    if (userInfo && isLogin && userInfo !== "user not found.") {
      userInfo.authorities?.filter(item => {
        if (item.authority) {
          setUserRole(item.authority);
        }
      });
    }
  }, [isLogin, userInfo]);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /**
     The event listener that's calling the handleMiniSidenav function when resizing the window.
     */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);
  useEffect(() => {
    checkRouteByRole(userRole);
  }, [location.pathname, userRole]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route, group, role }) => {
    let returnValue;
    if (group !== groupTab) {
      return;
    }
    if (role === "ROLE_ADMIN") {
      if (userRole !== "ROLE_ADMIN") {
        return;
      }
    }
    if (type === "collapse") {
      returnValue = href ? (
        <Link href={href} key={key} target="_blank" rel="noreferrer" sx={{ textDecoration: "none" }}>
          <SidenavCollapse name={name} icon={icon} active={collapseName.includes(key)} noCollapse={noCollapse} />
        </Link>
      ) : (
        <NavLink key={key} to={route}>
          <SidenavCollapse name={name} icon={icon} active={collapseName.includes(key)} />
        </NavLink>
      );
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider key={key}
                 light={(!darkMode && !whiteSidenav && !transparentSidenav) || (darkMode && !transparentSidenav && whiteSidenav)} />
      );
    }

    return returnValue;
  });

  return (
    <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}>
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <MDBox component="img" src={brand} alt="Brand" width="2rem" />}
          <MDBox width={!brandName && "100%"} sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}>
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={(!darkMode && !whiteSidenav && !transparentSidenav) || (darkMode && !transparentSidenav && whiteSidenav)} />
      <MDBox style={{ margin: "0 16px 5px 16px", display: "flex", justifyContent: "space-between" }}
             sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}>
        <MDBox width={"50%"}>
          <MDButton style={{
            width: "100%",
            backgroundColor: groupTab === "bacon" ? "white" : "transparent",
            color: "rgb(123, 128, 154)"
          }}
                    onClick={() => {
                      setGroupTab("bacon");
                      saveLocalStorage("sideNavTab", "bacon");
                    }}>BaconBox</MDButton>
        </MDBox>
        <MDBox width={"50%"}>
          <MDButton style={{
            width: "100%",
            backgroundColor: groupTab === "collins" ? "white" : "transparent",
            color: "rgb(123, 128, 154)"
          }}
                    onClick={() => {
                      setGroupTab("collins");
                      saveLocalStorage("sideNavTab", "collins");
                    }}>Collins</MDButton>
        </MDBox>
      </MDBox>
      <List>{renderRoutes}</List>;
    </SidenavRoot>
  )
    ;
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: ""
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Sidenav;
