// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
import { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import { Icon } from "@mui/material";
import MDInput from "../../components/MDInput";
import { API_URL } from "../../constants/constants";
import MDButton from "../../components/MDButton";
import MDPagination from "../../components/MDPagination";
import collinsBoardTableData from "../tables/data/collinsBoardTableData";
import { autoHypenPhone } from "../../Util";
import MenuItem from "@mui/material/MenuItem";
import MDPostCodeModal from "../../components/MDPostCodeModal/MDPostCodeModal";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import { setSpinnerStatus, useMaterialUIController } from "../../context";
import { useNavigate } from "react-router-dom";

function BoardList() {
  const [, dispatch] = useMaterialUIController();
  const [searchText, setSearchText] = useState("");
  const [targetData, setTargetData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(5);
  const navigate = useNavigate()

  const getCollinsBoardList = async () => {
    setSpinnerStatus(dispatch, true);
    await fetch(`${API_URL}/api/collins/boards/contents?page=${currentPage}&size=10${searchText.trim().length > 0 ? `&keyword=${searchText}` : ""}`, {
      method: "GET",
      credentials: "include"
    }).then(res => res.json().then(r => {
      if (r.data) {
        setTargetData(r.data);
        setCurrentPage(r.page);
        setTotalPage(Math.ceil(r.totalElements / 10));
      }
      setSpinnerStatus(dispatch, false);
    }));
  };


  const { columns, rows } = collinsBoardTableData(targetData, "collins", getCollinsBoardList);


  useEffect(() => {
    getCollinsBoardList();
  }, [currentPage]);


  useEffect(() => {
    document.addEventListener("keyup", function(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        document.getElementById("searchButton").click();

      }
    });
    return () => document.removeEventListener("keyup", function(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        document.getElementById("searchButton").click();
      }
    });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  COLLINS
                </MDTypography>
                <MDTypography variant="h6" color="white">
                  Maker's Note
                </MDTypography>
              </MDBox>
              <MDBox
                mx={2}
                mt={2}
                py={3}
                px={2}
                variant="gradient"
                borderRadius="lg"
                display="flex"
                flexWrap="wrap"
              >
                <MDBox display="block" width={"100%"}>
                  <MDBox flexWrap="wrap" display="flex">
                    <MDBox display="flex" flexWrap="wrap">
                      <MDBox mr={1} my={1}>
                        <MDInput label="제목 검색" onChange={(e) => setSearchText(e.target.value)} />
                      </MDBox>
                      <MDBox pr={1} mr={1} my={1}>
                        <MDButton color="success" id={"searchButton"}
                                  onClick={() => getCollinsBoardList()}>검색하기</MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDButton color={"info"} style={{ float: "right" }}
                            onClick={() => navigate("/collins/brandArchive/editor")}>새로 작성하기</MDButton>
                </MDBox>
              </MDBox>
              <Divider />
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
              <MDBox py={3} display="flex" justifyContent="center">
                <MDPagination>
                  {/*<MDPagination item onClick={() => {*/}
                  {/*  setCurrentPage(currentPage - 1);*/}
                  {/*}}>*/}
                  {/*  <Icon>keyboard_arrow_left</Icon>*/}
                  {/*</MDPagination>*/}

                  {/*{firstNum !== 0 && <MDPagination item*/}
                  {/*                                 active={firstNum === currentPage ? true : false}*/}
                  {/*                                 onClick={() => setCurrentPage(firstNum)}>*/}
                  {/*  {firstNum}*/}
                  {/*</MDPagination>}*/}
                  {/*{Array(4).fill().map((_, i) => {*/}
                  {/*  if (i <= 2) {*/}
                  {/*    return (*/}
                  {/*      <MDPagination item*/}
                  {/*                    onClick={() => setCurrentPage(firstNum + 1 + i)}*/}
                  {/*                    active={firstNum + 1 + i === currentPage ? true : false}>{firstNum + 1 + i}</MDPagination>);*/}
                  {/*  } else if (i >= 3) {*/}
                  {/*    return (*/}
                  {/*      <MDPagination item*/}
                  {/*                    onClick={() => setCurrentPage(lastNum)}*/}
                  {/*                    active={lastNum === currentPage ? true : false}>{lastNum}</MDPagination>*/}
                  {/*    );*/}
                  {/*  }*/}
                  {/*})}*/}
                  {/*<MDPagination item onClick={() => {*/}
                  {/*  setCurrentPage(currentPage + 1);*/}
                  {/*}}>*/}
                  {/*  <Icon>keyboard_arrow_right</Icon>*/}
                  {/*</MDPagination>*/}
                  <CustomPagination total={totalPage} itemsPerPage={4} currentPage={currentPage}
                                    setCurrentPage={(page) => setCurrentPage(page)} />
                </MDPagination>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BoardList;
